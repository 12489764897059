import React, {
	PureComponent
	, useState
} from 'react';
import classNames from 'classnames';
import { I } from '../../common/v5/config';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import {
	ADMIN
	, BACKSEARCH
	, EXPORTS
	, EXTERNAL_CHAT
	, EXTERNAL_OPEN
	, MESSAGES
	, REVIEW
	, REVIEW_ERRAND
	, SEARCH
	, SEARCH_ANSWERS
	, STATISTICS
	, V5
	, WORKFLOW
} from '../../common/path';


const SkeletonSidebar = ({collapseSideBar}) => {
    return (
        <div className={classNames("app-sidebar", {collapsed:collapseSideBar})}>
            <div className="app-header">
                {!collapseSideBar && <div className="sk-shine-bar"></div>}
                <button title="Collapse sidebar" className="sidebar-trigger" data-sidebar-trigger="true">
                    <i className="sk-shine-icon-circle"></i>
                </button>
            </div>
            <div className="app-inner-content">
                {/* {!collapseSideBar && <div className="flex-menus-wrap"></div>} */}
                {collapseSideBar ?
                    <div className="flex-menus-wrap" style={{padding:"0"}}>
                        <div className="side-bar-main-menu">
                            <button className="sidebar-trigger"><div className="sk-shine-icon-circle"></div></button>
                        </div>
                        <ul className="collapse-wfmenu">
                            <li className="collapse-menu"><div className="sk-shine-icon-circle"></div></li>
                            <li className="collapse-menu"><div className="sk-shine-icon-circle"></div></li>
                            <li className="collapse-menu"><div className="sk-shine-icon-circle"></div></li>
                            <li className="collapse-menu"><div className="sk-shine-icon-circle"></div></li>
                            <li className="collapse-menu"><div className="sk-shine-icon-circle"></div></li>
                            <li className="collapse-menu"><div className="sk-shine-icon-circle"></div></li>
                            <li className="collapse-menu"><div className="sk-shine-icon-circle"></div></li>
                        </ul>
                    </div>
                    :
                    <div className="flex-menus-wrap"></div>
                }
                <ul className="sidebar-settings-list">
                    <ul className="sidebar-buttons-list">
                        <div className="sk-shine-avatar"></div>
                        <li className="">
                            <button type="button" className="btn-transparent">
                                <i className="sk-shine-icon-circle" style={{margin:"0"}}></i>
                                <span hidden={collapseSideBar} className="sk-shine-bar" style={{marginLeft:"12px"}}></span>
                            </button>
                        </li>
                        <li className="">
                            <button type="button" className="btn-transparent">
                                <i className="sk-shine-icon-circle" style={{margin:"0"}}></i>
                                <span hidden={collapseSideBar} className="sk-shine-bar" style={{marginLeft:"12px"}}></span>
                            </button>
                        </li>
                    </ul>
                    <div className="chat-toggle-switch">
                        {collapseSideBar &&
                            <li>
                                <span className="sk-shine-icon-circle" style={{margin:"0 auto 5px"}}></span>
                                <span className="sk-shine-icon-circle" style={{margin:"0 auto 5px"}}></span>
                                <span className="sk-shine-icon-circle" style={{margin:"0 auto 5px"}}></span>
                            </li>
                        }
                    </div>
                </ul>
            </div>
        </div>
    )
}

const SkeletonInnerSearch = () => {
    return (
        <div className="sk-search-content">
            <div className="errand-box-search filter">
                <div className="search-block-page">
                    <div className="search-form"></div>
                    <div className="sidebar-dropdown-menu">
                        <div className="sk-shine-bar"></div>
                    </div>
                </div>
                {/* <div className="search-block-filter">
                    <div className="sk-shine-bar"></div>
                </div> */}
            </div>
        </div>
    )
}

const SkeletonInnerStatistic = () => {
    return (
        <div className="sk-statistic-content">
            <div className="widget-section">
                <div className="sk-widget">
                    <div className="sk-title"></div>
                    <div className="sk-widget-box"></div>
                </div>
                <div className="sk-widget">
                    <div className="sk-title"></div>
                    <div className="sk-widget-box"></div>
                </div>
                <div className="sk-widget">
                    <div className="sk-title"></div>
                    <div className="sk-widget-box"></div>
                </div>
                <div className="sk-widget">
                    <div className="sk-title"></div>
                    <div className="sk-widget-box"></div>
                </div>
            </div>
            <div className="chart-section">
                <div className="sk-chart">
                    <div className="sk-title"></div>
                    <div className="sk-chart-box">
                        <div className="sk-chart-line-1"></div>
                        <div className="sk-chart-line-2"></div>
                        <div className="sk-chart-line-3"></div>
                        <div className="sk-chart-line-4"></div>
                        <div className="sk-chart-line-5"></div>
                        <div className="sk-chart-line-6"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

//Brief errands
const ViewBriefErrandSkeleton = () => {
	return (
		<div className="conversation read">
			<div className="conversation-left-side">
				<div className="conversation-checkbox">
					<SquareCheckbox id={"QA_ErrandCb_skeleton"} checked={false} hidden={false}/>
				</div>
				<figure className="conversation-photo">
                    <div className="sk-shine-profile-avatar"></div>
                </figure>
				<div className="conversation-type">
					<i className="icon-dummy"></i>
				</div>
			</div>
			<div className="conversation-center" id={"vb1"}>
				<div className="sk-container">
					<div className="sk-line-id"></div>
					<div className="sk-line-name"></div>
					<div className="sk-line-message-1"></div>
				</div>
				<div className="sk-line-time"></div>
			</div>
		</div>
	)
}

const ErrandConversationSkeleton = () => {
	return (
		<div className="skeleton-loading">
			<ViewBriefErrandSkeleton/>
			<ViewBriefErrandSkeleton/>
			<ViewBriefErrandSkeleton/>
			<ViewBriefErrandSkeleton/>
			<ViewBriefErrandSkeleton/>
			<ViewBriefErrandSkeleton/>
			<ViewBriefErrandSkeleton/>
		</div>
	)
}

const SkeletonHeader = () => {
    return (
        <header className="header-slim skeleton-header">
            <div className="sk-header-logo">
                <div className="sk-shine-bar"></div>
            </div>
            <div className="sk-header-actions">
                <div className="sk-user-photo"></div>
            </div>
        </header>
    )
}

export const renderSkeletonHeader = () => {
    return SkeletonHeader
}

export const SkeletonLoader = ({view, hide, collapseSideBar, showSideBar, showConversationList}) => {
    return (
        <div className={classNames("skeleton-page skeleton-main", {"embedded": true})} hidden={hide}>
            <div id="page-wrapper" className="">
                <section className="layout slim">
                    {/* SIDEBAR */}
                    {showSideBar && <SkeletonSidebar collapseSideBar={collapseSideBar}/>}
                    {/* CONVOLIST */}
                    {showConversationList &&
                        <div className="app-conversations">
                            <div className="app-header">
                                <ul className="conversations-nav">
                                    <li className="toggle-sidebar "><a className="sidebar-trigger"><i className="sk-shine-icon-circle"></i></a></li>
                                    <li className="top-filter"><a><i className="sk-shine-icon-circle"></i></a></li>
                                    <div><div className="sk-shine-bar"></div></div>
                                </ul>
                            </div>
                            <div className="scrollable-container">
                                <div id="ErrandList" className="app-inner-content" data-conversations="true">
                                    <ErrandConversationSkeleton/>
                                </div>
                            </div>
                        </div>
                    }
                    {/* APP CONTENT */}
                    <div className="app-content w-100">
                        {showConversationList ?
                            <div className="app-header">
                            </div>
                            :
                            <div className="app-header">
                                <li className="toggle-sidebar "><a className="sidebar-trigger">
                                    <i className="sk-shine-icon-circle"></i></a>
                                </li>
                                <li className="top-filter"><a><i className="sk-shine-icon-circle"></i></a></li>
                                <div className="sk-shine-bar"></div>
                            </div>
                        }
                        <div className="app-inner-content">
                            {(view == STATISTICS || view == "statistic") && <SkeletonInnerStatistic/>}
                            {view == SEARCH && <SkeletonInnerSearch/>}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
