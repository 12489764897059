import React from 'react';
import classNames from 'classnames';
import Channel from '../../reactcomponents/Channel';
import { DropDownSelect } from '../../reactcomponents/SelectBox';
import { SerialMultiSelect } from '../../reactcomponents/Dropdown';
import { StandardAction } from '../../reactcomponents/HeaderActions';
import {
	RPLY_ERRAND,
	RPLY_MANUAL,
	RPLY_COLLABORATE,
	VIEW_REPLY_PANEL_ID_FORMAT
} from '../../common/v5/constants';
import { I } from '../../common/v5/config.js';

const PillSelect = ({options, isSocialMedia, channelAlias, onSelect}) => {
	let list =[];
	$.each(options, (k, v) => {
		if (!isSocialMedia || v.active) {
			list.push(
				<StandardAction
					data-qa-id={'l'+k}
					text={v.name}
					key={'l'+k}
					me={k}
					channel={channelAlias}
					onClick={onSelect.bind(null, k, channelAlias )}
					name={v.name}
					icon={v.icon}
				/>
			);
		}
	});
	return 	<ul className='actions-container'>{list}</ul>
}

const SocialMediaActionsToolbar = ({ channel, dropdownMode = true, ...props }) => {
	return (
		dropdownMode ?
		<DropDownSelect
			isSocialMedia={true}
			myClass='action-select'
			channel={channel}
			title={I('{CHANNEL} actions').replace('{CHANNEL}', channel)}
			{...props}
		/>
		:
		<PillSelect
			isSocialMedia={true}
			myClass='action-select'
			channel={channel}
			{...props}
		/>
	)
};

export const SocialMediaActions = ({
	replyAs
	, shouldDisplaySocialAction
	, noChannelSelection
	, channelOpts
	, selectedChannel
	, showActionSelection
	, onToggleAction
	, onSelectAction
	, errandId
	, ...p
}) => {
	const hasChannel = !noChannelSelection;
	let actions
		, channel
		, isCollaborate
		, enableAction
		, shouldDisplayAction = false;
	if(replyAs === RPLY_COLLABORATE) {
		isCollaborate = true
	}
	enableAction = !isCollaborate;
	let allowCollab = (replyAs === RPLY_COLLABORATE) && (
		p.collabSlackEnabled || p.collabMSTeamEnabled || p.collabJiraEnabled || p.collabGoogleChatEnabled);
	if (hasChannel) {
		channel = channelOpts[selectedChannel];
	}
	if (typeof shouldDisplaySocialAction !== 'undefined') {
		shouldDisplayAction = shouldDisplaySocialAction;
	}
	if (replyAs === RPLY_ERRAND || replyAs === RPLY_MANUAL || allowCollab) {
		if (enableAction) {
			if(channel) {
				actions = channel.actions;
			}
		}
	}
	const handleSelectAction = (action, channel) => {
		onSelectAction(errandId, action, channel);
	}
	let shouldShow = enableAction && shouldDisplayAction && !!actions
	return (
		shouldShow ?
		<SocialMediaActionsToolbar
			channel={channel.name}
			channelAlias={selectedChannel}
			show={showActionSelection}
			options={actions}
			onToggleShow={onToggleAction}
			onSelect={handleSelectAction}
			dropdownMode={p.dropdownMode}
		/>
		:
		null
	)
}

export class ToggleReplyBar extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleToggleViewPanelList = this.handleToggleViewPanelList.bind(this);
		this.state = {
			showViewPanelList: false
		}
	}
	handleToggleViewPanelList(id, toggle) {
		this.setState({showViewPanelList: !toggle});
	}
	render() {
		const { show, availableReplyPanel, selectedReplyPanel, onSelectedViewPanel } = this.props;
		return (
			<div className="show-option-select" hidden={!show}>
				<SerialMultiSelect
					idFields={VIEW_REPLY_PANEL_ID_FORMAT}
					id={"view-panel-list"}
					staticHeaderText={I("Show/Hide")}
					show={this.state.showViewPanelList}
					data={availableReplyPanel}
					selected={selectedReplyPanel ? selectedReplyPanel.join(","):""}
					onToggle={this.handleToggleViewPanelList}
					onSelect={onSelectedViewPanel}
					clickEventReturnCurrentSelected={true}
				/>
			</div>
			)
	}
}

class ReplyToolbar extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const {
				allowChangeChannel
				, children
				, className
				, enableAction
				, hideToolbar
				, noChannelSelection
				, replyAs
				, show
				, showChannelOpt
				, toolbarID
				, showSpellcheck
				, preferredSpellcheckLang
				, spellcheckLangs
				, editorId
				, ...p
			} = this.props
			, hasChannel = !noChannelSelection
			;
		let hide;
		let allowCollab = (replyAs === RPLY_COLLABORATE) && (
			p.collabSlackEnabled || p.collabMSTeamEnabled || p.collabJiraEnabled || p.collabGoogleChatEnabled);
		if (!show) {
			hide = true;
		}
		let chnOptions = p.options
		let selectedChn = p.selectedChannel;
		if (allowCollab) {
			chnOptions = p.collabOpts;
			selectedChn = p.selectedCollabChannel;
		}
		return (
			<span
				className={classNames("reply-toolbar", className)}
				hidden={hide}
			>
				{children}
				<Channel
					hidden={!showChannelOpt || !hasChannel}
					myClass={classNames(
						'channel',
						{
							'collab-channel-select': allowCollab,
							'channel-select': !allowCollab
						}
					)}
					onSelect={p.onSelectChannel}
					onToggle={p.onToggleChannel}
					options={chnOptions}
					readOnly={!allowChangeChannel &&
						replyAs !== RPLY_MANUAL &&
						!allowCollab
					}
					selected={selectedChn}
					show={p.showChannelSelection}
				/>
			{
				(p.availableReplyPanel && p.availableReplyPanel.length > 0) &&
					<div
						className={classNames({
							'show-option-slack': allowCollab,
							'show-option-select': !allowCollab
						})}
					>
						<ToggleReplyBar
							show={true}
							availableReplyPanel={p.availableReplyPanel}
							selectedReplyPanel={p.selectedReplyPanel}
							onSelectedViewPanel={p.onSelectedViewPanel}
						/>
					</div>
			}
			{toolbarID && <div
				className='toolbar'
				data-qa-id={p['data-qa-id']}
				id={toolbarID}
				hidden={hideToolbar}
			/>}
			</span>
		);
	}
}

export default ReplyToolbar;
