import React from "react";
import classNames from 'classnames';
import styled from 'styled-components';
import {
    SIP_CALL_CONNECTING
    , SIP_CALL_CONNECTED
    , SIP_CALL_IDLE
    , SIP_CALL_ON_HOLD
    , SIP_CALL_WAITING_CONNECTION
    , SIP_OUTGOING_CALL_CONNECTED
    , SIP_OUTGOING_CALL_CONNECTING
    , CALL_STR_DURATION
	, SIP_REGISTER
	, SIP_CONNECTED
	, SIP_DISCONNECTED
	, txtTransferCallToAgent
	, txtIncomingTransferToAgent
	, txtOutgoingTransferToAgent
	, WARM_TRANSFER
	, COLD_TRANSFER
} from '../../common/v5/callConstants.js';
import {
    MP_BASIC_CALL
} from '../../common/v5/constants.js';
import { getWebRtcStats } from "../../redux/actions/async/sippRtc";
import {
    msToHMS
} from '../../common/v5/helpers';
import {
    SimpleDialPadCtnr
} from '../../containers/call';
import {
    playBeepNotif
} from '../../redux/actions/async/sippRtc';
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import Draggable from 'react-draggable';
import {
	centionGreen
	, centionRed
	, centionYellow
 } from '../../styles/_variables'

const PingDisplay = ({ ping }) => {
    let color;
    if (ping == null) {
        color = "#FFFFFF";
    } else if (ping <= 20) {
        color = '#00CC77';
    } else if (ping <= 150) {
        color = "lightgreen";
    } else if (ping <= 300) {
        color = "yellow";
    } else {
        color = "#F4213F";
    }

    const roundedPing = Math.round(ping);

    return (
        <div style={{ alignItems: "center", marginLeft: "10px" }}>
            <div style={{ position: "relative" }}>
                <svg 
                    viewBox="0 0 24 24" 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="20" 
                    height="20"
                    onMouseEnter={() => document.getElementById('tooltip').style.visibility = 'visible'}
                    onMouseLeave={() => document.getElementById('tooltip').style.visibility = 'hidden'}
                >
                    <title />
                    <path d="M10,20a1,1,0,0,1-1-1V16a1,1,0,0,1,2,0v3A1,1,0,0,1,10,20Z" fill={color} />
                    <path d="M18,20a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0v6A1,1,0,0,1,18,20Z" fill={color} />
                    <path d="M6,20a1,1,0,0,1-1-1V10a1,1,0,0,1,2,0v9A1,1,0,0,1,6,20Z" fill={color} />
                    <path d="M14,20a1,1,0,0,1-1-1V6a1,1,0,0,1,2,0V19A1,1,0,0,1,14,20Z" fill={color} />
                </svg>
                <div id="tooltip" style={{
                    visibility: "hidden",
                    backgroundColor: "#00000080",
                    color: "#fff",
                    fontSize:11,
                    textAlign: "center",
                    borderRadius: "6px",
                    padding: "5px",
                    position: "absolute",
                    zIndex: 1,
                    bottom: "150%",
                    transform: "translateX(-50%)",
                    width: "auto",
                    height:"auto",
                    opacity: 1
                }}>
                     {ping == null ? "No ping" : `Ping: ${Math.round(ping)} ms`}
                </div>
            </div>
        </div>
    );
};





export class SipTransferNotification extends React.Component {
    constructor(props) {
        super(props);
        this.handleAcceptTransferCall = this.handleAcceptTransferCall.bind(this);
        this.handleAnswer = this.handleAnswer.bind(this);
        this.handleShowDtmfKeypad = this.handleShowDtmfKeypad.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCallPause = this.handleCallPause.bind(this);
    }
    handleAcceptTransferCall() {
		if(this.props.sipCallTransferStatus != SIP_CALL_CONNECTED &&
			this.props.sipCallTransferStatus != SIP_OUTGOING_CALL_CONNECTED){
            return;
        }
        let manualEid = this.props.manualCallEid;
        let dispId = "";
        let eid = this.props.eid;
        let isManual = false;
        let cipherKey = "";
		if(this.props.isCurrentErrand) {
            cipherKey = this.props.currentCipherKey;
        }
		if(manualEid != 0 && !this.props.isCurrentErrand){
            isManual = true;
            eid = manualEid;
            dispId = this.props.manualCallDispId;
            cipherKey = this.props.mCallCipherKey;
		} else if(eid != 0 && this.props.errandData != null &&
            typeof this.props.errandData.data !== "undefined" &&
			this.props.errandData.data !== null){
            dispId = this.props.errandData.data.displayId;
            eid = this.props.eid;
        }
		if(!this.props.outgoing && this.props.manualCallEid === 0 &&
            (!features["sip.auto-create-errand"] &&
                features["aventa-enabled"] == false)) {
            eid = 0;
        }
        this.props.handleFinalizeWarmTransferToAgent(eid,
            dispId, this.props.sipConn, cipherKey, isManual, this.props.sipCallTransferIsExternal)
        this.props.toggleDtmfKeypad(false);
    }
    handleAnswer() {
		if(this.props.sipCallTransferStatus != SIP_CALL_CONNECTED &&
            this.props.sipCallTransferStatus != SIP_CALL_CONNECTING &&
            this.props.sipCallTransferStatus != SIP_OUTGOING_CALL_CONNECTED &&
			this.props.sipCallTransferStatus != SIP_OUTGOING_CALL_CONNECTING){
            return;
        }
        this.props.closeXferSipCall(this.props.sipConn,
            this.props.sipCallTransferStatus);
    }
    handleCallPause() {
		if(this.props.sipCallTransferStatus == SIP_CALL_CONNECTING ||
            this.props.sipCallTransferStatus == SIP_OUTGOING_CALL_CONNECTING ||
            this.props.sipCallTransferStatus == SIP_CONNECTED ||
			this.props.sipCallTransferStatus == SIP_CALL_IDLE){
            return
        }
		if(this.props.sipCallTransferStatus == SIP_CALL_CONNECTED||
			this.props.sipCallTransferStatus == SIP_OUTGOING_CALL_CONNECTED){
            this.props.pauseSipTransferCall(this.props.eid, this.props.sipConn,
                true);
        } else {
            this.props.pauseSipTransferCall(this.props.eid, this.props.sipConn,
                false);
        }
        playBeepNotif();
    }
    handleShowDtmfKeypad() {
		if(this.props.sipCallTransferStatus != SIP_CALL_CONNECTED){
            return;
        }
        this.props.toggleDtmfKeypad(!this.props.sipShowDtmfKeypad);
    }
	handleClose(){
		if(this.props.sipCallTransferStatus != SIP_CALL_CONNECTED  &&
            this.props.sipCallTransferStatus != SIP_CALL_CONNECTING &&
            this.props.sipCallTransferStatus != SIP_OUTGOING_CALL_CONNECTING &&
			this.props.sipCallTransferStatus != SIP_OUTGOING_CALL_CONNECTED){
            return;
        }
        this.props.closeXferSipCall(this.props.sipConn,
            this.props.sipCallTransferStatus);
    }
    render() {
        const { data, sipCallStatus, toInputValue,
            onDeleteClick, onChangeToInput, sipConn,
            sipCallTransferStatus } = this.props;
        let msg;
        let popupClass = "sip-incoming-notification";
		if(this.props.type === "error"){
            popupClass += " error";
        }
        let callCircle = "green-circle";
        let connectingtoAgentText = this.props.outgoing ? txtOutgoingTransferToAgent : txtIncomingTransferToAgent;
        let showTimer = false, callInProgress = false;
        let transferCircle = "green-circle";
		if(sipCallTransferStatus != SIP_CALL_CONNECTED &&
			sipCallTransferStatus != SIP_OUTGOING_CALL_CONNECTED){
            connectingtoAgentText = txtTransferCallToAgent;
            transferCircle = "grey-circle";
        }
		switch(sipCallStatus) {
            case SIP_CALL_CONNECTED:
            case SIP_OUTGOING_CALL_CONNECTED:
                callInProgress = true;
            case SIP_CALL_ON_HOLD:
                showTimer = true;
                callCircle = "red-circle";
                break;
            case SIP_CALL_CONNECTING:
            case SIP_OUTGOING_CALL_CONNECTING:
                callCircle = "green-circle";
                break;
            case SIP_CONNECTED:
            case SIP_CALL_IDLE:
				callCircle="grey-circle";
                break;
        }
        let pauseCircle = ""
		if(sipCallTransferStatus == SIP_CALL_ON_HOLD){
            pauseCircle = "grey-circle"
        }
        let contactCardName = "";
		let agentPhoto=null;
        let hideName = true;
        let targetAgent = "";
		if(this.props.sipCallTransferTargetNumber != ""){
            targetAgent = this.props.sipCallTransferTargetNumber.replace("sip:",
                "")
            hideName = false;
        }
        let iTimes = {
            fontSize: '14px'
        };
		if(this.props.sipTransferTargetAvatar !== "") {
            agentPhoto = this.props.sipTransferTargetAvatar;
        }
        return (
            <div className="sip-incoming-notification">
                <div className="notification-image">
                    <ProfilePhoto width={"18"} isAgent={false}
                        photo={agentPhoto} />
                </div>
                <div className="notification-text">
                    <div className="caller-address">
                        <i className="icon-call"></i>
                        <span className="caller-name" hidden={hideName}>
                            {targetAgent}
                        </span>
                    </div>
                    <div className="notification-timer-text">
                        {connectingtoAgentText}
                    </div>
                </div>
                <div className="vertical-line">
                </div>
                <div className="notification-action">
                    <div className="notification-icons">
                        <i className={classNames("icon-call",
                            callCircle)}
                            title={I("Answer")}
                            onClick={this.handleAnswer}>
                        </i>
                        <i className={classNames("icon-forward-call",
                            transferCircle)}
                            title={I("Transfer")}
                            onClick={this.handleAcceptTransferCall}>
                        </i>
                        <i className={classNames("icon-pause",
                            pauseCircle)}
                            title={I("Hold")}
                            onClick={this.handleCallPause}>
                        </i>
                        <i className={classNames("icon-keypad", transferCircle)}
                            title={I("DTMF")}
                            onClick={this.handleShowDtmfKeypad}>
                        </i>
                        <i className="icon-times"
                            style={iTimes}
                            title={I("Close")}
                            onClick={this.handleClose}>
                        </i>
                    </div>
                </div>
            </div>
        );
    }
}

const StyledDTMFKeypad = styled.div`
	position: relative
	z-index: 9999
	padding-top: 5px
	padding-bottom: 5px
	display: table-row
`

export class SipDtmfKeypad extends React.Component {
    constructor(props) {
        super(props);
        this.handleDtmf = this.handleDtmf.bind(this);
    }
	handleDtmf(key){
        if(this.props.sipCallTransferStatus == SIP_CALL_CONNECTED ||
            this.props.sipCallTransferStatus == SIP_OUTGOING_CALL_CONNECTED) {
            this.props.sendDtmfToTransferAgent(this.props.sipCallConn, key);
        }
    }
    render() {
        const p = this.props;
        return (
            <Draggable bounds={"parent"}>
                <StyledDTMFKeypad>
                    <SimpleDialPadCtnr
                        hidden={false}
                        onChangeDtmf={this.handleDtmf}
                        type={MP_BASIC_CALL}
                        embedded={false}
                        withClose={true}
                    />
                </StyledDTMFKeypad>
            </Draggable>
        );
    }
}

const StyledTransferBtn = styled.button`
	border: 1px solid #0c87f7;
	padding: 10px 5px;
	background: ${props => props.mode === WARM_TRANSFER ? "#fff" : "#0c87f7"};
	color: ${props => props.mode === WARM_TRANSFER ? "#0c87f7" : "#fff"};
	opacity: ${props => props.disabled ? "0.35" : "1.0"};
	pointer-events: ${props => props.disabled ? "none" : "auto"};
	cursor: pointer;
	font-size: 12px;
`

export class SIPTransferButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        const p = this.props;
        this.props.onClick();
    }
    render() {
		const p = this.props, {disabled, text, id, type} = p;
		return <StyledTransferBtn data-qa-id={"QA_btn_"+id} id={id} mode={type} onClick={this.handleClick}
            className={p.className} title={text} disabled={disabled}>{text}
        </StyledTransferBtn>;
    }
}

const StyledAgentList = styled.div`
	border-color: #56acf9;
	background: #fff;
	color: #6d6d6d;
	padding: 5px;
	box-shadow: 0 0 8px 0 rgb(0 0 0 / 35%);
`

export class SipAgentList extends React.Component {
    constructor(props) {
        super(props);
        this.populateAgentList = this.populateAgentList.bind(this);
        this.handleAgentSelected = this.handleAgentSelected.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleWarmTransfer = this.handleWarmTransfer.bind(this);
        this.handleColdTransfer = this.handleColdTransfer.bind(this);
        this.state = {
            eid: 0,
            uid: 0,
            callString: "",
            dispId: 0,
            isManual: false
        };
    }
    handleAgentSelected(uid, sipid) {
        if (this.props.sipCallStatus != SIP_CALL_CONNECTED &&
            this.props.sipCallStatus != SIP_CALL_ON_HOLD &&
            this.props.sipCallStatus != SIP_OUTGOING_CALL_CONNECTED) {
            console.info("sip call is not connected or on hold. cannot transfer");
            return;
        }
        let callString = "";
        if (uid != 0 && sipid != "") {
            if (sipid.startsWith("sip:") == false) {
                callString = "sip:";
            }
            callString += sipid;
            if (sipid.includes("@") == false) {
                callString += "@" + this.props.sipServerName;
            }
        } else {
            return;
        }
        let dispId = "";
        let eid = this.props.eid;
        let isManual = false;
        let manualEid = this.props.manualCallEid;
        if (this.props.eid != 0 && this.props.errandData != null &&
            typeof this.props.errandData.data !== "undefined" &&
            this.props.errandData.data !== null) {
            dispId = this.props.errandData.data.displayId;
            eid = this.props.id;
        } else if (manualEid != 0 && !this.props.isCurrentErrand) {
            isManual = true;
            eid = this.props.manualCallEid;
            dispId = this.props.manualCallDispId;
        }

        this.setState({
            uid: uid,
            callString: callString,
            isManual: isManual,
            eid: eid,
            dispId: dispId
        });
    }
    handleWarmTransfer() {
        let cipherKey = this.props.mCallCipherKey, eid = this.props.eid;
        this.props.toggleTransferAgentList(false);
        if (!features["sip.auto-create-errand"] && !this.props.outgoing) {
            eid = 0;
        }
        this.props.handleWarmTransferToAgent(eid, this.state.uid,
            this.state.callString, this.props.sipConn, this.state.dispId,
            cipherKey, this.state.isManual);
    }
    handleColdTransfer() {
        let cipherKey = this.props.mCallCipherKey, eid = this.state.eid;
        this.props.toggleTransferAgentList(false);
        if (!features["sip.auto-create-errand"] && !this.props.outgoing) {
            eid = 0;
        }
        this.props.handleColdTransferToAgent(eid, this.state.uid,
            this.state.callString, this.props.sipConn, this.state.dispId,
            cipherKey, this.state.isManual);
    }
    populateAgentList() {
        const p = this.props;
        let agents = [];
        if (typeof p.sipCallAgentList !== 'undefined' &&
            p.sipCallAgentList.length > 0) {
            $.each(p.sipCallAgentList, (i, agent) => {
                let selectedAgent = false;
                if (agent.id === this.state.uid) {
                    selectedAgent = true;
                }
                agents.push(
                    <li
                        className={classNames("available", { selected: selectedAgent })}
                        key={agent.id}
                        value={agent.sipid}>
                        <a data-qa-id={"agent-list-" + agent.id}
                            onClick={(e) => this.handleAgentSelected(agent.id,
                                agent.sipid)}>
                            {agent.username}
                        </a>
                    </li>
                );
            });
        } else {
            agents.push(
                <li style={{ marginLeft: "22px", fontSize: "12px" }} className={"not-available"}
                    key={0}
                    value={0}>
                    <a data-qa-id={"agent-list-0"}
                        onClick={(e) => this.handleAgentSelected(0, 0)}>
                        {I("No available agents")}
                    </a>
                </li>
            );
        }
        return agents
    }
    handleClose() {
        this.props.toggleTransferAgentList(false);
    }
    render() {
        const p = this.props;
        let agentList = this.populateAgentList();
        let disabledBtn = true;
        if (this.state.uid > 0) {
            disabledBtn = false;
        }
        return (
            <StyledAgentList>
                <div>
                    <div className={"sip-agent-list-header"} style={{ margin: "10px" }}>
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>{I("Transfer call to:")}</span>
                        <i className={classNames("icon-times",
                            "sip-agent-header-icon")}
                            onClick={this.handleClose}>
                        </i>
                    </div>
                    <div className={"sip-agent-list-list"}>
                        <ul className={"sip-agent-list-ul"}>
                            {agentList}
                        </ul>
                    </div>
                    <div className="sip-transfer-mode" style={{ display: "inline-flex", margin: "10px" }}>
                        <div className="attended-button" style={{ marginRight: "10px" }}>
                            <SIPTransferButton
                                id="button-unattended"
                                onClick={this.handleWarmTransfer}
                                text={I("Attended transfer")}
                                type={WARM_TRANSFER}
                                disabled={disabledBtn} />
                        </div>
                        <div className="unattended-button">
                            <SIPTransferButton
                                id="button-unattended"
                                onClick={this.handleColdTransfer}
                                text={I("Unattended transfer")}
                                type={COLD_TRANSFER}
                                disabled={disabledBtn} />
                        </div>
                    </div>
                </div>
            </StyledAgentList>
        );
    }
}

export class CallTimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: 0,
            isOn: false,
        }
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
    }
    componentDidMount() {
        if (!this.props.outgoing) {
            this.props.resetSipTimer(Date.now());
        } else {
            if (this.props.callInProgress) {
                this.startTimer(); //fixme: react js error on change state on unmounted component
            }
        }
    }
    componentWillUnmount() {
        if (!this.props.callInProgress) {
            this.props.resetSipTimer(0);
            this.resetTimer();
        }
        clearInterval(this.timer)
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.callInProgress) {
            if (this.props.callInProgress) {
                this.startTimer();
            }
        } else {
            if (prevProps.callInProgress) {
                if (!this.props.callInProgress) {
                    if (typeof this.props.sipCallStatus !== 'undefined') {
                        this.stopTimer();
                        this.props.resetSipTimer(0);
                    }
                }
            }
        }
    }
    startTimer() {
        this.setState({
            isOn: true,
            time: this.state.time,
        })
        this.timer = setInterval(() => this.setState({
            time: Date.now() - this.props.sipCallTimer
        }), 1);
    }
    stopTimer() {
        this.setState({ isOn: false })
        clearInterval(this.timer)
        this.props.onStop(this.state.time);
    }
    resetTimer() {
        this.setState({ time: 0, isOn: false });
    }
    render() {
        let msgTxt = "";
        let addSpace = false;
        if (typeof this.props.msgTxt !== 'undefined' &&
            this.props.msgTxt != "") {
            msgTxt = this.props.msgTxt;
            addSpace = true;
        }
        let time = msToHMS(this.state.time);
        return (
            <div className="cention-video-timer">
                <span className="notification-timer"
                    hidden={!this.props.callInProgress || this.props.msgTxt === I("Connecting")}>
                    {time}
                </span>
                <span className="notification-timer-text" hidden={!msgTxt}>
                    &nbsp;{msgTxt}
                </span>
            </div>
        )
    }
}

const CallStatusDot = styled.span`
	display: contents;
	&:before {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 30px;
		background-color: #ccc;
		content: '';
		left: 5px;
		top: 2px;
		position: relative;
		transition: background-color .2s ease;
		background: ${props => (props.status == SIP_CALL_CONNECTED || props.status == SIP_OUTGOING_CALL_CONNECTED || props.status == SIP_CALL_ON_HOLD) ? centionGreen : (props.status == SIP_CALL_CONNECTING || props.status == SIP_OUTGOING_CALL_CONNECTING) ? centionYellow : centionRed};
	}
`

export default class SipNotification extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleAnswer = this.handleAnswer.bind(this);
        this.handleTransferCall = this.handleTransferCall.bind(this);
        this.handleCallPause = this.handleCallPause.bind(this);
        this.handleCallRecord = this.handleCallRecord.bind(this);
        this.handleStopTimer = this.handleStopTimer.bind(this);
        this.handleMaximize = this.handleMaximize.bind(this);
        this.handleMakeCall = this.handleMakeCall.bind(this);
        this.handleHangupCall = this.handleHangupCall.bind(this);
        this.handleClickClose = this.handleClickClose.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.state = {
            connecting: false,
            ping: null
        }
    }
    componentDidMount() {
        this.pingInterval = setInterval(async () => {
            if (this.props.sipConn && this.props.sipConn.conn && this.props.sipConn.conn.session) {
                let propo = this.props.sipConn.conn.session;
                let ping = await getWebRtcStats(propo);
                this.setState({ ping });
            }
        }, 5000); // this will update ping every 5 seconds
    }

    componentWillUnmount() {
        clearInterval(this.pingInterval);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.sipCallStatus == SIP_CALL_CONNECTED ||
            prevProps.sipCallStatus == SIP_CALL_ON_HOLD) &&
            this.props.sipCallStatus == SIP_CONNECTED) {
            if (this.props.sipCallIsRecording == true) {
                this.props.recordSipCall(this.props.eid, this.props.sipConn,
                    !this.props.sipCallIsRecording);
            }
        }
        if (!this.props.outgoing) {
            if (prevProps.sipCallStatus === SIP_CALL_CONNECTING && this.props.sipCallStatus === SIP_CALL_WAITING_CONNECTION) {
                this.setState({ connecting: true });
            } else if (prevProps.sipCallStatus === SIP_CALL_WAITING_CONNECTION && this.props.sipCallStatus === SIP_CALL_CONNECTED) {
                this.setState({ connecting: false });
            } else if (this.props.sipCallStatus === SIP_CONNECTED &&
                this.state.connecting == true) {
                this.setState({ connecting: false });
            }
        }
    }
    handleClose(closePopup) {
		if (this.props.outgoing) {
			if (this.props.sipCallStatus === SIP_CALL_CONNECTING || this.props.sipCallStatus === SIP_CALL_CONNECTED ||
				this.props.sipCallStatus === SIP_OUTGOING_CALL_CONNECTING || this.props.sipCallStatus === SIP_OUTGOING_CALL_CONNECTED) {
				console.log("call still ongoing, cannot close");
			} else {
				this.props.closeSipCall(this.props.sipConn, this.props.sipCallStatus,
					closePopup, true, this.props.isCurrentErrand);
				this.setState({ ping: null });
			}
		} else {
			if (this.props.sipCallCurrentTransferMode === WARM_TRANSFER &&
				(this.props.sipTransferStatus === SIP_CALL_CONNECTING || this.props.sipTransferStatus === SIP_CALL_CONNECTED)) {
				alert(I("Please complete the call transfer."));
			} else {
				if (this.props.sipCallIsRecording == true) {
					this.props.recordSipCall(this.props.eid, this.props.sipConn,
						!this.props.sipCallIsRecording);
				}
				this.props.closeSipCall(this.props.sipConn, this.props.sipCallStatus,
					true, false);
				this.setState({ ping: null });
			}
		}
	}
	
    handleAnswer() {
        if (this.props.sipCallStatus == SIP_CALL_CONNECTED ||
            this.props.sipCallStatus == SIP_CALL_ON_HOLD) {
            if (this.props.sipCallCurrentTransferMode === WARM_TRANSFER &&
                (this.props.sipTransferStatus === SIP_CALL_CONNECTING || this.props.sipTransferStatus === SIP_CALL_CONNECTED)) {
                alert(I("Please complete the call transfer."));
            } else {
                if (this.props.sipCallIsRecording == true) {
                    this.props.recordSipCall(this.props.eid, this.props.sipConn,
                        !this.props.sipCallIsRecording);
                }
                this.props.closeSipCall(this.props.sipConn,
                    this.props.sipCallStatus, false, false);
            }
        } else if (this.props.sipCallStatus != SIP_CONNECTED &&
            this.props.sipCallStatus != SIP_CALL_WAITING_CONNECTION) {
            this.props.answerSipCall(this.props.sipConn,
                this.props.sipXferRefId);
        }
    }
    handleMakeCall() {
        if (this.props.sipCallStatus == SIP_DISCONNECTED) {
            return;
        }
        if (this.props.sipCallStatus == SIP_CONNECTED ||
            this.props.sipCallStatus == SIP_CALL_IDLE ||
            this.props.sipCallStatus == SIP_REGISTER) {
            this.props.onCallClick();
        } else {
            if (this.props.sipCallIsRecording == true) {
                this.handleCallRecord();
            }
            this.handleHangupCall();
        }
    }
    handleHangupCall() {
        this.props.onHangupCall();
    }
    handleTransferCall() {
        if (this.props.sipHideColdTransfer === true &&
            this.props.sipHideWarmTransfer === true) {
            return;
        }
        if (this.props.sipCallStatus == SIP_CALL_CONNECTED || this.props.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED ||
            this.props.sipCallStatus == SIP_CALL_ON_HOLD) {
            this.props.answerTransferCall(this.props.sipConn, this.props.eid);
        }
    }
    handleCallPause() {
        if (this.props.sipCallStatus == SIP_CALL_CONNECTING || this.props.sipCallStatus == SIP_OUTGOING_CALL_CONNECTING ||
            this.props.sipCallStatus == SIP_CONNECTED ||
            this.props.sipCallStatus == SIP_CALL_IDLE) {
            return
        }
        if (this.props.sipCallStatus == SIP_CALL_CONNECTED || this.props.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED) {
            this.props.pauseSipCall(this.props.eid, this.props.sipConn, true);
        } else {
            this.props.pauseSipCall(this.props.eid, this.props.sipConn, false);
        }
        playBeepNotif();
    }
    handleCallRecord() {
        if (this.props.sipCallStatus == SIP_CALL_CONNECTED || this.props.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED) {
            console.info("calling recordSipCall");
            this.props.recordSipCall(this.props.eid, this.props.sipConn,
                !this.props.sipCallIsRecording);
        }
    }
    handleStopTimer(callTime) {
        if (typeof this.props.commonApiCall !== 'undefined' &&
            this.props.commonApiCall != null) {
            this.props.commonApiCall(CALL_STR_DURATION, this.props.data.data,
                false, Math.round(callTime / 1000));
        }
        this.closeNotification();
    }
    closeNotification() {
        setTimeout(() => {
            this.handleClose(true);
        }, 3000);
    }
    handleClickClose() {
        this.handleClose();
    }
    handleMaximize() {
        this.props.onMaximizeOutgoingCall();
    }
    render() {
        const { data, sipCallStatus, sipAllowRecord, toInputValue,
            onDeleteClick, onChangeToInput, sipConn, outgoing,
            sipSnoopDisplayString, sipChatCallbackEnabled } = this.props;
        let msg, connectStatus;
        let popupClass = "sip-incoming-notification";
        if (this.props.type === "error") {
            popupClass += " error";
        }
        let callCircle = "green-circle";
        let forwardCircle = "";
        let incomingText = I("Incoming Call");
        let outgoingText = I("Outgoing Call");
        let callTxt = I("Answer");
        let showTimer = false, callInProgress = false;
        let hideRejectCall = false;
        if (data.show == false) {
            return null;
        }
        if (typeof sipConn !== 'undefined' && sipConn != null &&
            typeof sipConn.conn !== 'undefined' && sipConn.conn != null) {
            if (sipConn.conn.isRefer) {
                incomingText = I("Transferred Call");
            }
        }
        if (this.state.connecting) {
            incomingText = I("Waiting for connection");
        }
        if (sipSnoopDisplayString != "") {
            incomingText = sipSnoopDisplayString;
        }
        switch (sipCallStatus) {
            case SIP_CALL_CONNECTED:
            case SIP_OUTGOING_CALL_CONNECTED:
                showTimer = true;
                callInProgress = true;
                callCircle = "red-circle";
                forwardCircle = "green-circle";
                outgoingText = I("Outgoing Call");
                callTxt = I("Hang up");
                break;
            case SIP_CALL_ON_HOLD:
                showTimer = true;
                callInProgress = true;
                callCircle = "red-circle";
                forwardCircle = "grey-circle";
                callTxt = I("Hang up");
                break;
            case SIP_CALL_CONNECTING:
                callCircle = "green-circle";
                outgoingText = I("Connecting");
                if (features["sip.disable.reject.call"]) {
                    hideRejectCall = true;
                }
                break;
            case SIP_OUTGOING_CALL_CONNECTING:
                callCircle = "red-circle";
                outgoingText = I("Connecting");
                callTxt = I("Hang up");
                break;
            case SIP_CONNECTED:
            case SIP_CALL_IDLE:
                if (this.props.outgoing) {
                    callCircle = "green-circle";
                } else {
                    callCircle = "grey-circle";
                }
                forwardCircle = "grey-circle";
                break;
        }
        if (this.props.sipHideColdTransfer === true &&
            this.props.sipHideWarmTransfer === true) {
            forwardCircle = "grey-circle";
        }
        let pauseCircle = ""
        if (sipCallStatus == SIP_CALL_ON_HOLD) {
            pauseCircle = "grey-circle"
        }
        let recordCircle = ["white-circle"];
        if (this.props.sipCallIsRecording == true) {
            recordCircle = ["red-blink", "white-color"];
        }
        //call errand not ready or on hold
        let errandNotReady = false;
        if (features["sip.auto-create-errand"] && (!this.props.outgoing && this.props.sipCurrentEid === 0)) {
            errandNotReady = true;
        }
        if (errandNotReady || sipCallStatus === SIP_CALL_ON_HOLD) {
            forwardCircle = "grey-circle disabled"
        }
        let contactCardName = "";
        let avatarPhoto = null;
        let hideName = true;
        if (data.avatar != null && data.avatar.nameOnContactCard != "" &&
            data.avatar.avatar != null) {
            contactCardName = data.avatar.nameOnContactCard;
            avatarPhoto = data.avatar.avatar.url;
            hideName = false;
        } else {
            avatarPhoto = data.avatar;
        }
        let outgoingErrandId = "";
        if (this.props.outgoing) {
            outgoingErrandId = this.props.activeCreatedErrand;
        }
        return (
            <div className="sip-incoming-notification" hidden={!data.show}>
                <PingDisplay ping={this.state.ping} />
                <div className="notification-image">
                    <ProfilePhoto width={"18"} isAgent={false}
                        photo={avatarPhoto} />
                </div>
                <div className="notification-text">
                    <div className="caller-address">
                        <i className={sipCallStatus === SIP_CALL_ON_HOLD ? "icon-pause" : "icon-call"}></i>
                        <span className="caller-name" hidden={hideName}>
                            {contactCardName}
                        </span>
                        <span className="caller-number" >
                            {data.data}
                        </span>
                    </div>
                    <div className="call-text">
                        <span className="call-eid" hidden={!this.props.outgoing} >{"#" + outgoingErrandId}</span>
                        <span className="call-eid" hidden={this.props.sipCurrentEid == 0 || this.props.outgoing} >{"#" + this.props.sipCurrentEid + "-1"}</span>
                        {(outgoingErrandId != "" || this.props.sipCurrentEid > 0) ? "|" : ""}
                        <CallStatusDot status={sipCallStatus} />
                        <CallTimer callInProgress={showTimer}
                            key="sip-call-timer-2" id={"call-timer"}
                            onStop={this.handleStopTimer}
                            msgTxt={this.props.outgoing ? outgoingText : incomingText}
                            sipCallTimer={this.props.sipCallTimer}
                            resetSipTimer={this.props.resetSipTimer}
                            sipCallStatus={sipCallStatus}
                            outgoing={this.props.outgoing}
                        />
                    </div>
                </div>
                <div className="vertical-line">
                </div>
                <div className="notification-action">
                    <div className="notification-top-icons">
                        {(sipAllowRecord && callInProgress && sipSnoopDisplayString == "") && <i className={classNames("icon-record",
                            recordCircle)}
                            title={I("Record")}
                            onClick={this.handleCallRecord}>
                        </i>}
                        <i className="fa fa-spinner fa-spin grey-circle" style={{ cursor: "auto" }} hidden={!this.state.connecting} />
                        <i className={classNames("icon-call",
                            callCircle)}
                            hidden={this.state.connecting}
                            title={outgoing ? I("Call") : callTxt}
                            onClick={outgoing ? this.handleMakeCall : this.handleAnswer}>
                        </i>
                        {(sipSnoopDisplayString == "") && <i className={classNames("icon-forward-call",
                            forwardCircle)}
                            title={I("Transfer")}
                            hidden={!callInProgress} onClick={this.handleTransferCall}>
                        </i>}
                        <i className={classNames("icon-pause",
                            pauseCircle)}
                            hidden={!callInProgress}
                            title={I("Hold")}
                            onClick={this.handleCallPause}>
                        </i>
                        <i className="icon-times" hidden={hideRejectCall}
                            title={I("Close")}
                            onClick={this.handleClickClose}>
                        </i>
                        <i className="icon-maximize" hidden={!outgoing || sipChatCallbackEnabled} title={I("Maximize")} onClick={this.handleMaximize}></i>
                    </div>
                </div>
            </div>
        );
    }
}
