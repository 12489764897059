import React, { PureComponent, Fragment, useEffect, useState } from 'react';
import { BootstrapTable as OldBootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { SelectionBox } from '../../reactcomponents/SelectBox';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import TagPicker from './TagPicker';
import {
	SerialMultiSelect,
	OrderSingleSelect
} from '../../reactcomponents/Dropdown';
import {
	getHours,
	getMinutes,
	getListSizeBasedOnHeight,
	formatValueToNameObj,
	FormatOptions
} from '../../common/v5/helpers';
import {
	RELATIVE_DATE_TYPES,
	EXPORT_TYPES,
	REPORT_RUNNING_DAY,
	SCHEDULED_REPORT,
	ERRAND_PAGINATION_SIZE_OPT,
	AGENT_TYPES,
	AT_CHOOSE_GROUPS,
	AT_CHOOSE_AGENTS,
	RC_EMAIL,
	BTN_TXT_SAVE,
	BTN_TXT_SAVING,
} from '../../common/v5/constants';
import {
	TABLE_BASE_CLASSNAME,
	TABLE_HEADER_STYLE
} from '../../reactcomponents/common';
import {
	PaginationSize
} from '../../reactcomponents/SimpleTable';
import {
	TextField,
	ReadOnly,
	TableIconicButton,
	FormInputWithLabelRow,
	SelectInputRow,
	TextInputRow
} from '../../reactcomponents/Form';
import Button from '../../reactcomponents/Button';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
	ListHeaderWrapper,
	ListContentWrapper,
	TableHeader,
	EditorHeader,
	AdminListAndEditLayout,
	withEditWrap
  } from '../../reactcomponents/Admin';

import { TABLE_STANDARD_OPTIONS } from "../../reactcomponents/Table";
import {
	useCallbackMultiValues,
} from '../../hooks/callback'
import { AlertWrapper } from "../../views/v5/admin";
import { DismissableAlert } from '../../reactcomponents/Error';
import { DeletePopup } from '../../reactcomponents/Dialog';
import { WorkflowInnerContainerCtnr } from '../../views/v5/workflowCtnr';
import PopupPage from "../../reactcomponents/PopupPage";
import { EditFormButtons, renderReportAction } from './CreateReport';
import { AddTextItemInputContainer } from '../../reactcomponents/AddTextItemInput';
import adminHelper from '../../components/adminHelper';

const RelatedDateType = () => {
	let r = [];
	$.each(RELATIVE_DATE_TYPES.order, (i, v) => {
		r.push({id: v, name: RELATIVE_DATE_TYPES[v]})
	});
	return r;
};

const ReportTypes = (types) => {
	let r = [];
	$.each(types, (i, v) => {
		r.push({id: v.Id, name: v.i18nName})
	});
	return r;
}

const FormatToObj = (list) => {
	let r = [];
	$.each(list , (i, v) => {
		if(i !== "order"){
			r.push({id: parseInt(i, 10), name: v});
		}
	});
	return r;
}

const ExportTypes = () => {
	let r = [];
	$.each(EXPORT_TYPES.order, (i, v) => {
		r.push({id: v, name: EXPORT_TYPES[v]})
	});
	return r;
};

const RunInterval = () => {
	let r = [];
	$.each(REPORT_RUNNING_DAY.order, (i, v) => {
		r.push({id: v, name: REPORT_RUNNING_DAY[v]})
	});
	return r;
}

//this is old schedule report form
class ScheduleReportOLD extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			nameWarning: "",
			emailWarning: "",
			toggleChannel: false,
			toggleArea: false,
			toggleAgentType: false,
			toggleAgent: false,
			toggleGroup: false
		};
		this.handleClose = this.handleClose.bind(this);
		this.handleName = this.handleName.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleHours = this.handleHours.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleDone = this.handleDone.bind(this);
		this.handleMinutes = this.handleMinutes.bind(this);
		this.handleTimeZone = this.handleTimeZone.bind(this);
		this.handleTimeFormat = this.handleTimeFormat.bind(this);
		this.handleFileFormat = this.handleFileFormat.bind(this);
		this.handleRunInterval = this.handleRunInterval.bind(this);
		this.handleSearchInterval = this.handleSearchInterval.bind(this);
		this.handleReportType = this.handleReportType.bind(this);
		this.handleToggleChannel = this.handleToggleChannel.bind(this);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.handleToggleAgentType = this.handleToggleAgentType.bind(this);
		this.handleToggleAgent = this.handleToggleAgent.bind(this);
		this.handleToggleGroup = this.handleToggleGroup.bind(this);
		this.handleSelectChannel = this.handleSelectChannel.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.handleSelectAgentType = this.handleSelectAgentType.bind(this);
		this.handleSelectAgents = this.handleSelectAgents.bind(this);
		this.handleSelectGroups = this.handleSelectGroups.bind(this);
		this.handleCompleteResult = this.handleCompleteResult.bind(this);
		this.handleFailValidation = this.handleFailValidation.bind(this);
	}
	handleClose() {
		this.props.showSchedule(false);
	}
	handleHours(v) {
		this.props.onSetScheduleReport("hour", v);
	}
	handleMinutes(v) {
		this.props.onSetScheduleReport("minute", v);
	}
	handleTimeZone(v) {
		this.props.onSetScheduleReport("timeZoneId", v);
	}
	handleTimeFormat(v) {
		this.props.onSetScheduleReport("timeStampFormat", v);
	}
	handleFileFormat(v) {
		this.props.onSetScheduleReport("outputType", v);
	}
	handleRunInterval(v) {
		this.props.onSetScheduleReport("time", v);
	}
	handleSearchInterval(v) {
		this.props.onSetScheduleReport("relativeDateType", v);
	}
	handleReportType(v) {
		this.props.onSetScheduleReport("reportId", v);
	}
	handleName(e) {
		this.props.onSetScheduleReport("reportName", e.target.value);
	}
	handleEmailChange(inputs) {
		let mails = "", validEmails = [];
		if(inputs && inputs.length > 0) {
			$.each(inputs, (i,v) => {
				validEmails.push(v);
			});
		}
		mails = validEmails.join(",");
		this.props.onSetScheduleReport("address", mails);
	}
	handleFailValidation(type, inpt) {
		if (type === RC_EMAIL) {
			if (this.props.onFailValidation) {
				this.props.onFailValidation(type, inpt);
			}
		}
	}
	handleToggleArea() {
		this.setState({
			toggleArea: !this.state.toggleArea
		});
	}
	handleSelectArea(v) {
		this.props.onSetScheduleReport("areas", v);
	}
	handleToggleChannel() {
		this.setState({
			toggleChannel: !this.state.toggleChannel
		});
	}
	handleSelectChannel(v) {
		this.props.onSetScheduleReport("channels", v);
	}
	handleSelectAgentType(v) {
		this.props.onSetScheduleReport("agentType", v);
	}
	handleSelectAgents(v) {
		this.props.onSetScheduleReport("users", v);
	}
	handleSelectGroups(v) {
		this.props.onSetScheduleReport("groups", v);
	}
	handleCompleteResult(v) {
		this.props.onSetScheduleReport("showCompleteResult", v);
	}
	handleToggleAgentType() {
		this.setState({
			toggleAgentType: !this.state.toggleAgentType
		});
	}
	handleToggleAgent() {
		this.setState({
			toggleAgent: !this.state.toggleAgent
		});
	}
	handleToggleGroup() {
		this.setState({
			toggleGroup: !this.state.toggleGroup
		});
	}
	handleSave() {
		const nr = this.props.newReport;
		let nw = I("* You can not leave the name field empty.");
		let ew = I("* You can not leave the email field empty.");
		if(nr.reportName === "") {
			this.setState({"nameWarning": nw});
			return;
		} else {
			this.setState({"nameWarning": ""});
		}
		if(nr.address === "") {
			this.setState({"emailWarning": ew});
			return;
		} else {
			this.setState({"emailWarning": ""});
		}
		this.props.onSetScheduleReport("buttonBusy", true)
		this.props.onSaveScheduleReport();
	}
	handleDone() {
		this.props.onResetScheduleReport();
		this.props.showSchedule(false);
		if(this.props.activeView === SCHEDULED_REPORT){
			this.props.onReloadList();
		}
	}
	render() {
		const p = this.props, newr = p.newReport;
		let updateMode = false, saveBtn = I("Create");
		if(p.activeScheduled > 0){
			updateMode = true;
			saveBtn = I("Update");
		}
		let cbClass = "schedule report popup";
		if(p.show) {
			cbClass = "schedule report popup open";
		}
		let disabled = false;
		let formOpt = p.otherOptions;
		let button = <Button color="blue"
			text={saveBtn} className="save-button"
			onClick={this.handleSave} />
		if(newr.buttonBusy) {
			let buttonText = <span>
					<i className="fa fa-spinner fa-spin"
					aria-hidden="true" /> {saveBtn}
				</span>
			button = <Button color="grey"
				text={buttonText} className="save-button" />
		}
		if(newr.id > 0 && newr.status === "done") {
			disabled = true;
			button = <Button color="blue"
				text={I("Done")} className="save-button"
				onClick={this.handleDone} />
		}
		let emailAddrs =  [];
		if(newr.address) {
			emailAddrs = newr.address.split(",");
		}
		if(p.show) {
			return(
				<div className={cbClass}>
					<div className="report-inner popup-inner schedule-inner">
						<div className="schedule-report-box">
							<div className="popup-tabs">
								<span className={"toggle-tabs"} >
									{I("Schedule report")}
								</span>
							</div>
							<form>
								<div className="schedule-report-form">
									<div className="label-row">
										<TextField className="schedule-name"
											onChange={this.handleName} value={newr.reportName}
											disabled={disabled} label={I('NAME: ')}
											warning={this.state.nameWarning}
											text={I('This name helps you identify this export if you want to remove it or make changes to it later.')} />
									</div>
									{updateMode &&
										<div className="label-row">
											<div className="label-block">
												<div className="label-report-type">
													<label htmlFor="report-type">{I('Report type ')}</label>
												</div>
												<div className="report-type">
													{disabled === false ?
														<SelectionBox options={ReportTypes(p.reportTypes)}
															name="report-type"
															selected={newr.reportId}
															onSelect={this.handleReportType} /> :
														<ReadOnly items={ReportTypes(p.reportTypes)}
															id={newr.reportId} />
													}
													{disabled === false &&
														<small className="report-field-tips">{I('This is the report that should be automatically exported. New reports can be created in the Create reports.')}</small>
													}
												</div>
											</div>
										</div>
									}
									<div className="label-row">
										<div className="label-block">
											<div className="run">
												<label htmlFor="run">{I('Run: ')}</label>
											</div>
											<div className="run-interval">
												{disabled === false ?
													<SelectionBox options={RunInterval()}
														name="run-interval" selected={newr.time}
														onSelect={this.handleRunInterval} /> :
													<ReadOnly items={RunInterval()}
														id={newr.time} />
												}
											</div>
											<div className="hours">
												{disabled === false ?
													<SelectionBox options={getHours()}
														name="hours" selected={newr.hour}
														onSelect={this.handleHours} /> :
													<ReadOnly items={getHours()}
														id={newr.hour} />
												}
											</div>
											<div className="clone-mark">:</div>
											<div className="minutes">
												{disabled === false ?
													<SelectionBox options={getMinutes()}
														name="minutes" selected={newr.minute}
														onSelect={this.handleMinutes} /> :
													<ReadOnly items={getMinutes()}
														id={newr.minute} />
												}
											</div>
											{p.features["show-time-zone"] &&
											<div className="timezones">
												{disabled === false ?
													<SelectionBox options={FormatOptions(p.timeZones)}
														name="timezones" selected={newr.timeZoneId}
														onSelect={this.handleTimeZone} /> :
													<ReadOnly items={FormatOptions(p.timeZones)}
														id={newr.timeZoneId} />
												}
											</div>
											}
										</div>
									</div>
									{formOpt.hasKeyFigureThatFilterByChannel &&
										<div className="label-row">
											<div className="label-block">
												<div className="label-channels">
													<label htmlFor="channels">{I('Channels: ')}</label>
												</div>
												<div className="channels">
												{disabled === false ?
													<SerialMultiSelect
														id={"schedule-channels"}
														title={I("Select channels")}
														className={"popup-multi-select"}
														overrideTitle={true}
														data={p.channels}
														idFields={{id: "id", name: "value"}}
														selected={newr.channels}
														onSelect={this.handleSelectChannel}
														onToggle={this.handleToggleChannel}
														show={this.state.toggleChannel}
														selectAll={true}
														selectNone={true}
													/> :
													<ReadOnly items={formatValueToNameObj(p.channels)}
														id={newr.channels} multiple={true} />
												}
												</div>
											</div>
										</div>
									}
									{formOpt.hasKeyFigureThatFilterByArea &&
										<div className="label-row">
											<div className="label-block">
												<div className="label-areas">
													<label htmlFor="areas">{I('Areas: ')}</label>
												</div>
												<div className="areas">
												{disabled === false ?
													<SerialMultiSelect
														id={"schedule-areas"}
														title={I("Select areas")}
														className={"popup-multi-select"}
														overrideTitle={true}
														groupSelect={true}
														nested={{key: 'Areas'}}
														data={p.areas}
														idFields={{id: "Id", name: "Name"}}
														selected={newr.areas}
														onSelect={this.handleSelectArea}
														onToggle={this.handleToggleArea}
														show={this.state.toggleArea}
														selectAll={true}
														selectNone={true}
													/> :
													<ReadOnly items={p.areas}
														id={newr.areas} multiple={true} nested={{key: 'Areas'}} idFields={{id: "Id", name: "Name"}} />
												}
												</div>
											</div>
										</div>
									}
									{formOpt.hasKeyFigureThatFilterByUser &&
										<div className="label-row">
											<div className="label-block">
												<div className="label-agents">
													<label htmlFor="agents">{I('Agents: ')}</label>
												</div>
												<div className="agentType">
												{disabled === false ?
													<OrderSingleSelect data={AGENT_TYPES}
														order={AGENT_TYPES.order}
														className={"popup-multi-select"}
														name="agents"
														selected={newr.agentType}
														onSelect={this.handleSelectAgentType}
														onToggle={this.handleToggleAgentType}
														show={this.state.toggleAgentType}
													/> :
													<ReadOnly items={FormatToObj(AGENT_TYPES)}
														id={newr.agentType} />
												}
												</div>
												{newr.agentType === AT_CHOOSE_AGENTS &&
													<div className="agents">
														{disabled === false ?
															<SerialMultiSelect
																id={"schedule-agents"}
																title={I("Select agents")}
																className={"popup-multi-select"}
																overrideTitle={true}
																data={p.agents}
																idFields={{id: "Id", name: "Name"}}
																selected={newr.users}
																onSelect={this.handleSelectAgents}
																onToggle={this.handleToggleAgent}
																show={this.state.toggleAgent}
																selectAll={true}
																selectNone={true}
															/> :
															<ReadOnly items={p.agents}
																idFields={{id: "Id", name: "Name"}}
																id={newr.users} multiple={true} />
														}
													</div>
												}
												{newr.agentType === AT_CHOOSE_GROUPS &&
													<div className="agent-groups">
														{disabled === false ?
															<SerialMultiSelect
																id={"schedule-group"}
																title={I("Select group")}
																className={"popup-multi-select"}
																overrideTitle={true}
																data={p.groups}
																idFields={{id: "Id", name: "Name"}}
																selected={newr.groups}
																onSelect={this.handleSelectGroups}
																onToggle={this.handleToggleGroup}
																show={this.state.toggleGroup}
																selectAll={true}
																selectNone={true}
															/> :
															<ReadOnly items={p.groups}
																idFields={{id: "Id", name: "Name"}}
																id={newr.groups} multiple={true} />
														}
													</div>
												}
											</div>
										</div>
									}
									{formOpt.hasCustomAbsoluteTimeGroup &&
										<div className="label-row">
											<div className="label-block">
												<div className="label-timespan">
													<label htmlFor="timespan">{I('Time Span: ')}</label>
												</div>
												<div className="timespan">
													{disabled === false ?
														<SelectionBox options={FormatOptions(p.timeStampFormat)}
															name="timespan" selected={newr.timeStampFormat}
															onSelect={this.handleTimeFormat} /> :
														<ReadOnly items={FormatOptions(p.timeStampFormat)}
															id={newr.timeStampFormat} />
													}
												</div>
											</div>
										</div>
									}
									<div className="label-row">
										<div className="label-block">
											<div className="label-search-interval">
												<label htmlFor="interval">{I('Interval: ')}</label>
											</div>
											<div className="search-interval">
												{disabled === false ?
													<SelectionBox options={RelatedDateType()}
														name="search-interval"
														selected={newr.relativeDateType}
														onSelect={this.handleSearchInterval} /> :
													<ReadOnly items={RelatedDateType()}
														id={newr.relativeDateType} />
												}
											</div>
										</div>
									</div>
									<div className="label-row">
										<div className="label-block">
											<div className="label-format">
												<label htmlFor="format">{I('Format: ')}</label>
											</div>
											<div className="format">
												{disabled === false ?
													<SelectionBox options={ExportTypes()}
														name="time-format" selected={newr.outputType}
														onSelect={this.handleFileFormat} /> :
													<ReadOnly items={ExportTypes()}
														id={newr.outputType} />
												}
											</div>
										</div>
									</div>
									<div className="label-row">
										<div className="label-block">
											<div className="label-showCompleteResult">
												<label htmlFor="showCompleteResult">{I('Show all: ')}</label>
											</div>
											<div className="showCompleteResult">
												{disabled === false ?
													<SquareCheckbox onClick={this.handleCompleteResult} checked={newr.showCompleteResult} />:
													<label className="readonly-input">{ newr.showCompleteResult ? I("Yes") : I("No") }</label>
												}
											</div>
										</div>
									</div>
									<div className="label-row">
										<div className="label-block">
											<div className="label-showCompleteResult">
												<label htmlFor="showCompleteResult">
													<span className="mandatory">*</span>
													{I('Emails: ')}
												</label>
											</div>
											<div className="email">
												{disabled === false ?
													<TagPicker
														name="schedule-emails"
														selected={emailAddrs}
														type={RC_EMAIL}
														basic={true}
														onChange={this.handleEmailChange}
														onFailValidation={this.handleFailValidation}
													/>:
													<label className="readonly-input">{ newr.address } </label>
												}
												<small className="report-field-tips">{I("Enter email addresses to send this report to and press enter.")}</small>
												{this.state.emailWarning ? <p className="mandatory">{this.state.emailWarning}</p> : ""}
											</div>
										</div>
									</div>
								</div>
								<div className="label-row">
									{button}
								</div>
							</form>
					</div>
						<div data-qa-id="schedule-report-close" className="popup-close" onClick={this.handleClose}>
							<i className="icon-times"></i>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
};

const getAgentTypes = (agentTypes) => {
	let r = [];
	$.each(agentTypes, (i, v) => {
		if(i !== "order"){
			r.push({id: parseInt(i, 10), name: v});
		}
	});
	let ordered = [];
	$.each(agentTypes.order, (i, v) => {
		$.each(r, (j, w) => {
			if(v === w.id){
				ordered.push(w);
			}
		});
	});
	return ordered;
}

const ScheduledReportForm = ({
	activeId,
	input,
	view,
	isNew,
	timeZones,
	timeStampFormat,
	reportTypes,
	otherOptions,
	channels,
	areas,
	agents,
	groups,
	baseButtons,
	onSetScheduleReport,
	onFailValidation,
	onSave
}) => {
	const [toggleChannel, setToggleChannel] = useState(false);
	const [toggleArea, setToggleArea] = useState(false);
	const [toggleAgent, setToggleAgent] = useState(false);
	const [toggleGroup, setToggleGroup] = useState(false);
	const [emailWarning, setEmailWarning] = useState("");

	const handleChangeName = (e) => {
		onSetScheduleReport("reportName", e.target.value);
	}
	const onHandleTextInputBlur = () => {
	}

	const ReportTypes = () => {
		let r = [];
		$.each(reportTypes, (i, v) => {
			r.push({id: v.Id, name: v.Name})
		});
		return r;
	};

	const handleSelectReportType = (v) => {
		onSetScheduleReport("reportId", v);
	}

	const handleInput = (value, name) => {
		onSetScheduleReport(name, value);
	}

	const handleToggleChannel = () => {
		setToggleChannel(!toggleChannel);
	}

	const handleSelectChannel = (v) => {
		onSetScheduleReport("channels", v);
	}

	const handleToggleArea = () => {
		setToggleArea(!toggleArea);
	}

	const handleSelectArea = (v) => {
		onSetScheduleReport("areas", v);
	}

	const handleSelectAgentType = (v) => {
		onSetScheduleReport("agentType", v);
	}

	const handleToggleAgent = () => {
		setToggleAgent(!toggleAgent);
	}

	const handleSelectAgents = (v) => {
		onSetScheduleReport("users", v);
	}

	const handleToggleGroup = () => {
		setToggleGroup(!toggleGroup);
	}

	const handleSelectGroup = (v) => {
		onSetScheduleReport("groups", v);
	}

	const handleCompleteResult = (v) => {
		onSetScheduleReport("showCompleteResult", v);
	}

	const handleFailValidation = (type, inpt) => {
		if (type === RC_EMAIL) {
			setEmailWarning(I("Invalid email address"))
			if (onFailValidation) {
				onFailValidation(type, inpt);
			}
		}
	}

	const onAddEmail = (id, email) => {
		if (email) {
			if(!adminHelper.validateEmail(email)){
				onFailValidation(RC_EMAIL, email);
				return;
			}

			let emails = input.address ? input.address.split(",") : [];
			emails.push(email);
			onSetScheduleReport("address", emails.join(","));
		}
	}

	const onRemoveEmail = (id, index) => {
		let emails = input.address ? input.address.split(",") : [];
		emails.splice(index, 1);
		onSetScheduleReport("address", emails.join(","));
	}

	let disabled = false;
	let emailAddrs =  [];
	if(input.address) {
		emailAddrs = input.address.split(",");
	}

	return(
		<Fragment>
			<form id="callExportEdit" className="admin-one-form edit-admin-form report-form" hidden={false}>
				<FormInputWithLabelRow
					label={I('Name')}
					mandatory={true}
					inlineLabel={false}
				>
					<TextInputRow
						name="name"
						className="admin-text-input with-helper"
						value={input.reportName}
						onChange={handleChangeName}
						onBlur={onHandleTextInputBlur}
						warning={""}
						inlineLabel={false}
						mandatory={true}
						helperTxt={I('This name helps you identify this export if you want to remove it or make changes to it later.')}
					/>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow
					label={I('Report type')}
					mandatory={true}
					inlineLabel={false}
				>
					<div className="row" style={{width: '100%'}}>
						<div className="col-11 col-md-11">
							<SelectInputRow
								id={"report-types"}
								name={"report-types"}
								className={"admin-select with-helper"}
								option={ReportTypes()}
								mandatory={true}
								value={input.reportId}
								textNoItemSelected={I("Please select")}
								onSelect={handleSelectReportType}
								readonly={false}
								helperTxt={I('This is the report that should be automatically exported. New reports can be created in the Create reports.')}
							/>
						</div>
					</div>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow
					label={I("Run")}
					warning={""}
					className={""}
					inlineLabel={false}
				>
					<div className="admin-input-wrapper-one-three">
						<SelectInputRow
							id="time"
							name="time"
							className=""
							option={RunInterval()}
							value={input.time}
							onSelect={handleInput}
						/>
						<SelectInputRow
							id="hour"
							name="hour"
							className=""
							label={""}
							option={getHours()}
							value={input.hour}
							onSelect={handleInput}
						/>
						<SelectInputRow
							id="minute"
							name="minute"
							className=""
							label={""}
							option={getMinutes()}
							value={input.minute}
							onSelect={handleInput}
						/>
						{ features["show-time-zone"] &&
							<SelectInputRow
								id="timeZoneId"
								name="timeZoneId"
								className=""
								label={""}
								option={FormatOptions(timeZones)}
								value={input.timeZoneId}
								onSelect={handleInput}
							/>
						}
					</div>
				</FormInputWithLabelRow>
				{ otherOptions.hasKeyFigureThatFilterByChannel &&
					<div className='admin-input-wrapper-half with-padding'>
						<FormInputWithLabelRow
							label={I("Channels")}
							warning={""}
							className={""}
							inlineLabel={false}
						>
							<SerialMultiSelect
								id={"schedule-channels"}
								title={I("Select channels")}
								className={"popup-multi-select"}
								overrideTitle={true}
								data={channels}
								idFields={{id: "id", name: "value"}}
								selected={input.channels}
								onSelect={handleSelectChannel}
								onToggle={handleToggleChannel}
								show={toggleChannel}
								selectAll={true}
								selectNone={true}
							/>
						</FormInputWithLabelRow>
					</div>
				}
				{ otherOptions.hasKeyFigureThatFilterByArea &&
					<div className='admin-input-wrapper-half with-padding'>
						<FormInputWithLabelRow
							label={I("Areas")}
							warning={""}
							className={""}
							inlineLabel={false}
						>
							<SerialMultiSelect
								id={"schedule-areas"}
								title={I("Select areas")}
								className={"popup-multi-select"}
								overrideTitle={true}
								data={areas}
								groupSelect={true}
								nested={{key: 'Areas'}}
								idFields={{id: "Id", name: "Name"}}
								selected={input.areas}
								onSelect={handleSelectArea}
								onToggle={handleToggleArea}
								show={toggleArea}
								selectAll={true}
								selectNone={true}
							/>
						</FormInputWithLabelRow>
					</div>
				}
				{ otherOptions.hasKeyFigureThatFilterByUser &&
					<div className='admin-input-wrapper-half with-padding'>
						<FormInputWithLabelRow
							label={I("Agents")}
							warning={""}
							className={""}
							inlineLabel={false}
						>
							<SelectInputRow
								id="agents"
								name="agents"
								className=""
								label={""}
								option={getAgentTypes(AGENT_TYPES)}
								value={input.agentType}
								onSelect={handleSelectAgentType}
							/>
						</FormInputWithLabelRow>
						{ input.agentType === AT_CHOOSE_AGENTS &&
								<FormInputWithLabelRow
									label={I("Agents")}
									warning={""}
									className={""}
									inlineLabel={false}
								>
									<SerialMultiSelect
										id={"schedule-agents"}
										title={I("Select agents")}
										className={"popup-multi-select"}
										overrideTitle={true}
										data={agents}
										idFields={{id: "Id", name: "Name"}}
										selected={input.users}
										onSelect={handleSelectAgents}
										onToggle={handleToggleAgent}
										show={toggleAgent}
										selectAll={true}
										selectNone={true}
									/>
								</FormInputWithLabelRow>
						}
						{ input.agentType === AT_CHOOSE_GROUPS &&
								<FormInputWithLabelRow
									label={I("Agent groups")}
									warning={""}
									className={""}
									inlineLabel={false}
								>
									<SerialMultiSelect
										id={"schedule-group"}
										title={I("Select group")}
										className={"popup-multi-select"}
										overrideTitle={true}
										data={groups}
										idFields={{id: "Id", name: "Name"}}
										selected={input.groups}
										onSelect={handleSelectGroup}
										onToggle={handleToggleGroup}
										show={toggleGroup}
										selectAll={true}
										selectNone={true}
									/>
								</FormInputWithLabelRow>
						}
					</div>
				}
				{	otherOptions.hasCustomAbsoluteTimeGroup &&
					<div className='admin-input-wrapper-half with-padding'>
						<FormInputWithLabelRow
							label={I("Time Span")}
							warning={""}
							className={""}
							inlineLabel={false}
						>
						<SelectInputRow
							id="timeStampFormat"
							name="timeStampFormat"
							className=""
							label={""}
							option={FormatOptions(timeStampFormat)}
							value={input.timeStampFormat}
							onSelect={handleInput}
						/>
						</FormInputWithLabelRow>
					</div>
				}
				<div className='admin-input-wrapper-half with-padding'>
					<FormInputWithLabelRow
						label={I("Interval")}
						warning={""}
						className={""}
						inlineLabel={false}
					>
					<SelectInputRow
						id="relativeDateType"
						name="relativeDateType"
						className=""
						option={RelatedDateType()}
						value={input.relativeDateType}
						onSelect={handleInput}
					/>
					</FormInputWithLabelRow>
				</div>
				<div className='admin-input-wrapper-half'>
					<FormInputWithLabelRow
						label={I("Format")}
						warning={""}
						className={""}
						inlineLabel={false}
					>
					{ disabled === false ?
						<SelectInputRow
							id="outputType"
							name="outputType"
							className=""
							option={ExportTypes()}
							value={input.outputType}
							onSelect={handleInput}
						/> : <ReadOnly items={ExportTypes()}
							id={input.outputType} />
					}
					</FormInputWithLabelRow>
				</div>
				<FormInputWithLabelRow
					label={I("Show all")}
					warning={""}
					className={""}
					inlineLabel={true}
				>
				<SquareCheckbox onClick={handleCompleteResult} checked={input.showCompleteResult} />
				</FormInputWithLabelRow>
				<div className='admin-input-wrapper' style={{overflow: 'hidden'}}>
					<FormInputWithLabelRow
						label={I("Emails")}
						warning={emailWarning}
						className={"full-width-field"}
						inlineLabel={false}
						mandatory={true}
						helperTxt={I("Enter email addresses to send this report to and press enter.")}
					>
					<AddTextItemInputContainer id={"schedule-emails"} name={"schedule-emails"} onAdd={onAddEmail} onRemove={onRemoveEmail} list={emailAddrs} />
					</FormInputWithLabelRow>
				</div>
				<div style={{ marginTop: '50px' }}>
					{baseButtons}
				</div>
			</form>
		</Fragment>
	)
}

const EditForm = withEditWrap(ScheduledReportForm);
export const EditFormWithPopup = withPopupPage(EditForm);

function withPopupPage(Component) {
	return ({ show, input, onSave, onClose, ...props }) => {
		let disableSave = false;
		if(input && input.address == "") {
			disableSave = true;
		}
		const activeId = 0;
		const baseButtons = <EditFormButtons
			hideDelete={true}
			hidePreview={true}
			onCancel={useCallbackMultiValues(onClose, activeId)}
			disableSave={disableSave}
			onSave={onSave}
		/>

		return (
			<PopupPage
				data-qa-id="report-preview-backdrop-popup"
				extraClass={"report-preview-backdrop report preview popup admin-main-wrapper" + (show ? " open" : "")}
				innerClass="report-preview report-inner create-inner app-content statistic schedule"
				show={show}
				onClose={onClose}
				onSave={onSave}
			>
				<div className="popup-title">
					<h2><i className={"icon-keypad"}></i>{I("Schedule report")}</h2>
				</div>
				<div className="popup-content" style={{background: '#FFF'}}>
					<div className='app-inner-content' style={{maxHeight: '80vh', border: 'none'}}>
						<div className='adminWrapper'>
							<div className='adminEdit section' style={{width: '100%'}}>
								<Component
									input={input}
									baseButtons={baseButtons}
									{...props}
								/>
							</div>
						</div>
					</div>
				</div>
			</PopupPage>
		);
	};
}

const ScheduledReportBase = ({
	onLoad,
	activeScheduled: activeId,
	list,
	input = {},
	show,
	timeZones,
	timeStampFormat,
	reportTypes,
	otherOptions,
	channels,
	areas,
	agents,
	groups,
	onClickEdit,
	onClickDelete,
	onCancel,
	onSave,
	showInline = false,
	onCloseForm,
	onDismissAlert,
	onSetScheduleReport,
	onFailValidation,
	saveStatus,
	...props
}) => {
	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [toBeDeleted , setToBeDeleted] = useState(0);
	const [deleteMsg, setDeleteMsg] = useState("");
	const [disableSave, setDisableSave] = useState(false);
	const [buttonSaveTxt, setbuttonSaveTxt] = useState(BTN_TXT_SAVE);
	const isNew = false;
	const view = SCHEDULED_REPORT;

	useEffect(() => {
		onLoad();
	}, []);

	useEffect(() => {
		if(input.reportName == "" || input.address == ""){
			setDisableSave(true);
		} else {
			setDisableSave(false);
		}
	}, [activeId, input]);

	function handleOpenEdit(id) {
		onClickEdit(id, list);
	}

	const handleDelete = (id) => {
		setShowDeleteAlert(true);
		setToBeDeleted(id);
		if(input.name) {
			setDeleteMsg(input.name);
		}
	}

	const handleConfirmDelete = () => {
		setShowDeleteAlert(false);
		onClickDelete(toBeDeleted);
	}
	const handleCancelDelete = () => {
		setShowDeleteAlert(false);
	}

	useEffect(() => {
		if(saveStatus && saveStatus.status === 1) {
			setbuttonSaveTxt(BTN_TXT_SAVING);
		} else {
			setbuttonSaveTxt(BTN_TXT_SAVE);
		}
	}, [saveStatus]);

	const baseButtons = <EditFormButtons
		hideDelete={isNew}
		hidePreview={true}
		onCancel={useCallbackMultiValues(onCancel, activeId)}
		onDelete={useCallbackMultiValues(handleDelete, activeId)}
		disableSave={disableSave || saveStatus.status === 1}
		saveTxt={buttonSaveTxt}
		onSave={onSave}
	/>

	const rowEvents = {
		onClick: (e, row) => {
			onClickEdit(row.Id);
		}
	};

	const reportCols = [
		{
			dataField: 'Id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'Name',
			text: I('Name'),
			sort: true,
		},
		{
			dataField: 'ReportName',
			text: I('Report'),
			sort: true,
		},
		{
			dataField: 'RunCondition',
			text: I('Run'),
			sort: true,
		},
		{
			dataField: 'CreatedAt',
			text: I('Created'),
			sort: true,
			formatter: (cell, row) => {
				return row.CreatedByName + " - " + cell;
			},
		},
		{
			isDummyField: true,
			dataField: 'Id',
			text: I('Action'),
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderReportAction(cell, row, actionDatas);
			},
		}
	];

	const actionDatas = {
		onEdit: handleOpenEdit
		, onDelete: handleDelete
	}

	const rowStyle = (row) => {
		if (row.Id === activeId) {
			return { backgroundColor: '#f5f5f5' };
		}
	};

	const listSection = <Fragment>
		<ListHeaderWrapper>
			<TableHeader
				title={I("Scheduled reports")}
				type='button'
				iconClass='icon-add'
				hide={false}
				hideAddIcon={true}
				showInline={showInline}
			/>
		</ListHeaderWrapper>
		<ListContentWrapper className={"v5-table-style"}>
			<BootstrapTable
				keyField="Id"
				data={list}
				noDataIndication={I("No data to display")}
				columns={reportCols}
				bordered={false}
				condensed={false}
				defaultSorted={[{
					dataField: 'name',
					order: 'asc'
				}]}
				pagination={paginationFactory(TABLE_STANDARD_OPTIONS)}
				rowEvents={rowEvents}
				rowStyle={rowStyle}
				hover
			/>
		</ListContentWrapper>
	</Fragment>;
	const editSection = <Fragment>
	<EditorHeader
		hidden={!showInline}
		isNew={isNew}
		onClickClose={onCloseForm}
		title={I("Edit report")}
	/>
	<EditForm
		activeId={activeId}
		input={input}
		view={view}
		isNew={isNew}
		disableSave={disableSave}
		timeZones={timeZones}
		timeStampFormat={timeStampFormat}
		reportTypes={reportTypes}
		otherOptions={otherOptions}
		channels={channels}
		areas={areas}
		agents={agents}
		groups={groups}
		hidden={!showInline}
		baseButtons={baseButtons}
		onSetScheduleReport={onSetScheduleReport}
		onFailValidation={onFailValidation}
		onSave={onSave}
	/>
	<DeletePopup
		title={I("Are you sure you want to delete ?")}
		msg={deleteMsg}
		icon={'icon-caution'}
		show={showDeleteAlert}
		onDelete={handleConfirmDelete}
		onClose={handleCancelDelete}
	/>
	</Fragment>;
	return <WorkflowInnerContainerCtnr className="app-inner-content" hidden={false} data-custom-scrollbar>
			<AdminListAndEditLayout
				className={"report-page no-max-height"}
				listSection={listSection}
				editSection={editSection}
			/>
		</WorkflowInnerContainerCtnr>
}


export const ScheduledReports = ({...props}) => {
	return <ScheduledReportBase {...props} />
}

const ScheduleReportModalBase = ({...props}) => {
	return <EditFormWithPopup {...props} />
}

const ScheduleReport = ScheduleReportModalBase;

export default ScheduleReport;
