import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
	DEFAULT_ERRAND_LENGTH_LIMIT
} from "../../common/v5/constants.js";
import { getTextLengthOnly, truncateHtml } from '../../common/v5/helpers.js';

const baseClassName = "truncate-text";

const Text = ({ className, text, "data-qa-id": dataQAId, onClickCapture, noFormatting = false }) => {

    const handleMarkUpText = (text, identifier, htmlTag) => {
        return text.split(identifier).map((segment, index) =>
            index % 2 === 1 && !segment.startsWith("http")
                ? `<${htmlTag}>${segment}</${htmlTag}>`
                : segment
        ).join('');
    };

    if (text) {
        text = handleMarkUpText(text, "\\*\\*", "strong");
    }

    const textStyle = noFormatting ? { padding: '0px' } : { padding: '10px 0px' };

    return (
        <div
            data-qa-id={dataQAId}
            className={classNames(className, "text")}
            dangerouslySetInnerHTML={{ __html: text }}
            onClickCapture={onClickCapture}
            style={textStyle}
        />
    );
};

Text.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    "data-qa-id": PropTypes.string,
    onClickCapture: PropTypes.func,
    noFormatting: PropTypes.bool
};

const MoreOrLess = ({ truncated, onClickMore, onClickLess }) => {
    const className = truncated ? "readmore" : "readless";
    const linkText = truncated ? "« more" : "» less";
    const handleClick = truncated ? onClickMore : onClickLess;

    return (
        <div className={className}>
            <a onClick={handleClick}>{linkText}</a>
        </div>
    );
};

MoreOrLess.propTypes = {
    truncated: PropTypes.bool.isRequired,
    onClickMore: PropTypes.func.isRequired,
    onClickLess: PropTypes.func.isRequired
};

class TruncateText extends React.PureComponent {
    state = {
        fullMode: false
    };

    componentDidMount() {
        if (this.props.truncateErrandMessageByDefault !== undefined) {
            this.setState({ fullMode: !this.props.truncateErrandMessageByDefault });
        }
    }

    handleReadMore = () => {
        this.setState({ fullMode: true });
        this.props.onSetErrandMessageTruncatePref?.(false);
    };

    handleReadLess = () => {
        this.setState({ fullMode: false });
        this.props.onSetErrandMessageTruncatePref?.(true);
    };

    render() {
        const {
            forwardCheckboxesShown,
            limit=DEFAULT_ERRAND_LENGTH_LIMIT,
            onClickCapture,
            showMore,
            text,
            truncateText,
            noFormatting = false,
            "data-qa-id": dataQAId,
        } = this.props;

        const className = classNames(
            baseClassName,
            { "less-width": forwardCheckboxesShown }
        );

        if (!truncateText || getTextLengthOnly(text) <= limit) {
            return (
                <Text
                    className={className}
                    data-qa-id={dataQAId}
                    onClickCapture={onClickCapture}
                    noFormatting={noFormatting}
                    text={text}
                />
            );
        }

        const msg = this.state.fullMode ? text : `${truncateHtml(text, limit)} ...`;

        return (
            <div className={className}>
                {
                    noFormatting
                        ? msg
                        : <Text
                            data-qa-id={dataQAId}
                            onClickCapture={onClickCapture}
                            text={msg}
                        />
                }
                {showMore && (
                    <MoreOrLess
                        truncated={!this.state.fullMode}
                        onClickMore={this.handleReadMore}
                        onClickLess={this.handleReadLess}
                    />
                )}
            </div>
        );
    }
}

TruncateText.propTypes = {
    forwardCheckboxesShown: PropTypes.bool,
    limit: PropTypes.number.isRequired,
    onClickCapture: PropTypes.func,
    showMore: PropTypes.bool,
    text: PropTypes.string.isRequired,
    truncateText: PropTypes.bool,
    noFormatting: PropTypes.bool,
    "data-qa-id": PropTypes.string,
    truncateErrandMessageByDefault: PropTypes.bool,
    onSetErrandMessageTruncatePref: PropTypes.func
};

TruncateText.defaultProps = {
    forwardCheckboxesShown: false,
    onClickCapture: () => {},
    showMore: true,
    truncateText: true,
    noFormatting: false,
    "data-qa-id": "",
    truncateErrandMessageByDefault: false,
    onSetErrandMessageTruncatePref: null
};

export default TruncateText;
