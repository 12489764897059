import React from "react";
import { emptyArray, emptyObject, MM_WORKFLOW } from "../../common/v5/constants";
import { getNotificationIcon } from "../../common/v5/helpers";
import { I } from "../../common/v5/config";
import { doNothing } from "../../reactcomponents/common";
import { PopupWithButtons } from "../../reactcomponents/PopupPage";
import { ErrandBox, ErrandHistoriesMessages } from '../../views/v5/errand';

const OtherContactMessages = props => (
	<ErrandHistoriesMessages {...props}
		associatedErrands={emptyArray}
		forwardSelection={emptyObject}
		includeAttachment={true}
		onActivateView={doNothing}
		onSelectForward={doNothing}
		onToggleAttachmentPanel={doNothing}
		socialMediaUI={doNothing}
	/>
);

export default function withOtherContactErrand(HeaderComponent) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleAcquire = this.handleAcquire.bind(this);
			this.buttons = [
				{
					id: "acquire"
					, text: I("Acquire")
					, onClick: this.handleAcquire
				}
				// , {
				// 	id: "sample"
				// 	, text: I("Sample")
				// 	, onClick: doNothing
				// }
			];
		}
		handleAcquire(e) {
			this.props.onAcquire(this.props.showId, this.props.showThreadId);
		}
	    render() {
			const {
					canAcquire
					, canTranslate
					, constants
					, data
					, eid
					, historyReady
					, interfaceLang
					, mobile
					, onAcquire
					, onClose
					, onTranslation
					, show
					, mainMenu
					, ...props
				} = this.props
				;

			//canAcquire && mainMenu ? this.buttons : emptyArray
			let acquireButton ;
			if (canAcquire && mainMenu == MM_WORKFLOW) {
				acquireButton = this.buttons
			} else
			{
				acquireButton = emptyArray
			}

			return (
				<PopupWithButtons
					data-qa-id={props["data-qa-id"]}
					buttons={acquireButton}
					show={show}
					onClose={onClose}
					innerClass="notification-list"
				>
					<ErrandBox>
						<HeaderComponent
							mobile={mobile}
							eid={eid}
							isOtherContact={true}
						/>
						<OtherContactMessages {...props}
							constants={constants}
							data={data}
							eid={eid}
							historyReady={historyReady}
							interfaceLang={interfaceLang}
							canTranslate={canTranslate}
							onTranslation={onTranslation}
						/>
					</ErrandBox>
				</PopupWithButtons>
			);
	    }
	};
}
