import React from 'react';
import moment from 'moment';

class SystemMessage extends React.Component {
	constructor(props) {
		super(props);
		this.displayName = "SystemMessage";
		this.state = {
			expandAction: false,
		};
	}

	agentChatName(agent) {
		if (!agent) {
			"N/A";
		}
		if (agent.chatName) {
			return agent.chatName;
		}
		if (agent.userName) {
			return agent.userName;
		}
		return "N/A";
	}

	renderSetAgentsEvent = (m) => {
		let actions = []
		, title
		, messages = []
		, actionsHtml
		, style
		, byInvitation = m.byInvitation
		, agentName = (id) => {
			let agent = this.props.getAgent(id)
			, name
			;
			if (!agent) {
				if (m.names) {
					name = m.names[id];
					if (name) {
						// This is what should happen 100% of the time
						return name;
					} else {
						// this should not happen
						console.log("error: m.names[id] not available? id:", id);
					}
				} else {
					// this should not happen
					console.log("error: m.names not available?");
				}
				return "N/A ("+id+")";
			}
			return this.agentChatName(agent);
		}
		, agentNamesCsv = function(ids) {
			let names = [];
			ids.map(function(id) {
				names.push(agentName(id));
			});
			names.sort(function(a, b) {
				if (a < b) { return -1; }
				if (a > b) { return 1; }
				return 0;
			});
			return names.join(", ");
		}
		, removedAgents = m.removed.concat(m.uninvited)
		, whoName = agentName(m.who)
		, pushAddedActions = function() {
			actions.push((byInvitation?I("Invited: {AGENT_NAMES}"):I("Added: {AGENT_NAMES}"))
				.replace("{AGENT_NAMES}", agentNamesCsv(m.added)));
		}
		, pushRemovedActions = function() {
			actions.push(I("Removed: {AGENT_NAMES}").replace("{AGENT_NAMES}", agentNamesCsv(removedAgents)));
		}
		;

		if        (m.added.length == 0 && removedAgents.length == 0) {
			// We should not reach here
		} else if (m.added.length == 0 && removedAgents.length >  0) {
			if (removedAgents.length == 1) {
				title = I("{WHO} removed {WHOM}")
					.replace("{WHO}", whoName)
					.replace("{WHOM}", agentName(removedAgents[0]));
			} else {
				title = I("{WHO} removed {NUMBER} agents")
					.replace("{WHO}", whoName)
					.replace("{NUMBER}", removedAgents.length);
				pushRemovedActions();
			}
		} else if (m.added.length >  0 && removedAgents.length == 0) {
			if (m.added.length == 1) {
				title = (byInvitation?I("{WHO} invited {WHOM}"):I("{WHO} added {WHOM}"))
					.replace("{WHO}", whoName)
					.replace("{WHOM}", agentName(m.added[0]));
			} else {
				title = (byInvitation?I("{WHO} invited {NUM_ADDED} agents"):I("{WHO} added {NUM_ADDED} agents"))
					.replace("{WHO}", whoName)
					.replace("{NUM_ADDED}", m.added.length);
				pushAddedActions();
			}
		} else if (m.added.length >  0 && removedAgents.length >  0) {
			if        (m.added.length == 1 && removedAgents.length == 1) {
				title = I("{WHO} removed {REMOVED}, added {ADDED}").replace("{WHO}", whoName)
					.replace("{REMOVED}", agentName(removedAgents[0]))
					.replace("{ADDED}", agentName(m.added[0]));
			} else if (m.added.length == 1 && removedAgents.length >  1) {
				title = (byInvitation?I("{WHO} invited {ADDED}, removed {NUM_REMOVED} agents"):I("{WHO} added {ADDED}, removed {NUM_REMOVED} agents"))
					.replace("{WHO}", whoName)
					.replace("{ADDED}", agentName(m.added[0]))
					.replace("{NUM_REMOVED}", removedAgents.length);
				pushRemovedActions();
			} else if (m.added.length >  1 && removedAgents.length == 1) {
				text = (byInvitation?I("{WHO} removed {REMOVED}, invited {NUM_ADDED} agents"):I("{WHO} removed {REMOVED}, added {NUM_ADDED} agents"))
					.replace("{WHO}", whoName)
					.replace("{REMOVED}", agentName(removedAgents[0]))
					.replace("{NUM_ADDED}", m.added.length);
				title = text;
				pushAddedActions();
			} else if (m.added.length >  1 && removedAgents.length >  1) {
				text = (byInvitation?I("{WHO} removed {NUM_ADDED} agents:, added {NUM_REMOVED} agents"):I("{WHO} removed {NUM_ADDED} agents:, added {NUM_REMOVED} agents"))
					.replace("{WHO}", whoName)
					.replace("{NUM_REMOVED}", removedAgents.length)
					.replace("{NUM_ADDED}", m.added.length);
				title = text;
				pushRemovedActions();
				pushAddedActions();
			}
		} else {
			throw new Error("FIXME this should not happen")
		}

		if (actions.length > 0) {
			let key = -1;
			actionsHtml = <div className={this.state.expandAction?"":"hide"}>
				<ul>
					{actions.map(function(action) {
						key++;
						return <li key={"action_"+key}> {action} </li>
					})}
				</ul>
			</div>
		}
		return <div>
					<span>{title} {actions.length > 0?<a href="#" onClick={this.expandAction}>...</a>:null}</span>
					{actionsHtml}
		</div>
	}
	renderAgentLeftEvent = (m) => {
		return <div>
			{I("{AGENT_NAME} has left the chat.").replace("{AGENT_NAME}", m.name)}
		</div>
	}
	renderClientEndsChat = (m) => {
		let text;
		if (m.name == "UNKNOWN") {
			text = I("Client ended the chat.");
		} else {
			text = I("{NAME} ended the chat.").replace("{NAME}", m.name);
		}
		return <div>
			{text}
		</div>
	}
	renderReturnToQueue = (m) => {
		const text = I("{FROM_AGENT} handover the chat to agent")
			.replace("{FROM_AGENT}", m.from)
		return <div>
			{text}
		</div>
	}
	renderForwardToAgent = (m) => {
		const text = I("{FROM_AGENT} forwarded the chat to {TO_AGENT}")
			.replace("{FROM_AGENT}", m.from)
			.replace("{TO_AGENT}", m.to)
			;
		return <div>
			{text}
		</div>
	}
	renderForwardToArea = (m) => {
		const text = I("{FROM_AGENT} forwarded the chat to area {AREA_NAME}")
			.replace("{FROM_AGENT}", m.from)
			.replace("{AREA_NAME}", m.areaName)
			;
		return <div>
			{text}
		</div>
	}
	expandAction = () => {
		this.setState({expandAction: !this.state.expandAction});
	}
	renderSendChatHistory = (m) => {
		const text = I("A copy of the chat will be sent to {CLIENT_EMAIL}")
			.replace("{CLIENT_EMAIL}", m.to)
			;
		return <div>
			{text}
		</div>;
	}
	renderAcceptChat = (m) => {
		const text = I("{AGENT_NAME} joins the chat.")
			.replace("{AGENT_NAME}", m.who)
			;
		return <div>
			{text}
		</div>;
	}
	renderRejectChat = (m) => {
		const text = I("{AGENT_NAME} declined the chat.")
			.replace("{AGENT_NAME}", m.who)
			;
		return <div>
			{text}
		</div>;
	}
	renderChatExpired = (m) => {
		const text = I("This chat has expired.");
		return <div>
			{text}
		</div>;
	}
	renderOwnerEndsChat = (m) => {
		const text = I("{AGENT_NAME} ended the chat.").
			replace("{AGENT_NAME}", m.who);
		return <div>
			{text}
		</div>;
	}
	renderAgentRejectVidCall = (m) => {
		const text = I("Agent {AGENT_NAME} is unable to accept video call at the moment.").
			replace("{AGENT_NAME}", m.who);
		return <div>
			{text}
		</div>;
	}
	renderAgentRejectScreenShare = (m) => {
		const text = I("Agent {AGENT_NAME} is unable to accept screen sharing at the moment.").
			replace("{AGENT_NAME}", m.who);
		return <div>
			{text}
		</div>;
	}
	renderAgentAcceptVidCall = (m) => {
		const text = I("Agent {AGENT_NAME} is accepting video call from {CLIENT_NAME}").
		replace("{AGENT_NAME}", m.who).
		replace("{CLIENT_NAME}", m.from);
		return <div>
			{text}
		</div>;
	}
	renderClientAcceptCall = (m) => {
		const text = I("{CLIENT_NAME} is accepting video call from agent {AGENT_NAME}").
			replace("{CLIENT_NAME}", m.name).
			replace("{AGENT_NAME}", m.from);
		return <div>
			{text}
		</div>;
	}
	renderClientRejectCall = (m) => {
		const text = I("{CLIENT_NAME} is not accepting video call from agent {AGENT_NAME}").
			replace("{CLIENT_NAME}", m.name).
			replace("{AGENT_NAME}", m.from);
		return <div>
			{text}
		</div>;
	}
	renderClientHangupVidCall = (m) => {
		const text = I("{CLIENT_NAME} stopped the video call").
			replace("{CLIENT_NAME}", m.name);
		return <div>
			{text}
		</div>;
	}
	renderAgentHangupVidCall = (m) => {
		const text = I("Agent {AGENT_NAME} stopped the video call").
			replace("{AGENT_NAME}", m.who);
		return <div>
			{text}
		</div>;
	}
	renderAgentStopRecording = (m) => {
		const text = I("Stop recording video call");
		return <div>
			{text}
		</div>;
	}
	renderAgentDoneRecording = (m) => {
		return null;
	}
	renderClientScreenShareOffer = (m) => {
		let text = I("{CLIENT_NAME} is starting screen sharing with agent {AGENT_NAME}").
		replace("{CLIENT_NAME}", m.from).
		replace("{AGENT_NAME}", m.who);
		if(m.coBrowse) {
			text = I("{CLIENT_NAME} is starting a co-browsing with agent {AGENT_NAME}").
			replace("{CLIENT_NAME}", m.from).
			replace("{AGENT_NAME}", m.who);
		}
		return <div>
			{text}
		</div>;
	}
	renderAgentScreenShareOffer = (m) => {
		const text = I("Agent {AGENT_NAME} is starting screen sharing with {CLIENT_NAME}").
		replace("{AGENT_NAME}", m.from).
		replace("{CLIENT_NAME}", m.who);
		return <div>
			{text}
		</div>;
	}
	renderClientStopScreenShare = (m) => {
		let text = I("{CLIENT_NAME} stopped screen sharing with agent {AGENT_NAME}").
		replace("{CLIENT_NAME}", m.from).
		replace("{AGENT_NAME}", m.who);
		if(m.coBrowse) {
			text = I("{CLIENT_NAME} stopped co-browsing with agent {AGENT_NAME}").
			replace("{CLIENT_NAME}", m.from).
			replace("{AGENT_NAME}", m.who);
		}
		return <div>
			{text}
		</div>;
	}
	renderClientOfferScreenShare = (m) => {
		let text = I("{CLIENT_NAME} starting screen sharing with agent {AGENT_NAME}")
			.replace("{CLIENT_NAME}", m.from)
			.replace("{AGENT_NAME}", m.who);
		if(m.coBrowse) {
			text = I("{CLIENT_NAME} is starting a co-browsing with agent {AGENT_NAME}")
			.replace("{CLIENT_NAME}", m.from)
			.replace("{AGENT_NAME}", m.who);
		}
		return <div>
			{text}
		</div>;
	}
	renderAgentOfferScreenShare = (m) => {
		const text = I("Agent {AGENT_NAME} starting screen sharing with {CLIENT_NAME}")
		.replace("{AGENT_NAME}", m.from)
		.replace("{CLIENT_NAME}", m.who);
		return <div>
			{text}
		</div>;
	}
	renderAgentStopScreenShare = (m) => {
		const text = I("Agent {AGENT_NAME} stopped screen sharing with {CLIENT_NAME}").
		replace("{AGENT_NAME}", m.from).
		replace("{CLIENT_NAME}", m.who);
		return <div>
			{text}
		</div>;
	}
	renderAgentStopRemoteScreenShare = (m) => {
		let text = I("Agent {AGENT_NAME} stopped screen sharing.").
		replace("{AGENT_NAME}", m.who);
		if(m.coBrowse) {
			text =I("Agent {AGENT_NAME} stopped a co-browsing session.").replace("{AGENT_NAME}", m.who);
		}
		return <div>
			{text}
		</div>;
	}
	renderClientRejectScreenShare = (m) => {
		const text = I("{CLIENT_NAME} is unable to accept screen sharing at the moment.").
			replace("{CLIENT_NAME}", m.from);
		return <div>
			{text}
		</div>;
	}
	renderClientActivateCoBrowse = (m) => {
		const text = I("{CLIENT_NAME} activated co-browsing").
		replace("{CLIENT_NAME}", m.from);
		return <div>
			{text}
		</div>;
	}
	renderClientStopAgentScreenShare = (m) => {
		const text = I("{CLIENT_NAME} stopped screen sharing").
		replace("{CLIENT_NAME}", m.from);
		return <div>
			{text}
		</div>;
	}
	renderAgentInitCoBrowse = (m) => {
		const text = I("{AGENT_NAME} initiated a co-browsing session.").
			replace("{AGENT_NAME}", m.from);
		return <div>
			{text}
		</div>;
	}
	renderCallbackRequestNow = (m) => {
		const text = I("{CLIENT_NAME} have requested a callback through phone number {PHONE}")
			.replace("{CLIENT_NAME}", m.from).replace("{PHONE}", m.data.info.phone);
		return <div>
			{text}
		</div>;
	}
	renderCallbackRequestSchedule = (m) => {
		const unixTimestamp = m.data.info.datetime;
		let timezone;
		if (window.features && window.features.agentTimezoneOffset) {
			timezone = window.features.agentTimezoneOffset;
		} else {
			timezone = moment().format("ZZ");
		}
		const formattedTime = moment.unix(unixTimestamp).utcOffset(timezone).format('YYYY/MM/DD HH:mm');
		const text = I("{CLIENT_NAME} have requested a callback through phone number {PHONE} at {TIME}, we will reach you at the appointed time.")
			.replace("{CLIENT_NAME}", m.from).replace("{PHONE}", m.data.info.phone).replace("{TIME}", formattedTime);

		return <div>
			{text}
		</div>;
	}
	render() {
		let m = JSON.parse(this.props.json);
		if (!m) {
			console.log("error: could not parse system message", this.props.json);
			return null;
		}
		switch (m.event) {
			case "SET_AGENTS":
				return this.renderSetAgentsEvent(m);
				break;
			case "AGENT_LEFT":
				return this.renderAgentLeftEvent(m);
				break;
			case "CLIENT_ENDS_CHAT":
				return this.renderClientEndsChat(m);
				break;
			case "RETURN_TO_QUEUE":
				return this.renderReturnToQueue(m);
			case "FORWARD_TO_AGENT":
				return this.renderForwardToAgent(m);
			case "FORWARD_TO_AREA":
				return this.renderForwardToArea(m);
			case "SEND_CHAT_HISTORY":
				return this.renderSendChatHistory(m);
			case "ACCEPT_CHAT":
				return this.renderAcceptChat(m);
			case "REJECT_CHAT":
				return this.renderRejectChat(m);
			case "CHAT_EXPIRED":
				return this.renderChatExpired(m);
			case "OWNER_ENDS_CHAT":
				return this.renderOwnerEndsChat(m);
			case "AGENT_REJECT_VIDEO_CALL":
				return this.renderAgentRejectVidCall(m);
				break;
			case "AGENT_ACCEPT_VIDEO_CALL":
				return this.renderAgentAcceptVidCall(m);
				break;
			case "CLIENT_HANGUP_VIDEO_CHAT":
				return this.renderClientHangupVidCall(m);
				break;
			case "AGENT_HANGUP_VIDEO_CHAT":
				return this.renderAgentHangupVidCall(m);
				break;
			case "CLIENT_ACCEPT_VIDEO_CHAT":
				return this.renderClientAcceptCall(m);
				break;
			case "CLIENT_REJECT_VIDEO_CALL":
				return this.renderAgentScreenShareOffer(m);
				break;
			case "CLIENT_OFFER_SCREEN_SHARE":
				return this.renderClientOfferScreenShare(m);
				break;
			case "CLIENT_STOP_SCREEN_SHARE":
				return this.renderClientStopScreenShare(m);
				break;
			case "AGENT_OFFER_SCREEN_SHARE":
				return this.renderAgentOfferScreenShare(m);
				break;
			case "AGENT_STOP_SCREEN_SHARE":
				return this.renderAgentStopScreenShare(m);
				break;
			case "AGENT_REJECT_SCREEN_SHARE":
				return this.renderAgentRejectScreenShare(m);
				break;
			case "AGENT_STOP_REMOTE_SCREEN_SHARE":
				return this.renderAgentStopRemoteScreenShare(m);
				break;
			case "CLIENT_REJECT_SCREEN_SHARE":
				return this.renderClientRejectScreenShare(m);
				break;
			case "CLIENT_STOP_AGENT_SCREEN_SHARE":
				return this.renderClientStopAgentScreenShare(m);
				break;
			case "AGENT_INIT_COBROWSE":
				return this.renderAgentInitCoBrowse(m);
				break;
			case "CLIENT_ACTIVATE_COBROWSE":
				return this.renderClientActivateCoBrowse(m);
				break;
			case "CALLBACK_REQUEST_NOW":
				return this.renderCallbackRequestNow(m);
				break;
			case "CALLBACK_REQUEST_SCHEDULE":
				return this.renderCallbackRequestSchedule(m);
				break;
		}
		return <p>{this.props.json}</p>
	}
}

export default SystemMessage;
