import React from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import { I } from '../../common/v5/config.js';
import BeautifySubject from './BeautifySubject';
import ErrandTags from './ErrandTags';

const Span = ({text, bold}) => {
	if (bold) {
		return <span><b>{text}</b></span>;
	}
	return <span>{text}</span>;
};

const TagNames = ({texts, bold}) => {
	if (!texts || texts.length <= 1) {
		return <Span text={texts} bold={bold} />;
	}
	let spans = [];
	const lastIndex = texts.length - 1;
	$.each(texts, (i, v) => {
		if (i !== lastIndex) {
			spans.push(<Span key={i} text={v} bold={bold} />);
			spans.push(<Span key={"comma"+i} text=", " bold={bold} />);
		} else {
			spans.push(<Span key={i} text={v} bold={bold} />);
		}
	});
	return <span>{spans}</span>;
};

const subjectTitle = I('Subject:')
	, reasonReTag = I("You need to re-tag {DISPLAY_ID}, because its area {AREA_NAME} doesn't have {TAGS_NAME} you have chosen available.")
	;
const ConditionMessage = ({condition, displayId, areaName}) => {
	if (!condition) {
		return null;
	}
	let spans = []
		, ss
		;
	ss = reasonReTag.split("{DISPLAY_ID}");
	spans.push(<Span key="pre-id" text={ss[0]} />);
	spans.push(<Span key="display-id" bold={true} text={displayId} />);
	ss = ss[1].split("{AREA_NAME}");
	spans.push(<Span key="pre-area" text={ss[0]} />);
	spans.push(<Span key="area-name" bold={true} text={areaName} />);
	ss = ss[1].split("{TAGS_NAME}");
	spans.push(<Span key="pre-tags" text={ss[0]} />);
	spans.push(<TagNames key="tags-name" bold={true} texts={condition} />);
	spans.push(<Span key="last" text={ss[1]} />);
	return (
		<div className="renderClassificationMessage">{spans}</div>
	);
};

class TagItemList extends React.Component {
	constructor(props) {
		super(props);
		this.toggleTag = this.toggleTag.bind(this);
		this.state = {
			showTagSelection: false
		};
	}
	parentIsSameOrigin() {
		var result = true;
		try {
			result = window.parent.location.host == window.location.host;
		} catch(e) {
			result = false;
		}
		return result;
	}
	handleTagKeyDown(e) {
		console.log('dbg: todo: keydown tag not working yet');
	}
	renderClassificationMessage() {
		if(this.props.hotkeys) {
			let classificationSubject = null, classificationMessage = null;
			if(!this.props.classificationIconicSubject &&
				this.props.classificationSubject) {
				classificationSubject = <p className="classificationSubject">
						{subjectTitle}
						<BeautifySubject text={this.props.classificationSubject} />
					</p>;
			} else if(this.props.classificationIconicSubject) {
				//TODO: use React component to render the heart icon instead of
				// directly insert HTML. As this iconic subject seem only
				// trigger by heart icon which trigger by email from cention.se
				// domain.
				const subjectText = subjectTitle +
					this.props.classificationIconicSubject;
				classificationSubject = <p className="classificationSubject"
					dangerouslySetInnerHTML={{__html: subjectText}} />;
			}
			if(this.props.classificationMessage) {
				classificationMessage = <span>
						<p className="classificationMessage">
							{this.props.classificationMessage}
						</p>
						<p className="classificationInstruction">
							{I("Press enter to answer the errand with the selected classifications. Press escape to cancel.")}
						</p>
					</span>;
			}
			return <div className="renderClassificationMessage">
					{classificationSubject}
					{classificationMessage}
				</div>;
		} else {
			return "";
		}
	}
	renderTagDataHeight(tagHeight) {
		let height;
		if(this.props.classificationMessage &&
			document.body.clientHeight < 500) {
			height = document.body.clientHeight - 200;
		} else if(document.body.clientHeight < 500) {
			height = document.body.clientHeight - 150;
		} else {
			height = tagHeight;
		}
		return height;
	}
	toggleTag(toggleState) {
		this.setState({showTagSelection: toggleState});
	}
	render() {
		const p = this.props;
		if(!p.show) {
			return null;
		}
		let tagHeight = 600, myClass = 'tagitemlist cention-modal';
		if(this.parentIsSameOrigin()) {
			tagHeight = parent.innerHeight - 200
		}
		if(p.className) {
			myClass += ' ' + p.className;
		}
		let errorMessage = "";
		let canConfirmTag = false;

		//NOTE: I let the popup appear for multiple errands
		//but enable the 'Confirm' button so user knows some
		//of the selected errand's area doesn't have tags.
		if(p.tagsFetchReady){
			if (!p.tags || p.tags.length == 0) {
				//NOTE: Only tagging multiple errand will reach here
				//as when no tag on single errand's area,
				//this modal not triggered.
				canConfirmTag = true;
				errorMessage = I('No tags defined in the area');
			}else{
				if(p.selectedTags.length){
					canConfirmTag = true;
				}
			}
		}
		return <Modal className={myClass} isOpen={p.show} toggle={p.onCancel} backdrop={p.backDropDisable ? 'static' : true}>
				<ModalHeader toggle={p.onCancel}>
					{p.classificationTitle}
				</ModalHeader>
				<ModalBody>
					<div className="body-content">
						{this.renderClassificationMessage()}
						<ConditionMessage condition={p.condition}
							displayId={p.displayId}
							areaName={p.areaName}
						/>
						<div id="tagData" style={{"height": 'max-content', minHeight: '190px', maxHeight: this.renderTagDataHeight(tagHeight), width: '100%'}}>
							<div className="errand-tag">
								<div className="tagWrapper">
									{errorMessage}
									{!errorMessage && <ErrandTags me={'classifytags'}
										height={tagHeight}
										title={I('TAGS')} tags={p.tags}
										eid={p.eid} onAddTag={p.onAddTag}
										onDeleteTag={p.onDeleteTag}
										onToggleTagsSelection={this.toggleTag}
										showTagsSelection={this.state.showTagSelection}
										selectedTags={p.selectedTags}
										hideAddErrandLink={p.hideAddErrandLink}
										onKeyDown={this.handleTagKeyDown}
										clearCurrentItem={p.condition ? true : false}
										tagsReady = {p.tagsFetchReady}
										/>
									}
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<button data-qa-id={"QA_tag_btn_confirm"}
						onClick={p.onConfirm} href="#"
						className="btn-blue"
						disabled={!canConfirmTag}
						title={I("Confirm")}>
							{I("Confirm")}
					</button>{' '}
					<button data-qa-id={"QA_tag_btn_cancel"}
						onClick={p.onCancel} href="#"
						className="btn-grey"
						title={I("Cancel")}>
							{I("Cancel")}
					</button>
				</ModalFooter>
			</Modal>;
	}
}

export default TagItemList;
