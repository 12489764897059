import React, { useState, useEffect } from 'react';

const Scrollable = ({children, disable, onScroll}) => {
	const [scrolled, setScrolled] = useState(false);

	const handleScroll = () => {
		const scrollContainer = document.querySelector('.scrollable-container');
		const scrolledElem = scrollContainer.firstElementChild;

		if (scrolledElem) {
			const atBottom = isBottom(scrolledElem);
			const atTop = isTop(scrolledElem);

			setScrolled(!atTop);

			if (onScroll) {
				onScroll(atBottom);
			}
		}
	};

	useEffect(() => {
		const scrollContainer = document.querySelector('.scrollable-container');
		const scrolledElem = scrollContainer.firstElementChild;

		if (scrolledElem) {
			scrolledElem.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (scrolledElem) {
				scrolledElem.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	const isTop = (el) => {
		return el.scrollTop === 0;
	}

	const isBottom = (el, threshold = 1) => {
		// 'threshold' is to account for potential rounding errors or discrepancies in client heights
		// by prevent false positives from occurring when an element is near but
		// not quite at the bottom
		return el.scrollHeight - el.scrollTop <= el.clientHeight + threshold;
	}

	const handleScrollTop = () => {
		const scrollContainer = document.querySelector('.scrollable-container');
		const scrolledElem = scrollContainer.firstElementChild;
		scrolledElem.scrollTop = 0;
	};

	const loadScrollToTop = () => {
		return (
			<div
				className="scroll-to-top-box"
				onClick={handleScrollTop}
				hidden={!scrolled}
			>
				<span className="scroll-to-top" title={I("Scroll to top")}>
					<i className="icon-chevron-up"></i>
				</span>
			</div>
		);
	}

	return (
		<div className="scrollable-container" onScroll={handleScroll}>
			{children}
			{!disable ? loadScrollToTop() : null}
		</div>
	);
}

export default Scrollable;
