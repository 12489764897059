import React, { Fragment } from 'react';
import classNames from 'classnames';
import {
	CHAT_SOURCE_ONLINE,
	CHAT_SOURCE_ENABLED
} from '../../common/v5/constants';
import { useCallbackWithValue, useCompose } from '../../hooks/callback';
import SwitchCheckbox from '../../reactcomponents/SwitchCheckbox';
import Helper from '../../reactcomponents/Helper'

export class ChatToggleSwitch extends React.Component {
	constructor(props){
		super(props);
		this.handleClick = this.props.handleClick.bind(this);
	}
	handleClick(){
		if(this.props.handleClick){
			this.props.handleClick();
		}
	}
	render(){
		const {status, statusText, chatConfigOpen, collapseSideBar, ...props} = this.props;
		let btnClass = "";
		if(status){
			switch (status) {
				case CHAT_SOURCE_ENABLED:
					btnClass = "jq-checkbox enabled";
					break;
				case CHAT_SOURCE_ONLINE:
					btnClass = "jq-checkbox checked";
					break;
				default:
					btnClass = "jq-checkbox";
					break;
			}
		}
		return (
			<ToggleSwitch handleClick={this.handleClick} btnClass={btnClass} active={chatConfigOpen} {...props}>
				{(statusText && !collapseSideBar) &&
					<div className="chat-active-status">
						{statusText}
					</div>
				}
				<a data-qa-id="toggle-switch-setting"
					title={this.props.label}
					className={classNames("setting-button", {active: chatConfigOpen})}
					onClick={this.props.handleConfig}
				>
					<i className="icon-chat-settings" title={this.props.title}></i>
				</a>
			</ToggleSwitch>
			);
	}
}

export const LabelToggleSwitch = ({
	checked,
	'data-qa-id': dataQAId,
	id,
	label,
	name,
	onClick,
	helperTxt,
	inlineLabel = true,
	disabled = false
}) => (
	<div className={classNames("label-row toggle-switch", { notInline: !inlineLabel })} >
		<div className='label-block'>
			<div className='label-admin-text-input'>
				<label htmlFor={id}>{label}</label>
			</div>
			<SwitchCheckbox
				active={checked}
				id={id}
				inputId={id}
				name={name}
				disabled={disabled}
				onClick={useCompose(useCallbackWithValue, onClick, !checked, id)}
			/>
			{helperTxt && <Helper>{helperTxt}</Helper>}
		</div>
	</div>
)

const Label = ({ icon, title }) => {
	let child
	if (icon) {
		child = <i className={icon} />
	} else {
		child = title
	}
	return <span className="setting-label" title={title}>{child}</span>
}

const PreOrPostLabel = ({ pre, children, icon, title }) => (
	<Fragment>
		{pre && <Label icon={icon} title={title} />}
		{children}
		{!pre && <Label title={title} />}
	</Fragment>
)

class ToggleSwitch extends React.Component {
	static defaultProps = { listWrap: true };
	constructor(props) {
		super(props);
	}
	toggleSwitch = () => {
		if(this.props.handleToggle){
			this.props.handleToggle(!this.props.checked);
		}else{
			if(this.props.handleClick){
				this.props.handleClick();
			}
		}
	}
	render() {
		const {
			btnClass,
			className = "",
			checked,
			children,
			collapseSideBar,
			hide,
			icon,
			id,
			label,
			listWrap,
			name,
			disabled = false
		} = this.props
		let Wrapper
		if (listWrap) {
			Wrapper = 'li'
		} else {
			Wrapper = 'span'
		}
		return (
			<Wrapper className={className} hidden={listWrap && hide}>
				<PreOrPostLabel
					icon={listWrap ? icon : false}
					pre={collapseSideBar}
					title={label}
				>
					<SwitchCheckbox
						active={checked}
						buttonClassName={btnClass}
						id={id}
						name={name}
						disabled={disabled}
						onClick={this.toggleSwitch}
					/>
				</PreOrPostLabel>
				{listWrap && children}
			</Wrapper>
		)
	}
}

export default ToggleSwitch;
