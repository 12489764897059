import React from 'react';
import {
	RC_EMAIL,
	SHARE_STATISTICS,
	STATISTICS_PAGE
} from '../../common/v5/constants';
import TagPicker from './TagPicker';

const Button = ({value, className, btnClassName, onAction, disabled, ...props}) => {
	return <div className={className} >
			<button data-qa-id={"schedule-report-btn-"+value}
				className={btnClassName}
				disabled={disabled}
				type="button" onClick={onAction}>
					{value}
			</button>
		</div>;
};

class AgentRecipient extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleOpenAgentBook = this.handleOpenAgentBook.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleOpenAgentBook() {
		this.props.onToggleAgentBook(true, SHARE_STATISTICS, STATISTICS_PAGE)
	}
	handleChange(agent) {
		let selectedAgents = this.props.selected;
		let arrAgent = [];
		$.each(selectedAgents, (i, v) => {
			$.each(agent, (j,k) => {
				if(k === v.name) {
					arrAgent.push(v.id)
				}
			})
		});
		let strAgent = arrAgent.join(',');
		this.props.onSetShareParam('removeSelectedAgent', strAgent)
	}
	render() {
		const { formDisabled, selected } = this.props;
		let selectedName = [];
		$.each(selected, (i, v) => {
			selectedName.push(v.name)
		});
		return (
			<div className="agent-container">
				<label>{I("Agents: ")}</label>
				<div className="tag-picker-input">
					<TagPicker
						disabled={formDisabled}
						onShowContactBook={this.handleOpenAgentBook}
						onChange={this.handleChange}
						selected={selectedName}
					/>
				</div>
			</div>
		)
	}
};

class EmailRecipient extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleChangeInput = this.handleChangeInput.bind(this);
		this.handleRecipientChange = this.handleRecipientChange.bind(this);
		this.handleFailValidation = this.handleFailValidation.bind(this);
		this.state = {
			currentEmail: ""
		}
	}
	handleChangeInput(currentInput) {
		this.setState({currentEmail: currentInput});
	}
	handleFailValidation(type, inpt) {
		if (type === RC_EMAIL) {
			if (this.props.onFailValidation) {
				this.props.onFailValidation(type, inpt);
				this.setState({currentEmail: null});
			};
		};
	}
	handleRecipientChange(emails) {
		let emailStr = emails.join(",");
		this.props.onSetShareReport("emailAddress", emailStr);
	}
	render() {
		const { className, formDisabled, onChange, value, ...props } = this.props;
		let emailRecipient = [];
		if (value) {
			emailRecipient = value.split(",");
		};
		return (
			<div className="email-container">
				<label>{I('Emails: ')}</label>
				<div className="tag-picker-input">
					<TagPicker
						basic={true}
						currentValue={this.state.currentEmail}
						disabled={formDisabled}
						onChange={this.handleRecipientChange}
						onFailValidation={this.handleFailValidation}
						selected={emailRecipient}
						type={RC_EMAIL}
					/>
					<small className="report-field-tips">{I('Enter email addresses to share this report with.')}</small>
				</div>
			</div>
		)
	};
};

class ShareReport extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			toggle: false,
		};
		this.handleClose = this.handleClose.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handleShare = this.handleShare.bind(this);
		this.handleDone = this.handleDone.bind(this);
	}
	handleClose() {
		this.props.showShareReport(false);
	}
	handleToggle(id, toggle) {
		this.setState({toggle: !toggle});
	}
	handleShare() {
		this.props.onSetShareReport("busy", true);
		this.props.onShareReport();
	}
	handleDone() {
		this.props.resetShareReport();
		this.handleClose();
	}
	render() {
		const { busy, show, emailAddress,
			onFailValidation, onSetShareReport,
			onToggleAgentBook, selectedAgent, shareReportId,
			status, ...props } = this.props;
		let cbClass = "share report popup";
		if(show) {
			cbClass = "share report popup open";
		}
		let formDisabled = false, buttonDisabled = true;
		if(emailAddress || selectedAgent.length > 0) {
			buttonDisabled = false
		}
		let button = <Button btnClassName="btn-blue"
			value={I("Share")} className="share-button"
			disabled={buttonDisabled}
			onAction={this.handleShare} />
		if(busy) {
			let buttonText = <span>
				<i className="fa fa-spinner fa-spin"
				aria-hidden="true" /> {I("Share")}
			</span>
			button = <Button btnClassName="btn-gray"
				value={buttonText} className="share-button" />
		}
		if(shareReportId > 0 && status === "done") {
			formDisabled = true;
			button = <Button btnClassName="btn-blue"
				value={I("Done")} className="share-button"
				onAction={this.handleDone} />
		}
		if(show) {
			return(
				<div className={cbClass}>
					<div className="report-inner popup-inner">
						<div className="schedule-report-box">
							<div className="popup-tabs">
								<span className={"toggle-tabs"} >
									{"Share Report"}
								</span>
							</div>
							<form>
								<div className="label-row">
									<div className="label-block">
										<AgentRecipient
											formDisabled={formDisabled}
											onToggleAgentBook={onToggleAgentBook}
											onSetShareParam={onSetShareReport}
											selected={selectedAgent}
										/>
									</div>
								</div>
								<div className="label-row">
									<div className="label-block">
										<div className="divider">
											<label>{I("or")}</label>
										</div>
									</div>
								</div>
								<div className="label-row">
									<div className="label-block">
										<EmailRecipient className="email"
											formDisabled={formDisabled}
											onFailValidation={onFailValidation}
											onSetShareReport={onSetShareReport}
											value={emailAddress}
										/>
									</div>
								</div>
								<div className="label-row">
									{button}
								</div>
							</form>
					</div>
						<div data-qa-id="schedule-report-close" className="popup-close" onClick={this.handleClose}>
							<i className="icon-times"></i>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
};
export default ShareReport;
