import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import {
	branch
	, mapProps
	, renameProps
	, renderComponent
	, withProps
} from 'recompose';
import update from 'immutability-helper';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import {
	composeWithDisplayName
	, withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import Button from '../../reactcomponents/Button';
import {
	AreaFilter,
	PriorityFilter,
	AgentFilter,
	TagFilter
} from '../../reactcomponents/WorkflowFilter';
import { IsContextSearch } from '../../common/v5/utils';
import { CTX_REVIEW, MY_ERRANDS, VIP_ERRANDS } from '../../common/v5/constants';
import { DummyFunction } from '../../common/v5/helpers';

class TopFilterBase extends React.Component {
	constructor(props) {
		super(props);
		this.handleToggleTopFilter = this.handleToggleTopFilter.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.state = { showTopFilter : false };
	}
	handleToggleTopFilter() {
		this.setState({ showTopFilter: !this.state.showTopFilter });
	}
	handleClickOutside() {
		if(this.state.showTopFilter) {
			this.setState({ showTopFilter: false });
		}
	}
	render() {
		return (
			<li
				title={I("Filter")}
				data-qa-id={"top-filter"}
				key={"top-filter"}
				className="top-filter"
			>
				<a onClick={this.handleToggleTopFilter}><i className="icon-filter" /></a>
				<div className="tag-filter-top" hidden={!this.state.showTopFilter}>
					<div>{I("Filter")} <i className="icon-filter" /></div>
					<ul>
						{this.props.children}
					</ul>
				</div>
			</li>
		);
	}
}

export const TopFilter = onClickOutside(TopFilterBase);

const Ul = ({ children }) => (
	<ul className="sidebar-dropdown-menu">{children}</ul>
);

const MainWrapper = branch(
	({ collapseSideBar }) => !collapseSideBar
	, renderComponent(Ul)
)(TopFilter);

const prioritiesArray = [
	{id: "hp_first", name: I("High Priority"), className: "high-priority"}
	, {id: "warnings_first", name: I("Warnings"), className: "warning"}
	, {id: "collaboration_first", name: I("Collaboration"), className: "collaboration"}
	, {id: "expired_first", name: I("Expired"), className: "expired"}
	, {id: "replies_first", name: I("Replies"), className: "replies"}
];

const WrapperBase = ({ children, className, isDropdown, tag: Tag }) => (
	<Tag
		className={classNames(
			className
			, {"sidebar-dropdown-menu": isDropdown}
		)}
	>
		{children}
	</Tag>
);

const Wrapper = composeWithDisplayName(
	"Wrapper"
	, withProps(({ top }) => {
		let props = {};
		if (top) {
			props.tag = "div";
			props.className = "tag-filter-top-item"
		} else {
			props.tag = "li";
		}
		return props;
	})
)(WrapperBase);

const createWithWrapper = isDropdown => Component => ({ top, ...props }) => (
	<Wrapper isDropdown={isDropdown} top={top}>
		<Component {...props} top={top} />
	</Wrapper>
);

const PriorityBase = ({
	onSortBy
	, onToggle
	, selected
	, show
	, top
}) => {
	//for translation
	const [prioritiesArrayNew, setPrioritiesArrayNew] = useState(prioritiesArray)
	useEffect(()=> {
		let pal = prioritiesArray;
		if(typeof TranslationDictionary !== 'undefined'){
			pal = prioritiesArray.map(v => ({id: v.id, name: L(v.name), className: v.className}));
			setPrioritiesArrayNew(pal);
		}
	}, [])
	return <Fragment>
		<a
			data-qa-id="QA_togglePriorityFilter"
			className={classNames(
				{"tag-filter-top-item-header": top}
				, {"dropped": show}
			)}
			onClick={onToggle}
			data-dropdown-action
		>
			{I("Priority")}{top ? " :" : ""}
		</a>
		<PriorityFilter
			paramsSet={prioritiesArrayNew}
			onSortBy={onSortBy}
			selected={selected}
			top={top}
		/>
	</Fragment>
};

const useAutoBackToTopFilteredListHandler = (
	onSetParamsThenReload
	, autoBackToTop
) => useCallback(
	params => {
		onSetParamsThenReload(update(params, {offset: {$set: 0}}));
		autoBackToTop();
	}
	, [autoBackToTop, onSetParamsThenReload]
);

const useFilteredListHandler = onSetParamsThenReload => useCallback(
	params => onSetParamsThenReload(update(params, {offset: {$set: 0}}))
	, [onSetParamsThenReload]
);

const withFilteredListBase = Component => ({
	onSetParamsThenReload
	, ...props
}) => (
	<Component
		{...props}
		onFilteredList={useFilteredListHandler(onSetParamsThenReload)}
	/>
);

const withAutoBackToTopFilteredList = Component => ({
	autoBackToTop
	, onSetParamsThenReload
	, ...props
}) => (
	<Component
		{...props}
		onFilteredList={useAutoBackToTopFilteredListHandler(
			onSetParamsThenReload
			, autoBackToTop
		)}
	/>
);

const withFilteredList = branch(
	({ collapseSideBar }) => !collapseSideBar
	, withAutoBackToTopFilteredList
	, withFilteredListBase
);

const useResetAndReloadList = ({
	agentInboxSize: size
	, selectedFolder: folder
	, onFilteredList
	, onToggleSideBar
	, showMobileView
}) => useCallback(
	(source, field, value) => {
		if (showMobileView) {
			onToggleSideBar(false);
		}
		// TODO: this reset 'folder' may cause a bug because in
		// 'my errand folder' it is reset to 0 because no selectedFolder during
		// initial load.
		const param = {folder, size, source};
		// TODO: this is to backward compatible with 'search context' BUT
		// should we use 'my context' priority sort on 'search context'? There
		// isn't any priority filter in search at all.
		param["sort_"+field] = value;
		onFilteredList(param);
	}
	, [folder, showMobileView, size, onFilteredList, onToggleSideBar]
);

const useChangePriorityFilter = ({
	currentContext: context
	, onChangePriorityFilter
	, ...props
}) => {
	const handleResetAndReloadList = useResetAndReloadList(props);
	return useCallback(
		(field, value) => {
			onChangePriorityFilter({context, field, value});
			handleResetAndReloadList(context, field, value);
		}
		, [context, onChangePriorityFilter, handleResetAndReloadList]
	);
};

export const PrioritySort = composeWithDisplayName(
	"PrioritySort"
	, withProps(({ currentContext, hidden, onToggle, sort, top }) => {
		let selected;
		if (!hidden) {
			selected = sort[currentContext];
			if (typeof selected === "undefined") {
				hidden = true;
			}
		}
		if (top) {
			onToggle = DummyFunction;
		}
		return {hidden, onToggle, selected};
	})
	, withUnmountWhenHidden
	, createWithWrapper()
)(PriorityBase);

const ApplyCancel = ({ onApply, onCancel }) => (
	<div className="top-filter-btns" hidden>
		<div className="button-row">
			<Button
				color="grey"
				text={I("Cancel")}
				className="btn-grey"
				onClick={onApply}
			/>
			<Button
				color="blue"
				text={I("Apply")}
				className="btn-blue"
				onClick={onCancel}
			/>
		</div>
	</div>
);

const withDropdownWrapper = createWithWrapper(true);

const useAgentFilterHandler = ({
	agentInboxSize: size
	, myErrandFolder: folder
	, onFilteredList
	, onToggleSideBar
	, onSetParamsThenReload
	, onSetSelectedAgent
	, onSetSelectedFolder
	, showMobileView
}) => {
	// const handleFilteredList = useFilteredListHandler(onSetParamsThenReload);
	return useCallback(
		params => {
			if (showMobileView) {
				onToggleSideBar(false);
			}
			onSetSelectedFolder(0);
			onSetSelectedAgent(params.user);
			onFilteredList(update(
				params
				, {$merge: {classification: "", folder, size}}
			));
		}
		, [
			folder
			, onFilteredList
			, onSetSelectedAgent
			, onSetSelectedFolder
			, onToggleSideBar
			, showMobileView
			, size
		]
	);
};

const AgentBase = ({
	counter
	, data
	, onFilter
	, onToggle
	, selected
	, show
	, top
	, noCounter
}) => (
	<Fragment>
		<a
			data-qa-id="QA_toggleAgentFilter"
			className={classNames({dropped: show})}
			onClick={onToggle}
			data-dropdown-action
		>
			{I("Agents")}
		</a>
		<AgentFilter
			counter={counter}
			noCounter={noCounter}
			data={data}
			onClickAgent={onFilter}
			selectedAgent={selected}
		/>
	</Fragment>
);

const Agent = composeWithDisplayName(
	"Agent"
	, withUnmountWhenHidden
	, withDropdownWrapper
)(AgentBase);

const withWrapperIfTopFilter = branch(
	({ top }) => !!top
	, withDropdownWrapper
);

const createWithHideableAndWrapperIfTopFilter = name => composeWithDisplayName(
	name
	, withUnmountWhenHidden
	, withWrapperIfTopFilter
);

const Area = createWithHideableAndWrapperIfTopFilter("Area")(AreaFilter);

const useTagFilterHandler = ({
	onToggleSideBar
	, setLoadList
	, showMobileView
}) => useCallback(
	() => {
		if (showMobileView) {
			onToggleSideBar(false);
		}
		setLoadList();
	}
	, [onToggleSideBar, setLoadList, showMobileView]
);

const Tag = composeWithDisplayName(
	"Tag"
	, createWithHideableAndWrapperIfTopFilter("TagBase")
	, renameProps({
		data: "currentTags"
		, onToggle: "onClickPanel"
		, show: "showTagsFilter"
	})
)(TagFilter);

const FiltersBase = props => {
	const {
			agentAreas
			, agentFilter
			// , agentInboxSize
			, agents
			, areaFilter
			, collapseSideBar
			, context
			, currentContext
			, filteredTags
			, getCurrentAreas
			, isGrpLeader
			, onChangeAreaFilter
			// , onChangePriorityFilter
			, onSetFilteredAreas
			, onSetFilteredTags
			// , onSetSelectedAgent
			// , onSetSelectedFolder
			, onSetSelectedTags
			, onShowAllArea
			, onToggleAgentsFilter
			, onToggleAreasListFilter
			, onTogglePriorityFilter
			// , onToggleSideBar
			, onToggleTagsFilter
			, orgAreas
			, selectedAgent
			, selectedAreas
			// , selectedFolder
			, selectedTags
			, selectedOrgs
			, showAgentsFilter
			, showAllArea
			, showAreasFilter
			// , showMobileView
			, showPriorityFilter
			, showTagsFilter
			, sort
			, tagsFilter
			, hideCounter
		} = props
		, handleAgentFilter = useAgentFilterHandler(props)
		, handleSortBy = useChangePriorityFilter(props)
		, handleTagFilter = useTagFilterHandler(props)
		;
	return (
		<MainWrapper collapseSideBar={collapseSideBar}>
			<PrioritySort
				currentContext={currentContext}
				hidden={IsContextSearch(currentContext)}
				onSortBy={handleSortBy}
				onToggle={onTogglePriorityFilter}
				sort={sort}
				show={showPriorityFilter}
				top={collapseSideBar}
			/>
			<Area
				context={context}
				counter={areaFilter}
				data={agentAreas}
				hidden={IsContextSearch(currentContext) || currentContext === VIP_ERRANDS}
				getCurrentAreas={getCurrentAreas}
				noCounter={currentContext === CTX_REVIEW || hideCounter}
				onChange={onChangeAreaFilter}
				onSetFilteredAreas={onSetFilteredAreas}
				onShowAll={onShowAllArea}
				onToggleAreasListFilter={onToggleAreasListFilter}
				orgAreas={orgAreas}
				selectedAreas={selectedAreas}
				selectedOrgs={selectedOrgs}
				show={showAreasFilter}
				showAll={showAllArea}
				top={collapseSideBar}
			/>
			<Agent
				counter={agentFilter}
				data={agents}
				hidden={!isGrpLeader || currentContext != MY_ERRANDS}
				onFilter={handleAgentFilter}
				onToggle={onToggleAgentsFilter}
				selected={selectedAgent}
				show={showAgentsFilter}
				top={collapseSideBar}
				noCounter={hideCounter}
			/>
			<Tag
				currentFiltered={filteredTags}
				currentSelected={selectedTags}
				data={tagsFilter}
				hidden={currentContext === CTX_REVIEW || tagsFilter.length <= 0}
				onClickTag={handleTagFilter}
				onSetFilteredTags={onSetFilteredTags}
				onSetSelectedTags={onSetSelectedTags}
				onToggle={onToggleTagsFilter}
				show={showTagsFilter}
				top={collapseSideBar}
				noCounter={hideCounter}
			/>
			<ApplyCancel
				onApply={DummyFunction}
				onCancel={DummyFunction}
			/>
		</MainWrapper>
	);
};

export const Filters = composeWithDisplayName(
	"Filters"
	, memo
	, withUnmountWhenHidden
	, mapProps(({ filter, ui, ...props }) => {
		const { currentContext, ...restFilter } = filter;
		return {...restFilter, ...ui, ...props};
	})
	, withFilteredList
)(FiltersBase);

const TopWorkflowFilter = props => <Filters {...props} collapseSideBar={true} />;

export default TopWorkflowFilter;
