import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from 'styled-components'
import {
  centionBlack,
  chatSystemMessageBackground
} from '../../styles/_variables'
import EnchancedMoment from './EnchancedMoment'

const StyleDiv = styled.div`
  &.errand-message {
    margin-left: 20px;
    .message-system {
      display: inline-block;
      padding: 5px 16px 3px 14px;
      margin: 5px;
      background-color: ${({ theme }) => theme.tintLightBlack || `${chatSystemMessageBackground}`};
      color: ${({ theme }) => theme.lightGrey || `${centionBlack}`};
    }
  }
`
const withSystemMessage = Component => ({
  parseFormat,
  timestamp,
  ...props
}) => (
  <StyleDiv className='errand-message' theme={useContext(ThemeContext)}>
    <div className='message-system'>
      <Component {...props} />
    </div>
    <div className='errand-message-meta system' style={{marginBottom: '20px'}}>
      <span>
        <EnchancedMoment date={timestamp} parseFormat={parseFormat} />
      </span>
    </div>
  </StyleDiv>
)

export default withSystemMessage
