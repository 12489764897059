import React, { memo, useCallback, useMemo } from 'react'
import {
  AT_CHOOSE_AGENTS,
  AT_CHOOSE_GROUPS,
  DDG_AGENT_TYPES,
  DDG_DATE_FROM,
  DDG_DATE_TO,
  DDG_DATE_TYPE,
  DDG_DATE_COMPARE,
  DDG_GROUPS,
  DDG_INTERVAL_DATE,
  DDG_MULTI_AGENTS,
  DDG_MULTI_AREAS,
  DDG_MULTI_SERVICE_IDS,
  DDG_TIME_FORMAT,
  DDG_MULTI_SIMPLE_TAGS,
  DDG_REL_DATETIME,
  DDG_TIME_SPAN_FORMAT,
  DDG_TIME_ZONE,
  DT_RELATIVE,
  DDG_AGGREGATE_TYPE,
  DDG_COLLABORATORS
} from '../../common/v5/constants'
import { ArrayDropdowns } from './DropdownGroup'
import { CompareStatCtnr } from '../../views/v5/statisticsCtnr';

const typeToReportField = {
  [DDG_AGENT_TYPES]: 'agentType',
  [DDG_DATE_TYPE]: 'dateType',
  [DDG_DATE_COMPARE]: 'showCompare',
  [DDG_GROUPS]: 'groups',
  [DDG_MULTI_AGENTS]: 'users',
  [DDG_MULTI_AREAS]: 'areas',
  [DDG_MULTI_SERVICE_IDS]: 'channels',
  [DDG_MULTI_SIMPLE_TAGS]: 'tags',
  [DDG_REL_DATETIME]: 'relativeDateType',
  [DDG_TIME_FORMAT]: 'timeFormat',
  [DDG_TIME_SPAN_FORMAT]: 'timeStampFormat',
  [DDG_TIME_ZONE]: 'timeZoneId',
  [DDG_DATE_FROM]: 'startTime',
  [DDG_DATE_TO]: 'endTime',
  [DDG_AGGREGATE_TYPE]: 'aggregateType',
  [DDG_COLLABORATORS]: 'collaborators'
}

const ReportParametersBase = ({
  className,
  features,
  onParametersChange,
  options,
  parameters,
  reportAreas
}) => {
  const { dateType, agentType, startTime, endTime } = parameters
  const handleSelections = useCallback((index, type, ...args) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: report params index: ' + index + ' type: ' + type, args)
    }
    onParametersChange(typeToReportField[type], ...args)
  }, [onParametersChange])
  const handleDateTypeChange = useCallback(v => {
    onParametersChange(typeToReportField[DDG_DATE_TYPE], v)
  }, [onParametersChange])
  const handleFromDateChange = useCallback(v => {
    onParametersChange(typeToReportField[DDG_DATE_FROM], v)
  }, [onParametersChange])
  const handleToDateChange = useCallback(v => {
    onParametersChange(typeToReportField[DDG_DATE_TO], v)
  }, [onParametersChange])
  const handleRelDateChange = useCallback(v => {
    onParametersChange(typeToReportField[DDG_REL_DATETIME], v)
  }, [onParametersChange])
  const handleToggleCompare = useCallback(v => {
    onParametersChange(typeToReportField[DDG_DATE_COMPARE], v)
  }, [onParametersChange])
  const {
    hasKeyFigureThatFilterByUser: renderAgentTypes,
    noDateTimeRange
  } = options
  const renderRelDT = dateType === DT_RELATIVE
  const renderAbsDT = !renderRelDT
  const data = useMemo(() => ([
    {
      data: DDG_DATE_TYPE,
      selected: parameters[typeToReportField[DDG_DATE_TYPE]],
      doNotRender: noDateTimeRange
    },
    {
      data: DDG_DATE_COMPARE,
      selected: parameters[typeToReportField[DDG_REL_DATETIME]],
      //dateTypeSelected: parameters[typeToReportField[DDG_DATE_TYPE]],
      dateTypeSelected: dateType,
      fromDateSelected: startTime,
      toDateSelected: endTime,
      doNotRender: noDateTimeRange,
      onShowCompare: handleToggleCompare,
      onChangeDateType: handleDateTypeChange,
      onChangeFromDate: handleFromDateChange,
      onChangeToDate: handleToDateChange,
      onChangeRelDate: handleRelDateChange
    },
    {
      data: DDG_REL_DATETIME,
      selected: parameters[typeToReportField[DDG_REL_DATETIME]],
      doNotRender: noDateTimeRange || !renderRelDT
    },
    {
      data: DDG_DATE_FROM,
      selected: startTime,
      doNotRender: noDateTimeRange || !renderAbsDT
    },
    {
      data: DDG_DATE_TO,
      selected: endTime,
      doNotRender: noDateTimeRange || !renderAbsDT
    },
    { // TODO: is this longer been use? Why not just remove it from array?
      data: DDG_INTERVAL_DATE,
      mixed: true,
      selected: {
        selectedFromDate: startTime,
        selectedToDate: endTime
      },
      onSelect: {
        onChangeFromDate: handleFromDateChange,
        onChangeToDate: handleToDateChange
      },
      doNotRender: { doNotRender: true }
    },
    {
      data: DDG_MULTI_SERVICE_IDS,
      selected: parameters[typeToReportField[DDG_MULTI_SERVICE_IDS]],
      doNotRender: !options.hasKeyFigureThatFilterByChannel
    },
    {
      data: DDG_MULTI_AREAS,
      selected: parameters[typeToReportField[DDG_MULTI_AREAS]],
      doNotRender: !options.hasKeyFigureThatFilterByArea,
      source: reportAreas
    },
    {
      data: DDG_AGENT_TYPES,
      selected: parameters[typeToReportField[DDG_AGENT_TYPES]],
      doNotRender: !renderAgentTypes
    },
    {
      data: DDG_MULTI_AGENTS,
      selected: parameters[typeToReportField[DDG_MULTI_AGENTS]],
      doNotRender: !(renderAgentTypes && agentType === AT_CHOOSE_AGENTS)
    },
    {
      data: DDG_GROUPS,
      selected: parameters[typeToReportField[DDG_GROUPS]],
      doNotRender: !(renderAgentTypes && agentType === AT_CHOOSE_GROUPS)
    },
    {
      data: DDG_MULTI_SIMPLE_TAGS,
      selected: parameters[typeToReportField[DDG_MULTI_SIMPLE_TAGS]],
      doNotRender: !options.doesSomethingWithTags,
      others: { includeUntagged: true }
    },
    {
      data: DDG_TIME_ZONE,
      selected: parameters[typeToReportField[DDG_TIME_ZONE]],
      doNotRender: !features['show-time-zone']
    },
    {
      data: DDG_TIME_FORMAT,
      selected: parameters[typeToReportField[DDG_TIME_FORMAT]],
      doNotRender: !options.hasCustomTimeFormatGroup
    },
    {
      data: DDG_TIME_SPAN_FORMAT,
      selected: parameters[typeToReportField[DDG_TIME_SPAN_FORMAT]],
      doNotRender: !options.hasCustomAbsoluteTimeGroup
    },
    {
      data: DDG_AGGREGATE_TYPE,
      selected: parameters[typeToReportField[DDG_AGGREGATE_TYPE]],
      doNotRender: !options.hasAggregateFilter
    },
    {
      data: DDG_COLLABORATORS,
      selected: parameters[typeToReportField[DDG_COLLABORATORS]],
      doNotRender: !options.hasKeyFigureThatFilterByCollaborator
    }
  ]), [
    agentType,
    endTime,
    features,
    handleFromDateChange,
    handleToDateChange,
    noDateTimeRange,
    options,
    parameters,
    renderAbsDT,
    renderAgentTypes,
    renderRelDT,
    reportAreas,
    startTime
  ])
  return (
    <div>
      <ArrayDropdowns
        className={className}
        data={data}
        onSelect={handleSelections}
      />
      <CompareStatCtnr />
    </div>
  )
}

export default memo(ReportParametersBase)
