export const lightTheme = {
    collaborationParticipant: '#e0f3fe !important' ,
    collaborationBorder: '#efefef'
}
export const darkTheme = {
    //Following provided color pallete for Dark mode.
    //Color with syntax ~ "T(N)" shows darker tone than previous family (e.g darkBlueT2, darkBlueT3)
    //Grey
    tintGrey: '#babbbd1a',
    lightGrey: '#B9BBBD',
    grey: '#9c9c9c',
    midGrey: '#6D6D6D',
    midGreyT2: '#484848',
    darkGrey: '#3f3f3f',

    //Black
    tintLightBlack: '#00000029',
    tintBlack: '#00000033',
    lightBlack: '#333333', //lightblack
    midBlack: '#1B1C1C',
    pureBlack: 'black',

    //Green
    // tintGreen: '#00cc7780',
    tintGreen: '#00cc7733',
    lightGreen: '#6de1b1',
    centionGreen: '#0c7',
    darkGreen: '#007343',

    //White
    lightWhite: '#ccc',
    light: '#F0F0F0', // tk-todo: rename to mid white
    pureWhite: '#fff',

    //Blue
    tintBlue: '#0c87f733',
    skyBlue: '#e0f3ff',
    lightBlue: '#79CBFF',
    centionBlue: '#0c87f7',
    darkBlue: '#0055a3',
    //Aqua
    lightAqua: '#75B1CF',
    aqua: '#25adfc',
    darkAqua: '#00629c',

    //Red
    // tintRed: '#f4213f33',
    tintRed: '#f4213f1a',
    lightRed: '#f88091',
    red: 'indianred',
    centionRed: '#f4213f',
    darkRed: '#9c0016',
    centionDarkPurple: '#342247',

    tintPurple: '#881bff33',

    //Yellow
    tintYellow: '#ffaf1133',
    lightYellow: '#FFe870',
    centionYellow: '#ffaf11',
    darkYellow: '#a88e00',

    //Icons
    iconEmail: '#4EA8A1',
    iconFaceBook: '#5199E0',
    iconLinkedIn: '#1AA3D8',
    iconLine: '#09CE09',
    iconWebForm: '#64ACD8',
    iconViber: '#B25BCE',
    iconInstagram: '#D14B9B',
    iconChat: '#9C9C9C',
    iconMsTeam: '#6D81FF',
    iconJira: '#147BE2',
    iconYoutube: '#E24242',
    iconVK: '#68A9D8',
    iconGoogle: '#6697D3',
    telegram: '#039BE5',
    whatsApp: '#29A71A',
    twitter: '#4AA0EC',
    trustPilot: '#00B67A',
    playStore: '#34A853',
    iconMessenger: '#52B5FF',

    //Unrepetitive components color
    // background: '#999',
    //TODO: investigate usage of this variable and remove if possible
    collaborationParticipant: '#283E63 !important' ,
    collaborationAgent: '#353047 !important',

    //Special font color
    unreadErrandTextFont: 'bold',

    //Border color
    CkeButtonBorder: '1px solid #6EB3DE',
    sideBarBorder: '1px solid #1B1C1C',
    tableBorder: '1px solid #B9BBBD',
    borderColor: '1px solid #1B1C1C',
    toggleBorder: '#6B8096',

    //Filter
    invertFilter: 'invert(1)',
    grayScaleFilter: 'grayscale(1)',

    //Opacity
    centionTransparent: 'transparent',
    darkModeOpacity: '.6',

    //None
    setNone: 'none',
    unSet: 'unset',

}