import React, { Fragment } from 'react';
import ReactPaginate from 'react-paginate';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';
import {
	centionBackgroundGrey
} from '../../styles/_variables';
import { 
	SHORTCUT_SEARCH,
	SHORTCUT_SEARCH_TERM
 } from '../../common/v5/constants';
import { BriefSearchErrand } from './BriefErrand';
import Link from '../../containers/link';

const StyledSearchList = styled.div`
	position: absolute;
	z-index: 950;
	background-color: #f2f2f2;
	background-clip: padding-box;
	height: auto;
	bottom: ${({ height }) => height ? (height + 20) + 'px' : 'auto'};
	padding: 10px;
	width: 239px;
	white-space: nowrap;
	box-shadow: 0 5px 10px 0 rgba(0,0,0,.2);
	& > button {
		border: none;
		background: none;
		outline: none;
		margin: 0;
		line-height: 0.99em;
		color: #fff;
		font-size: 15px;
		transition: color .2s ease;
		vertical-align: top;
		background-color: white;
		height: 23px;
	}
	& > .search-field {
		border: none;
		background: none;
		background-color: white;
		outline: none;
		height: 23px;
		font-size: 10px;
		font-weight: 300;
		padding: 0;
		width: 191px;
	}
	& > ul {
		font-size: 12px;
    	padding-bottom: 10px;
	}
`

const StyledShortcutMenu = styled.div`
	position: absolute;
	// will-change: transform;
	bottom: ${({ height }) => height ? (height + 90) + 'px' : 'auto'};
	// left: 0px;
	// transform: translate3d(510px, 320px, 0px);
	z-index: 950;
	// float: left;
	min-width: 10rem;
	margin: .125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	order: 1px solid rgba(0,0,0,.15);
	border-radius: .25rem;
    cursor: default;
    margin-top: 0;
	box-shadow: 0 5px 10px 0 rgba(0,0,0,.2);
	height: auto;
	padding: 10px;
`
const StyledShortcutMenuScroll = styled.div`
	max-height: 20vh;
	max-width: 33vw;
	min-width: 200px;
	overflow: auto;
`
const StyledSearchListScroll = styled.div`
	max-height: 20vh;
	max-width: 33vw;
	min-width: 200px;
	min-height: ${({ height }) => height ? (height) + 'px' : 'auto'};
	overflow: auto;
`
const StyledButton = styled.a`
	padding: 8px 20px;
	font-size: 12px;
	width: 100%;
	line-height: 2;
	outline: none;
	& > span {
		padding: 8px 20px;
		font-size: 10px;
	}
`
const StyledLi = styled.li`
	padding: 8px 20px;
	width: 100%;
	outline: none;
	&:hover {
		cursor: pointer;
		background-color: ${centionBackgroundGrey}
	}
	&.active {
		cursor: pointer;
		background-color: ${centionBackgroundGrey}
	}
`


class ReplySearch extends React.Component {
	constructor(props){
		super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
		this.renderShortcutItem = this.renderShortcutItem.bind(this);
	}
	wrap(match){
		return '<b>' + match + '</b>';
	}
	highlightSearchTerm(term, searchResult){
		let regex = new RegExp("" + term + "", 'gi');
		return searchResult.replace(regex, match => this.wrap(match));
	}
	handleLinkSearch = (id) => {
		switch(id){
			case SHORTCUT_SEARCH_TERM:
				this.props.toggleSearchInput(true);
		}
	}
    renderShortcutItem(items, term) {
        let result = [], body, count = 0;
        items.forEach((shortcutItem, i) => {
            const { name, id, desc } = shortcutItem;
			body = this.highlightSearchTerm(term, name);
			body += "<br><span>" + desc + "</span";
			if (body.includes("<b>")){
				count++
				if(count == 1){
					result.push(<LinkSearch key={id} sid={id} body={body} active={true} handleClick={this.handleLinkSearch} />);
				}else{
					result.push(<LinkSearch key={id} sid={id} body={body} active={false} handleClick={this.handleLinkSearch} />);
				}
			}
        })
        return result;
	}
	handleClickOutside() {
		if(this.props.onHide());
	}
    render(){
		const {
				showShortcut
				, showSearch
				, term
				, searchData
				, searchResults
				, showReplyResultBox
				, searchText
				, isSearching
				, onSearchButton
				, onKeyDown
				, onKeyUp
				, onChange
				, ...p
			  } = this.props;
		if(showShortcut){
			return(
				<StaticShortcutSearchDropdown
					onRenderShortcutItem={this.renderShortcutItem}
					term={term}
					{...p}
				/>
			)
		}else if(showSearch){
			let chevron, height;
			if (isSearching) {
				chevron = "fa fa-spinner fa-spin";
			}
			if (showReplyResultBox) {
				height = 100;
			}
			return(
				<Fragment>
					<StyledSearchList height={p.height}>
							<StyledSearchListScroll height={height}>
								<SearchConversationErrand
									show={showReplyResultBox}
									searchData={searchData}
									searchResults={searchResults}
									searchText={searchText}
									{...p}
								/>
							</StyledSearchListScroll>
							<ul>
								{showReplyResultBox && <li className="more">
									<Link to={{pathname: `${process.env.PATH_PREFIX}v5/search`,state: { searchTerm: searchText }}}>{I('...more results and options')}</Link>
								</li>}
							</ul>
						<button type="submit" onClick={onSearchButton}><i className="icon-search"></i></button>
						<input
							autoFocus={true}
							value={searchText}
							className="search-field"
							type="text"
							name="searchText"
							data-qa-id="reply-search-input"
							placeholder={I('Search')}
							onKeyDown={onKeyDown}
							onKeyUp={onKeyUp}
							onChange={onChange}
							autoComplete="off"
						/>
						<i className={chevron} />
 					</StyledSearchList>
				</Fragment>
			)
		}else{
			return null;
		}
	}
}

class StaticShortcutSearchDropdown extends React.Component {
	constructor(props){
		super(props);
	}
	render() {
		let shortcutSearch = SHORTCUT_SEARCH
		const {
			onRenderShortcutItem
			, term
			, height
		} = this.props;
        return(
            <StyledShortcutMenu height={height}>
				<StyledShortcutMenuScroll>
					<ul className="ul-wrapper">
						{ onRenderShortcutItem(shortcutSearch, term) }
					</ul>
				</StyledShortcutMenuScroll>
            </StyledShortcutMenu>
        )
	}
}

class LinkSearch extends React.Component {
	handleClick = () => {
		let {sid} = this.props;
		this.props.handleClick(sid);
	}
	render = () => {
		let {body, active} = this.props, result;
		if(active){
			result = (<StyledLi className="active"><StyledButton onClick={this.handleClick} dangerouslySetInnerHTML={{__html: body }}></StyledButton></StyledLi>);
		}else{
			result = (<StyledLi><StyledButton onClick={this.handleClick} dangerouslySetInnerHTML={{__html: body }}></StyledButton></StyledLi>);
		}
		return result;
	}
}

class SearchConversationErrand extends React.Component {
	constructor(props){
		super(props);
	}
	render() {
		const {
			show
			, searchData
			, searchResults
			, searchText
			, ...p
		} = this.props;
		if(show){
			let sd = searchData, list = [], showTotal = 5, count = 0;
			if(sd && sd.length) {
				$.each(sd, (i,v) => {
					count++;
					if(count <= showTotal){
						let lst = v;
						lst.collaboration = {status: false};
						lst.fromName = v.from;
						lst.serviceName = v.channel;
						lst.areaName = v.area;
						lst.date = v.createdTime;
						let noSelect = false
						, selected = searchResults.opr[v.id] ? searchResults.opr[v.id].selected : false;
						list.push(<BriefSearchErrand errand={v} key={i} index={i} me={v.id}
								onClick={p.onClick} tzOffset={p.tzOffset}
								onSelect={p.onSelectOne} noSelect={noSelect} select={selected}
								onGetCollaborationInfo={p.onGetCollaborationInfo}
								collaborationInfo={p.collaborationInfo}
								canOffCollaborationLight={p.canOffCollaborationLight}
								onToggleLight={p.onToggleLight} clientAvatar={p.clientAvatar}
								currentOpened={p.currentOpened}
								onLoadPreview={p.onLoadPreview}
								previewActivated={p.previewActivated}
								previewData={p.previewData}
								showExactDayAndTime={p.showExactDayAndTime}
								term={searchText} />
							);
					}
				});
			}
			return list;
		}else{
			return null;
		}
	}
}

export const HideableReplySearch = onClickOutside(ReplySearch);