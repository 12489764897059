import React, { useCallback, useState, useEffect } from 'react'
import 'jquery-spellchecker'
import { CKEDITOR, I } from '../../common/globals'
import { useShowAndHide } from '../../hooks/toggle'
import { CtrlAssistDropdown, newDropdownUI } from '../../reactcomponents/Dropdown'
import Reload from '../../reactcomponents/Reload'

const Spellchecker = ({ editorId, langs, preferredLang }) => {
  const [show, onShow, onHide] = useShowAndHide()
  const [langCode, setLangCode] = useState(preferredLang)
  const [loading, setLoading] = useState(false)
  const handleSelectLang = useCallback(lang => {
    setLangCode(lang)
    if (!newDropdownUI) {
      onHide()
    }
  }, [onHide, setLangCode])
  const handleResetLang = useCallback(() => {
    setLangCode(preferredLang)
  }, [preferredLang, setLangCode])
  const handleToggle = useCallback(() => {
    if (show) {
      onHide()
    } else {
      onShow()
    }
  }, [onHide, onShow, show])
  const isInitializedSelection = useCallback(
    selected => selected === preferredLang,
    [preferredLang]
  )
  const handleSpellCheck = useCallback(() => {
    if (editorId) {
      CKEDITOR.instances[editorId].execCommand(
        'spellchecker',
        { lang: langCode }
      )
      var spellLoading = document.getElementsByClassName('spellchecker-suggestbox');
      if (spellLoading.length > 0) {
        setLoading(true);
        setTimeout(function () { setLoading(false); }, 3000);
      }
    }
  }, [editorId, langCode])

  useEffect(() => {
    var spellLoading = document.getElementsByClassName('spellchecker-suggestbox');
    if (spellLoading.length == 0) {
      setLoading(false);
    }
  });

  return (
    <div className='reply-links spellcheck-container'>
      <CtrlAssistDropdown
        customClass='spellcheck-dd'
        data={langs}
        id='spellcheckLangs'
        isInitializedSelection={isInitializedSelection}
        onReset={handleResetLang}
        onSelectItem={handleSelectLang}
        onToggle={handleToggle}
        open={show}
        selected={langCode}
        title={I('Spellcheck Language')}
      />
      <div className='reply-spellcheck'>
        {/* <a
          className='cke_button cke_button__spellchecker'
          title={I('Spellchecker')}
        >
          <span
            onClick={handleSpellCheck}
            className='cke_button_icon cke_button__spellchecker_icon spellchecker-icon'
          >
            &nbsp;
          </span>
        </a> */}
        <Reload
          data-qa-id="QA_reload_list"
          loading={loading}
          onClick={handleSpellCheck}
        />
      </div>
    </div>
  )
}

export default Spellchecker
