import React from 'react';

const ToggleSideBar = ({ mobileMode, ...props }) => {
	if(!mobileMode) {
		return <ToggleSideBarCollapsible {...props} />
	}
	return <ToggleSideBarBasic {...props} />
};

class ToggleSideBarBasic extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onMobile: false
		}
	}
	renderWrappedBtn(child) {
		let customClass = "toggle-sidebar ";
		if(this.props.customClass) {
			customClass += this.props.customClass;
		}
		return (
			<li className={customClass} onClick={this.handleOnClick}>
				{child}
			</li>
			)
	}
	handleOnClick = (e) => {
		e.preventDefault();
		this.props.onClick(e);
	}
	render() {
		const p = this.props;
		if(p.visible || p.mobileMode) {
			if(p.wrapped) {
				return (
					this.renderWrappedBtn(<a className='sidebar-trigger' data-sidebar-trigger data-qa-id={p["data-qa-id"] ? p["data-qa-id"] : p.id}><i className='icon-expand-sidebar'></i></a>)
				)
			}else {
				return (
					<button onClick={this.handleOnClick} className='sidebar-trigger' data-sidebar-trigger data-qa-id={p["data-qa-id"] ? p["data-qa-id"] : p.id} ><i className='icon-expand-sidebar'></i></button>
				)
			}
		}else {
			return null;
		}
	}
}

class ToggleSideBarCollapsible extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onMobile: false
		}
	}
	renderWrappedBtn(child) {
		let customClass = "toggle-sidebar ";
		if(this.props.customClass) {
			customClass += this.props.customClass;
		}
		let listStyle = {display: "inline-block"};
		return (
			<li className={customClass} style={listStyle} onClick={this.handleOnClick} >
				{child}
			</li>
			)
	}
	handleCollapse = (e) => {
		e.preventDefault();
		this.props.onCollapse(e);
	}
	handleOnClick = (e) => {
		e.preventDefault();
		this.props.onClick(e);
	}
	render() {
		const p = this.props;
		let showIcon = false;
		if(typeof p.visible !== "undefined" && p.visible == true){
			showIcon = true;
		}
		if(showIcon || p.mobileMode) {
			if(p.wrapped) {
				return (
						this.renderWrappedBtn(<a className='sidebar-trigger' title={I("Hide/show sidebar")} data-sidebar-trigger data-qa-id={p["data-qa-id"] ? p["data-qa-id"] : p.id}><i className={p.disableCollapse ? 'icon-expand-sidebar' : 'icon-close-sidebar'}></i></a>)
					)
			}else {
				if(p.disableCollapse){
					return (
						<button onClick={this.handleOnClick} className='sidebar-trigger' data-sidebar-trigger data-qa-id={p["data-qa-id"] ? p["data-qa-id"] : p.id} ><i className='icon-expand-sidebar'></i></button>
					)
				}else{
					return (
						<button title={p.collapsed ? I("Expand sidebar") : I("Collapse sidebar")} onClick={this.handleCollapse} className='sidebar-trigger' data-sidebar-trigger data-qa-id={p["data-qa-id"] ? p["data-qa-id"] : p.id} ><i className={p.collapsed ? 'icon-expand-sidebar' : 'icon-collapse-sidebar'}></i></button>
					)
				}
			}
		}else {
			return null;
		}
	}
}

export default ToggleSideBar;
