import React from 'react';
import { I } from '../../common/v5/config.js';
import DatePicker from 'react-datepicker';
import { AreaDropdown } from '../../reactcomponents/Dropdown';
import moment from 'moment';

const Button = ({value, className, btnClassName, onAction, disabled, ...props}) => {
	return <div className={className} >
			<button data-qa-id={"postpone-errand-btn-"+value}
				className={btnClassName}
  				disabled={disabled}
				type="button" onClick={onAction}>
				{value}
			</button>
        </div>;
};

class PostponeErrand extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			postponeDate : 0
			, showArea : false
			, selectedArea : props.currentArea.Id
			, dateError : false
			, areaError : false
		};
		this.handleClose = this.handleClose.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handlePostpone = this.handlePostpone.bind(this);
		this.onSetPostponeDate = this.onSetPostponeDate.bind(this);
		this.onSelectArea = this.onSelectArea.bind(this);
		this.toggleArea = this.toggleArea.bind(this);
	}
	componentDidUpdate(prevProps, prevState) {
		if((prevProps.inputs.postpone_errand_popup !==
			this.props.inputs.postpone_errand_popup) &&
			this.props.inputs.postpone_errand_popup){
			if(this.props.data != null && this.props.data.data != null){
				let currentArea = this.props.currentArea.id;
				let areas = this.filterPostponeAreas();
				if(areas.length == 1 && currentArea != areas[0].Id){
					currentArea = areas[0].Id;
				} else if(this.props.data.data.postponedate == 0 &&
					areas.length != 1){
					currentArea = 0;
				}
				this.setState({
				postponeDate: this.props.data.data.postponedate,
				selectedArea: currentArea});
			}
        }
    }
	onSetPostponeDate(dueDate){
		if(dueDate == null){
			this.setState({postponeDate: 0, dateError: false});
			return;
		}
		this.setState({postponeDate: dueDate.unix(), dateError: false});
	}
	handleClose = () =>{
		this.props.handleTogglePostponeErrand(false);
	}
	handleClear = () =>{
		this.props.handleSetPostponeDate(
			this.props.currentErrand.id, 0, 0,
			this.props.extData.data.errand_target_area_id);
		this.setState({postponeDate: 0, selectedArea: 0});
		this.props.handleTogglePostponeErrand(false);
	}
	onSelectArea = (areaId) =>{
		this.setState({selectedArea: areaId, areaError: false});
	}
	toggleArea(t, v) {
		this.setState({showArea: !v});
	}
	filterPostponeAreas = () => {
		var areas = [];
		$.each(this.props.wf.agentAreas.data.areas,(i,areaArr) => {
			$.each(areaArr.Areas, (j,v) => {
				if(v.HasPostpone == true){
					areas.push(v);
				}
			});
		});
		return areas;	
	}
	handlePostpone = () =>{
		if(this.state.postponeDate > 0 && this.state.selectedArea == 0){
			this.setState({areaError: true});
			return
		}
		if(this.state.postponeDate == 0 && this.state.selectedArea != 0){
			this.setState({dateError: true});
			return
		}
		let areaToReload = this.state.selectedArea;
		if(areaToReload == 0 && typeof this.props.extData.data !== 'undefined'
			&& this.props.extData.data.errand_target_area_id != 0){
			areaToReload = this.props.extData.data.errand_target_area_id;
		}
		var nowDate = moment();
		if(this.state.postponeDate < (Date.now()/1000)){
			this.setState({dateError: true});
			return;
		}
		this.props.handleSetPostponeDate(
			this.props.currentErrand.id,
			this.state.postponeDate,
			this.state.selectedArea,
			areaToReload);
		this.props.handleTogglePostponeErrand(false);
	}
	render() {
		const p = this.props;
		var divClass = "postpone errand popup"
		if(p.inputs.postpone_errand_popup == true){
			divClass = "postpone errand popup open"
		}
		var areas={};
		if(p.wf.agentAreas && p.wf.agentAreas.data) {
			areas = this.filterPostponeAreas();
		}
		var errDisplay = {display: "none"};
		var areaErrDisplay = {display: "none"};
		if(this.state.dateError == true){
			errDisplay={display: "block"};
		}
		if(this.state.areaError == true){
			areaErrDisplay={display: "block"};
		}
		var dateMoment = moment.unix(this.state.postponeDate);
		if(this.state.postponeDate == 0){
			dateMoment = null;
		}
		return(
			<div className={divClass}>
				<div className="postpone-inner popup-inner">
					<div className="popup-close" onClick={this.handleClose}>
						<i className="icon-times"></i>
					</div>
					<form>
						<div className="postpone-title-row">
							<label className="postpone-label">
								{I("Postpone errand")}
							</label>
						</div>
						<div className="postpone-date-row">
							<label className="postpone-label">
								{I("Select postpone date")}:&nbsp;
							</label>
							<DatePicker
								selected={dateMoment}
								onChange={this.onSetPostponeDate}
								showTimeSelect
		   						filterDate={(date) => {
									return moment().startOf('day') <= date;
								}}
								timeFormat="HH:mm"
								timeIntervals={15}
								dateFormat="MMMM DD, YYYY h:mm a"
								timeCaption="time"
							/>
						</div>
						<div className="postpone-date-error-row">
							<label className="postpone-error-text" style={errDisplay}>
								{I("Selected date cannot be before current time")}
							</label>
						</div>
						<div className="postpone-area-row">
							<label className="postpone-area-text">
								{I("Postpone to area:")}&nbsp;
							</label>
							<AreaDropdown
								title=""
								className="postpone-area-text"
								show={this.state.showArea}
								selected={this.state.selectedArea}
								id="postponeArea"
								data={areas}
								nested={null}
								textNoItemSelected={I("select an area")}
								idFields={{id: "Id", name: "Name"}}
								onToggle={this.toggleArea}
								onSelect={this.onSelectArea}
								readOnly={false}
							/>
						</div>
						<div className="postpone-date-error-row">
							<label className="postpone-error-text" style={areaErrDisplay}>
								{I("No area selected")}
							</label>
						</div>
						<div className="postpone-button-row">
							<Button btnClassName="btn-blue"
								value={I("Save")} className="postpone-button"
								disabled={false}
								onAction={this.handlePostpone} />
							<Button btnClassName="btn-blue"
								value={I("Clear")} 
								className="close-button"
								disabled={false}
								onAction={this.handleClear} />
						</div>
					</form>
				</div>
			</div>
		);
	}
};

export default PostponeErrand;
