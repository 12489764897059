import React from "react";
import Draggable from 'react-draggable';
import styled from 'styled-components'
import { SipDtmfKeypad, SIPTransferButton } from "./SipNotification";
import { SimpleDialPadCtnr } from '../../containers/call';
import { BTTN_CLEAR } from '../../reactcomponents/DialPad';
import {
	MP_BASIC_CALL
} from '../../common/v5/constants';
import {
	WARM_TRANSFER
	, COLD_TRANSFER
} from '../../common/v5/callConstants.js';

const allowEscToClear = [BTTN_CLEAR];

const StyledSIPDialerWrapper = styled.div`
	position: fixed;
	border-radius: 10px;
	border-style: solid;
	border-color: #cecece;
	border-width: 1px;
	background-color: white;
	z-index: 99999;
	right: 30%;
	top: 40%;
	width: ${props => props.withInput ? "350px" : "300px"};
	height: ${props => props.withInput ? "460px" : "300px"};
	box-shadow: 0 0 8px 0 rgb(0 0 0 / 35%);
`
const StyledSIPDialerWrapperEmbedded = styled.div`
	width: ${props => props.withInput ? "250px" : "300px"};
	height: 300px;
	margin-top: 10px;
`

const StyledSIPDialerInput = styled.div`
	padding: 0px 10px;
	width: 100%;
`

const StyledDialerCloseBtn = styled.div`
	padding: 5px 10px;
	display: inline-block;
	float: right;
`

const DialCloseBtn = ({onClose}) => {
	return(
		<StyledDialerCloseBtn>
			<span onClick={onClose}><i style={{fontSize: "12px", float: "right", cursor: "pointer"}} className="icon-times"></i></span>
		</StyledDialerCloseBtn>
	)
}

class SipDialer extends React.Component {
	constructor(props) {
		super(props);
		this.handleChangeInput = this.handleChangeInput.bind(this);
		this.handleChangeToInput = this.handleChangeToInput.bind(this);
		this.handleClearInput = this.handleClearInput.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleExternalWarmTransfer = this.handleExternalWarmTransfer.bind(this);
		this.handleExternalColdTransfer = this.handleExternalColdTransfer.bind(this);
		this.state = { number: ''}
	}
	handleChangeInput(e) {
		this.setState({ number: e.target.value});
	}
	handleChangeToInput(v) {
		this.setState({ number: v});
	}
	handleClearInput() {
		this.setState({ number: ''})
	}
	handleClose() {
		this.props.onClose();
	}
	handleDelete() {
		let oldValue = this.state.number;
		if(typeof oldValue !== 'undefined' && oldValue != null &&
			oldValue.length >0){
			let newValue = oldValue.slice(0, -1);
			this.setState({number: newValue});
		}
	}
	handleExternalWarmTransfer() {
		let callString = "", extContact = this.state.number, isManual = false;
		let cipherKey = this.props.mCipherKey, eid = this.props.errandId;
		if(this.props.manualEid != 0 && !this.props.sipMakeCallCurrentErrand) {
			isManual = true;
			eid = this.props.manualEid;
		} else {
			if(this.props.sipMakeCallCurrentErrand) {
				eid = this.props.errandId;
				cipherKey = this.props.currentCipherKey;
			}
		}
		if(extContact != ""){
			if(extContact.startsWith("sip:") == false){
				callString = "sip:";
			}
			callString += extContact;
			if(extContact.includes("@") == false){
				callString += "@" + this.props.sipServerName;
			}
		} else {
			return;
		}
		if((!features["sip.auto-create-errand"] && this.props.manualEid === 0) && !this.props.sipMakeCallCurrentErrand) {
			eid = 0;
		}
		 this.props.handleWarmTransferToExternal(eid, 0,
			callString, this.props.sipCallConn, this.props.displayId,
			cipherKey, isManual);
		this.handleClearInput();
		this.props.onClose();
		this.props.onCloseContactBook();
	}
	handleExternalColdTransfer() {
		let callString = "", extContact = this.state.number, isManual = false;
		let cipherKey = this.props.mCipherKey, eid = this.props.errandId;
		if(this.props.manualEid != 0 && !this.props.sipMakeCallCurrentErrand) {
			isManual = true;
			eid = this.props.manualEid;
		} else {
			if(this.props.sipMakeCallCurrentErrand) {
				eid = this.props.errandId;
				cipherKey = this.props.currentCipherKey;
			}
		}
		if(extContact != ""){
			if(extContact.startsWith("sip:") == false){
				callString = "sip:";
			}
			callString += extContact;
			if(extContact.includes("@") == false){
				callString += "@" + this.props.sipServerName;
			}
		} else {
			return;
		}
		if((!features["sip.auto-create-errand"] && this.props.manualEid === 0) && !this.props.sipMakeCallCurrentErrand) {
			eid = 0;
		}
		this.props.handleColdTransferToExternal(eid, 0,
			callString, this.props.sipCallConn, this.props.displayId,
			cipherKey,
			isManual,
			this.props.extRefId);
		this.handleClearInput();
		this.props.onClose();
		this.props.onCloseContactBook();
	}
	render() {
		const {sipShowDtmfKeypad, sipCallConn, sendDtmfToTransferAgent, sipCallTransferStatus, sipShowExtTransferKeypad, dialerClass} = this.props;
		let disabledBtn = false;
		if (sipShowDtmfKeypad) {
			return (
				<Draggable axis="both" bounds={"parent"}>
					<StyledSIPDialerWrapper>
						{
							<SipDtmfKeypad
								sipCallConn={sipCallConn}
								sendDtmfToTransferAgent={sendDtmfToTransferAgent}
								sipCallTransferStatus={sipCallTransferStatus}
							/>
						}
					</StyledSIPDialerWrapper>
				</Draggable>
			);
		} else if(sipShowExtTransferKeypad) {
			if(this.state.number === '') {
				disabledBtn = true;
			}
			return (
				<StyledSIPDialerWrapperEmbedded withInput className="sip-transfer-dialer-wrapper">
					<div hidden style={{padding: "0px", width: "100%", margin: "5px", fontSize: "15px", fontWeight: "600", color: "#6d6d6d"}}>
						{I("Transfer call")}
						<DialCloseBtn onClose={this.handleClose}/>
					</div>
					<div className="sip-dialer-input-keys-wrapper">
						<StyledSIPDialerInput className="sip-transfer-input">
							<input
								type="text"
								id="mceToRecipient"
								placeholder={I('Enter phone number')}
								value={this.state.number}
								onChange={this.handleChangeInput}
							/>
							<i className="icon-delete"
								onClick={this.handleDelete}
							/>
						</StyledSIPDialerInput>
						<SimpleDialPadCtnr
							allowedButtonsChangeValue={allowEscToClear}
							hidden={false}
							value={this.state.number}
							onChange={this.handleChangeToInput}
							sipCallStatus={this.props.sipCallStatus}
							type={MP_BASIC_CALL}
							embedded={false}
							withClose={false}
							className={dialerClass}
						/>
						<div className="sip-transfer-mode" style={{display: "flex", justifyContent: "center"}}>
							{ this.props.sipHideWarmTransfer !== true &&
							<div className="attended-button" style={{marginRight: "10px"}}>
								<SIPTransferButton
									id="button-unattended"
									onClick={this.handleExternalWarmTransfer}
									text={I("Attended transfer")}
									type={WARM_TRANSFER}
									disabled={disabledBtn} />
							</div>
							}
							{ this.props.sipHideColdTransfer !== true &&
							<div className="unattended-button">
								<SIPTransferButton
									id="button-unattended"
									onClick={this.handleExternalColdTransfer}
									text={I("Unattended transfer")}
									type={COLD_TRANSFER}
									disabled={disabledBtn} />
							</div>
							}
						</div>
					</div>
				</StyledSIPDialerWrapperEmbedded>
			);
		} else {
			return null;
		}
	}
}

export default SipDialer;
