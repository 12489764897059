import React, {
	PureComponent
	, useState
	, useRef
} from 'react';
import classNames from 'classnames';
import { I } from '../../common/v5/config';
import { QRCode } from 'react-qrcode-logo';

const downloadCodeLabel = I("Download Code");

export default class QRWrapper extends PureComponent{
	constructor(props) {
		super(props);
		this.downloadCode = this.downloadCode.bind(this);
	} 
	
	downloadCode(e) {
		e.preventDefault();
		const canvas = document.getElementsByTagName("canvas")[0];
		const img = canvas.toDataURL("image/png");
		const a = document.createElement ("a");
		a.href = img;
		a.download = "qrcode.png";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	render() {
		let hasDownload = false
		if(typeof this.props.download !== "undefined" &&
			this.props.download == true){
				hasDownload = true;
		}
		return (
			<div>
				<QRCode
					value={this.props.baseUrl}
					enableCORS={true}
					qrStyle={"fluid"}
					id={"qrcode-wrapper"}
				/>
				<div>
					{hasDownload &&
					<button
						className={"btn-blue"}
						onClick={this.downloadCode}
					>
						{downloadCodeLabel}
					</button>
					}
				</div>
			</div>
		)
	}
}
