import React, { Fragment } from 'react';
import each from 'lodash/each';
import {
	CL_REPORT,
	RC_LIMITS,
	RL_LEFT_PANE,
	RL_RIGHT_PANE,
	RL_BOTTOM_PANE,
	RELATIVE_DATE_TYPES_WITH_CUSTOM,
	RDT_CUSTOM,
	DT_ABSOLUTE,
	DT_RELATIVE
} from '../../common/v5/constants';
import { useCallbackWithValue } from '../../hooks/callback';
import Reload from '../../reactcomponents/Reload';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import { ChartCtnr } from '../../containers/chart';
import ReportParameters from './ReportParameters';
import classNames from 'classnames';
import PopupPage from '../../reactcomponents/PopupPage';
import { Calender } from '../../reactcomponents/DatePicker';
import {
	OrderSingleSelect,
} from '../../reactcomponents/Dropdown';

const Settings = ({ paramId: { id }, onParametersChange, ...props }) => (
	<ReportParameters
		onParametersChange={useCallbackWithValue(id, onParametersChange)}
		{...props}
	/>
);

export const withChartRenderer = Wrapper => ({
	data
	, position
	, layout
	, limits
	, noAutoFetchOnLoad
	, ...props
}) => {
	if (!data || !data[0]) {
		return null;
	}
	const limit = limits[position];
	if (limit > 1 && data.length > 1) {
		const charts = [];
		each(data, (v, i) => {
			if (i >= limit) {
				return false;
			}
			charts.push(
				<ChartCtnr {...props}
					key={i}
					id={v}
					layout={layout}
					position={position}
					index={i}
					noAutoFetchOnLoad={noAutoFetchOnLoad}
				/>
			);
		});
		return <Wrapper>{charts}</Wrapper>;
	}
	return (
		<ChartCtnr {...props}
			id={data[0]}
			layout={layout}
			position={position}
			index={0}
			noAutoFetchOnLoad={noAutoFetchOnLoad}
		/>
	);
};

const ChartRenderer = withChartRenderer("div");

const ReportChartRenderer = props => (
	<ChartRenderer {...props}
		layout={CL_REPORT}
		limits={RC_LIMITS}
		noAutoFetchOnLoad={true}
		hideTitleAndLink={true}
	/>
);

const withReportChart = (className, position) => props => (
	<div className={className}>
		<ReportChartRenderer position={position} {...props} />
	</div>
);

export const LeftChart = withReportChart("top-left", RL_LEFT_PANE);

const RightChart = withReportChart("top-right", RL_RIGHT_PANE);

const BottomChart = withReportChart("bottom", RL_BOTTOM_PANE);

const ReportContent = ({ leftData, rightData }) => (
	<div className="inner-content">
		<LeftChart data={leftData} calculateHeight />
		<RightChart data={rightData} square />
	</div>
);

const withReportTable = Component => ({ data, onCellClick, ...props }) => (
	<Component
		data={data}
		onTableCellClick={onCellClick}
		useExpandable={true}
		showSubTableTotal={false}
		{...props}
	/>
);

const ReportTable = withReportTable(BottomChart);

const selectAllAffectField = "completeResult";

class Header extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleReload = this.handleReload.bind(this);
		this.handleToggleSelectAll = this.handleToggleSelectAll.bind(this);
	}
	handleReload(e) {
		this.props.onReload(this.props.paramId.id);
	}
	handleToggleSelectAll(e) {
		this.props.onParametersChange(
			this.props.paramId.id
			, selectAllAffectField
			, !this.props.parameters[selectAllAffectField]
		);
	}
	render() {
		const {
				className
				, loading
				, onParametersChange
				, options
				, parameters
				, paramId
				, reportAreas
				, features
			} = this.props
			;
		return (
			<div className={className}>
				<Settings
					className={"report-parameters"}
					options={options}
					paramId={paramId}
					parameters={parameters}
					onParametersChange={onParametersChange}
					reportAreas={reportAreas}
					features={features}
				/>
				<SquareCheckbox
					onClick={this.handleToggleSelectAll}
					checked={parameters[selectAllAffectField]}
					label={I("Show all")}
				/>
				<Reload
					enableLabel={true}
					loading={loading}
					onClick={this.handleReload}
				/>
			</div>
		);
	}
}

//popup calendar comparison
const calenderTitleStyle = {cursor: 'pointer' }; //{cursor: 'pointer', color: '#0c87f7'}; //TODO: Remove this
const calenderTitleStyle1 = {cursor: 'pointer', display: 'none'};
export class CompareStat extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleToggleDateType = this.handleToggleDateType.bind(this);
		this.handleToggleCompareDateType = this.handleToggleCompareDateType.bind(this);
		this.handleChangeDefaultDateFrom = this.handleChangeDefaultDateFrom.bind(this);
		this.handleChangeDefaultDateTo = this.handleChangeDefaultDateTo.bind(this);
		this.handleChangeDefaultDateType = this.handleChangeDefaultDateType.bind(this);
		this.handleChangeDefaultRelDate = this.handleChangeDefaultRelDate.bind(this);
		this.handleChangeCompareDateFrom = this.handleChangeCompareDateFrom.bind(this);
		this.handleChangeCompareDateTo = this.handleChangeCompareDateTo.bind(this);
		this.handleChangeCompareDateType = this.handleChangeCompareDateType.bind(this);
		this.handleChangeCompareRelDate = this.handleChangeCompareRelDate.bind(this);
		this.handleLoadCalendar = this.handleLoadCalendar.bind(this);

		this.handleGenerateCompare = this.handleGenerateCompare.bind(this);
		this.closePopup = this.closePopup.bind(this);
 
		this.state = {
			toggleDateType: false,
			toggleCompareDateType: false,
			dateType: 2,
			dateCompareFrom: "2020/08/28",
			dateCompareTo: "2020/08/28",
			showDefaultOpt: false,
			disableCalendar: true,
			disableCalendarCompare: true,
			popCalendar: false
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.parameters.relativeDateType !== this.props.parameters.relativeDateType) {
			if (this.props.parameters.relativeDateType === RDT_CUSTOM) {
				this.setState({disableCalendar: false});
			}else {
				this.setState({disableCalendar: true});
				this.props.onParametersChange(this.props.paramId, 'startTime', this.props.defCurTime);
				this.props.onParametersChange(this.props.paramId, 'endTime', this.props.defCurTime);
			}
		}
	}
	handleToggleDateType(t) {
		this.setState({ toggleDateType: !this.state.toggleDateType });
	}
	handleToggleCompareDateType(t) {
		this.setState({ toggleCompareDateType: !this.state.toggleCompareDateType });
	}
	handleChangeDefaultDateFrom(d) {
		this.props.onParametersChange(this.props.paramId, 'startTime', d);
	}
	handleChangeDefaultDateTo(d) {
		this.props.onParametersChange(this.props.paramId, 'endTime', d);
	}
	handleChangeDefaultDateType(d) {
		this.props.onParametersChange(this.props.paramId, 'dateType', d);
	}
	handleChangeDefaultRelDate(d) {
		if(d == RDT_CUSTOM) {
			this.props.onParametersChange(this.props.paramId, 'dateType', DT_ABSOLUTE);
			this.setState({disableCalendar: false});
		}else {
			this.props.onParametersChange(this.props.paramId, 'dateType', DT_RELATIVE);
			this.setState({disableCalendar: true});
		}
		this.props.onParametersChange(this.props.paramId, 'relativeDateType', d);
	}
	handleChangeCompareDateFrom(d) {
		this.props.onParametersChange(this.props.compareParamId, 'startTime', d);
	}
	handleChangeCompareDateTo(d) {
		this.props.onParametersChange(this.props.compareParamId, 'endTime', d);
	}
	handleChangeCompareDateType(d) {
		this.props.onParametersChange(this.props.compareParamId, 'dateType', d);
	}
	handleLoadCalendar() {
		this.setState({ popCalendar: !this.state.popCalendar });
	}
	handleChangeCompareRelDate(d) {
		if(d == RDT_CUSTOM) {
			this.props.onParametersChange(this.props.compareParamId, 'dateType', DT_ABSOLUTE);
			this.setState({disableCalendarCompare: false});
		}else {
			this.props.onParametersChange(this.props.compareParamId, 'dateType', DT_RELATIVE);
			this.setState({disableCalendarCompare: true});
		}
		this.props.onParametersChange(this.props.compareParamId, 'relativeDateType', d);
	}
	closePopup() {
		if(this.props.onClose){
			this.props.onClose(this.props.paramId.id, 'showCompare', false);
		}
	}
	handleGenerateCompare() {
		this.props.onReload(this.props.paramId.id);
		this.props.onReload(this.props.compareParamId.id);
		this.setState({ popCalendar: !this.state.popCalendar });
	}
	render() {
		const {
			paramId, parameters, compareParameters, leftPanels, rightPanels, onToggle
		} = this.props;
		let calenderType, mobile;
		return (
			<PopupPage
				data-qa-id="compare-stat-backdrop-popup"
				extraClass="compare-stat-backdrop"
				innerClass="compare-stat"
				show={parameters.showCompare}
				onClose={this.closePopup}
			>
				<div className="compare-stat-body">
					<div className="header-title">{I("Compare data")}</div>
					<div className="stats-compare-data-inner">
						{/**left side */}
						<div className="date-set-default">
							{/**Todo: make into a re-usable component */}
							<div className="date-range-container">
								<div className="row-range" onClick={this.handleLoadCalendar}>
									<span>{I("Date range: ")}</span>
									<div className="row-compare">
										{ this.props.parameters.relativeDateType === RDT_CUSTOM ?
											<Fragment>
											<div className="date-set-from">
												<span style={calenderTitleStyle} onClick={onToggle}>
													{parameters.startTime}
												</span>
											</div>
											<span className="separator">-</span>
											<div className="date-set-to">
												<span style={calenderTitleStyle} onClick={onToggle}>
													{parameters.endTime}
												</span>
											</div>
											</Fragment> :
											<span>{RELATIVE_DATE_TYPES_WITH_CUSTOM[parameters.relativeDateType]}</span>
										}
									</div>
								</div>
								<div className="date-select-dropdown" hidden={!this.state.popCalendar}>
									<div className="row-range">
										<span>{I("Date range:")}</span>
										<div>
											<OrderSingleSelect data={RELATIVE_DATE_TYPES_WITH_CUSTOM} show={this.state.toggleDateType} title={""} selected={parameters.relativeDateType} onToggle={this.handleToggleDateType} onSelect={this.handleChangeDefaultRelDate} />
										</div>
									</div>
									<div className="row-compare" hidden={this.state.disableCalendar}>
										<div className="date-set-from">
											<Calender
												selected={parameters.startTime}
												id={calenderType}
												onChange={this.handleChangeDefaultDateFrom}
												mobile={mobile}
												disabled={this.state.disableCalendar}
											/>
										</div>
										<span className="separator">-</span>
										<div className="date-set-to">
											<Calender
												selected={parameters.endTime}
												id={calenderType}
												onChange={this.handleChangeDefaultDateTo}
												mobile={mobile}
												disabled={this.state.disableCalendar}
											/>
										</div>
									</div>
									<div className="row-range">
										<span>{I("Comparing to:")}</span>
										<div>
											<OrderSingleSelect data={RELATIVE_DATE_TYPES_WITH_CUSTOM} show={this.state.toggleCompareDateType} title={""} selected={compareParameters.relativeDateType} onToggle={this.handleToggleCompareDateType} onSelect={this.handleChangeCompareRelDate} />
										</div>
									</div>
									<div className="row-compare" hidden={this.state.disableCalendarCompare}>
										<div className="date-set-compare-from">
											<Calender
												selected={compareParameters.startTime}
												//id={calenderType}
												onChange={this.handleChangeCompareDateFrom}
												disabled={this.state.disableCalendarCompare}
											/>
										</div>
										<span className="separator">-</span>
										<div className="date-set-compare-to">
											<Calender
												selected={compareParameters.endTime}
												//id={calenderType}
												onChange={this.handleChangeCompareDateTo}
												//mobile={mobile}
												disabled={this.state.disableCalendarCompare}
											/>
										</div>
									</div>
									<div style={{cursor: "pointer", marginRight: "5px", float: "right"}} onClick={this.handleGenerateCompare}>{I("Apply")}</div>
								</div>
							</div>
							<div className="generated-report">
								<LeftChart data={leftPanels} noUnLoad={true} />
							</div>
						</div>
						{/**right side */}
						<div className="date-set-compare">
							<div className="date-range-container">
								<div className="row-range">
									<span>{I("Comparing to: ")}</span>
									{ this.props.compareParameters.relativeDateType === RDT_CUSTOM ?
										<Fragment>
										<span style={calenderTitleStyle} onClick={onToggle}>
												{compareParameters.startTime}
										</span>
										<span className="separator">-</span>
										<span style={calenderTitleStyle} onClick={onToggle}>
												{compareParameters.endTime}
										</span>
										</Fragment> :
										<span>{RELATIVE_DATE_TYPES_WITH_CUSTOM[compareParameters.relativeDateType]}</span>
									}
								</div>
							</div>
							<div className="generated-report">
								<LeftChart data={rightPanels} />
							</div>
						</div>
					</div>
				</div>
			</PopupPage>
		)
	}
}

class Report extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleTableCellClick = this.handleTableCellClick.bind(this);
	}
	handleTableCellClick(...args) {
		const { reportId, onTableCellClick, parameters } = this.props;
		if (typeof onTableCellClick === "function") {
			onTableCellClick(reportId, parameters, ...args);
		}
	}
	render() {
		const {
			leftPanels
			, rightPanels
			, bottomPanels
			, loading
			, paramId
			, options
			, parameters
			, onParametersChange
			, onReload
			, reportAreas
			, features
			, ...p
		} = this.props;
		return (
			<div className='statistics-report'>
				<Header
					className={classNames('header',{'mobile': p.mobileView, 'show': p.show})}
					loading={loading}
					onParametersChange={onParametersChange}
					onReload={onReload}
					options={options}
					parameters={parameters}
					paramId={paramId}
					reportAreas={reportAreas}
					features={features}
				/>
				<div id='report-content' className="report-wrapper">
					<ReportContent
						leftData={leftPanels}
						rightData={rightPanels}
					/>
					<ReportTable
						data={bottomPanels}
						onCellClick={this.handleTableCellClick}
					/>
				</div>
			</div>
		);
	}
}

export default Report;
