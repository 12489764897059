// popup that allow insertion of protected reply
import React from "react";
import { ADMIN_INSERT_PROTECT_CKE_HEIGHT } from "../../common/v5/constants";
import { I } from "../../common/v5/config";
import { doNothing } from "../../reactcomponents/common";
import { createWithPropAttachedCallback } from "../../reactcomponents/hocs";
import { PopupWithButtons } from "../../reactcomponents/PopupPage";
import Ckeditor from "./Ckeditor";

const idBase = "popupInsertProtect";

class PopupInsertProtectBase extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleCkeditorChange = this.handleCkeditorChange.bind(this);
		this.handleInsert = this.handleInsert.bind(this);
		this.buttons = [
			{
				id: "insert"
				, text: I("Insert")
				, onClick: this.handleInsert
			}
		];
		this.state = {
			content: ""
		};
	}
	handleCkeditorChange(e) {
		this.setState({content: e.editor.getData()});
	}
	handleInsert(e) {
		const { onClose, onInsert } = this.props;
		if (typeof onInsert !== "function") {
			return;
		}
		onInsert(this.state.content);
		this.setState({content: ""});
		if (typeof onClose === "function") {
			onClose();
		}
	}
	render() {
		const {
				archiveImgs
				, ckeditorSettings
				, langSrc
				, onClose
				, show
				, ...props
			} = this.props
			, { fontFamily, fontSize } = ckeditorSettings
			;
		return (
			<PopupWithButtons
				data-qa-id={props["data-qa-id"]}
				buttons={this.buttons}
				show={show}
				onClose={onClose}
			>
				<Ckeditor
					id={idBase}
					data-qa-id={idBase}
					defaultFontFamily={fontFamily}
					height={ADMIN_INSERT_PROTECT_CKE_HEIGHT}
					defaultFontSize={fontSize}
					defaultContent={this.state.content}
					spellLanguages={langSrc}
					fileArchiveImages={archiveImgs}
					onKeydown={doNothing}
					onChange={this.handleCkeditorChange}
				/>
			</PopupWithButtons>
		);
	}
};

const PopupInsertProtect = createWithPropAttachedCallback(
	"ckeditor"
	, "onInsert"
	, true
)(PopupInsertProtectBase);

export default PopupInsertProtect;
