import React, { Component, PureComponent } from 'react';
import {Popover, PopoverHeader, PopoverBody} from 'reactstrap';
import {I} from '../../common/v5/config.js';
import { Tooltip } from 'reactstrap';

class SitePath extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			tooltipOpen: false
		};
		this.toggleTooltip=this.toggleTooltip.bind(this);
	}
	formatRoundTime = (s) =>{
		var r = Math.floor(s/3600);
			if(r<=0){
				r = Math.floor(s/60);
				if(r<=0){
 					return s + ' sec';
				}
				return r + ' min';
			}
		return r + ' h';
	}
	toggleTooltip() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}
	render = () => {
		let p=this.props;
		let visitorPath = p.visitorPath;
		let tooltipContent = "what?"
		let delay=500;
		let thisId = "SitePath-"+this.props.id;
		return(
			<span className="site-path">
				<div id={thisId}>
					<a style={{color: '#0c87f7'}} href="#">
					{visitorPath.path}
					</a>
				</div>
				<span>({this.formatRoundTime(p.lapsedTime)})</span>
				<Tooltip className="visitor-popup"
					placement={"bottom-start"}
					autohide={false}
					hideArrow={true}
					isOpen={this.state.tooltipOpen}
					target={thisId}
					toggle={this.toggleTooltip}
					delay={{ show: delay, hide: 250 }}
					container="body"
				>
					<div>
						{visitorPath.path}
					</div>
						<iframe className="visitor-iframe"
							src={visitorPath.path} ></iframe>
				</Tooltip>
			</span>
		);
	}
}

class VisitorPathWindow  extends PureComponent {
	constructor(props) {
		super(props);
	}
	formatTimeHMS = (s) =>{
		var h = Math.floor(s/3600);
		var r = s%3600;
		if(r>0){
			var m = Math.floor(r/60);
			s = r%60;
		}
		return {hour:h,min:m,sec:s};
	}
	pathTime = (cTime) =>{
		var prevTime = Math.round(new Date().getTime()/1000);
		let lapsed = prevTime -cTime;
		if(lapsed < 0){
			lapsed = 0;
		}
		return lapsed;
	}
	getTimeString = (totalTime) => {
		let ts = this.formatTimeHMS(totalTime);
		let timeString = " ";
		if(ts.hour > 0){
			timeString = timeString + ts.hour + " h";
		}
		if(ts.min > 0 ){
			timeString += ", ";
		}
		timeString = timeString + ts.min + " min, " + ts.sec + " sec";
		return timeString;
	}
	render = () => {
		let p=this.props;
		let visitorPaths = p.chat.visitorPaths;
		let rows = [];
		let totalTime = 0;
		if(visitorPaths){
			for(let i=0; i < visitorPaths.length; i ++){
				let lapsed = this.pathTime(visitorPaths[i].time);
				totalTime += lapsed;
				let aPath = (
					<SitePath key={visitorPaths[i].id}
						visitorPath={visitorPaths[i]}
						id={visitorPaths[i].id}
						lapsedTime ={lapsed}
					/>
				);
				rows.push(aPath);
			}
		}
		let visitorName = "";
		if(p.chat && p.chat.errand && p.chat.errand.data &&
			p.chat.errand.data.fromName){
			visitorName = p.chat.errand.data.fromName;
		}
		let timeString = this.getTimeString(totalTime);

		return(
			<div className="visitor-block" 
				style={{display:(p.showVisitorPath? "block":"none")}}>
				<div className="ignore-react-onclickoutside close-visitor" onClick={p.closeVisitorPath}>
					<a href="#">
						<i className="icon-times" style={{color: 'white'}}></i>
					</a>
				</div>
				<div className="visitor-title">Visitor path for {visitorName}
				</div>
				<div className="vpath-heading">SITE PATH:</div>
				<div className="visitor-rows">
					{rows}
				</div>
				<div className="vpath-heading total-time">TOTAL TIME ON SITE:</div>
				<div>{timeString}</div>
			</div>
		);
	}
}

export default VisitorPathWindow
