import React from 'react';
import { Link } from 'react-router-dom';
import { ANM_TAB_NOTIFICATION,ANM_TAB_ANNOUNCEMENT, PF_TIMESTAMP } from '../../common/v5/constants';
import { getNotificationIcon, getNotificationMessage } from '../../common/v5/helpers';
import { readNotification, unreadNotification, dismissNotification } from '../../common/v5/socket';
import Moment from '../../reactcomponents/Moment';
import PopupPage from "../../reactcomponents/PopupPage";
import { withCancellableOpenErrand } from '../../containers/hocs';
import {
	ACCOUNTS_PATH_SLICE_V5,
	ACCOUNTS_PATH_SLICE
} from '../../common/v5/constants';

class NotificationList extends React.Component {
	handleDismissNotification(e, id) {
		e.stopPropagation();
		dismissNotification(id);
	}
	handleReadNotification(id, msgText, linkTo, linkPage, msg) {
		let items = this.props.data;
		if (items[id] && !items[id].read) {
			readNotification(id);
		}
		this.props.onClose();

		if (linkPage === "/messages") {
			this.props.openMessage(linkTo);
		} else if (linkPage === "/statistics") {
			this.props.openStatistics(linkTo);
		} else if (linkTo) {
			let featureOpenLastErrandInThread = features["external-experts.notification-link-to-last-errand"];
			if (featureOpenLastErrandInThread && msgText == 'MsgCollaborationReply') {
				this.props.openLastErrandInThread(linkTo);
			} else if (msgText == 'MsgAccountsErr') {
				this.props.openAccounts(linkTo, linkPage);
			} else {
				this.props.openErrand(linkTo);
			}
		} else if (cflag.IsActive ("2023-09-20.CEN-109.announcement") && msgText == "MsgAnnouncement") {
			this.props.openAnnouncement(msg.id);
		}
	}
	handleUnreadNotification(e, id) {
		e.stopPropagation();
		unreadNotification(id);
	}
	handleReadAll(e) {
		e.stopPropagation();
		if (cflag.IsActive("2023-09-20.CEN-109.announcement") ) {
			let announcement = (this.props.tab === ANM_TAB_ANNOUNCEMENT)
			readNotification(-1, announcement);
		} else {
			readNotification(-1);
		}
	}
	handleClearAll(e) {
		if (cflag.IsActive("2023-09-20.CEN-109.announcement") ) {
			let announcement = (this.props.tab === ANM_TAB_ANNOUNCEMENT)
			dismissNotification(-1, announcement);
		} else {
			dismissNotification(-1);
		}
		this.props.onClose();
	}
	renderUnreadOption(id) {
		return (
			<div className="notification-list-item-action">
				<button className="remove" onClick={(e) => this.handleUnreadNotification(e, id)}>
					<i className="far fa-bell"></i>
				</button>
			</div>
		);
	}
	render() {
		let items = this.props.data
			, reversedData = []
			, notificationMessages = []
			, tab = this.props.tab;

		for (let key in items) {
			reversedData.push(items[key]);
		}
		for (let i = reversedData.length - 1; i >= 0; i--) {
			let item = reversedData[i]
			let msg = item.msg;
			let messageClass = (item.read) ? "" : "unread";
			let linkPage = msg.page ? msg.page : "";
			let pathName = process.env.PATH_PREFIX + "v5" + linkPage;
			let linkTo = msg.linkTo
			if (msg.text == "MsgAccountsErr") {
				pathName = process.env.PATH_PREFIX + ACCOUNTS_PATH_SLICE_V5[linkPage] + "/" + msg.linkTo;
				linkTo = linkPage
				linkPage = pathName
			}
			if (cflag.IsActive("2023-09-20.CEN-109.announcement")) {
				if ( ( tab == ANM_TAB_ANNOUNCEMENT && msg.text == "MsgAnnouncement") ||
				     ( tab == ANM_TAB_NOTIFICATION && msg.text != "MsgAnnouncement") ) {
					notificationMessages.push((
						<li key={i} className="notification-list-item">
							<Link className="notification-list-link" key={"notices-" + i} to={{ pathname: pathName }}
										onClick={() => this.handleReadNotification(item.id, msg.text, linkTo, linkPage, msg)}>
								<div className="notification-list-item-icon">
									{getNotificationIcon(msg.text)}
								</div>
								<div className="notification-list-item-text">
									<div className={messageClass}>
										{getNotificationMessage(msg)} <span className="datetime">
											<Moment
												date={item.sent}
												parseFormat={PF_TIMESTAMP}
												timezoneOffset={this.props.agentTimezoneOffset}
											/>
										</span>
									</div>
								</div>
							</Link>
							{item.read && this.renderUnreadOption(item.id)}
							<div className="notification-list-item-action">
								<button className="remove" onClick={(e) => this.handleDismissNotification(e, item.id)}>
									<i className="icon-times"></i>
								</button>
							</div>
						</li>
					));
				}
			} else {
				notificationMessages.push((
					<li key={i} className="notification-list-item">
						<Link className="notification-list-link" key={"notices-" + i} to={{ pathname: pathName }}
								onClick={() => this.handleReadNotification(item.id, msg.text, linkTo, linkPage, msg)}>
							<div className="notification-list-item-icon">
								{getNotificationIcon(msg.text)}
							</div>
							<div className="notification-list-item-text">
								<div className={messageClass}>
									{getNotificationMessage(msg)} <span className="datetime">
										<Moment
											date={item.sent}
											parseFormat={PF_TIMESTAMP}
											timezoneOffset={this.props.agentTimezoneOffset}
										/>
									</span>
								</div>
							</div>
						</Link>
						{item.read && this.renderUnreadOption(item.id)}
						<div className="notification-list-item-action">
							<button className="remove" onClick={(e) => this.handleDismissNotification(e, item.id)}>
								<i className="icon-times"></i>
							</button>
						</div>
					</li>
				));
			}
		}
		let notificationAction = null;
		if (this.props.noticeCount > 0) {
			notificationAction = <div className="notification-list-action">
				<div className="notification-list-action-link" onClick={this.handleReadAll}>
					{I('Read all')}
				</div>
			</div>;
		} else if (notificationMessages.length > 0) {
			notificationAction = <div className="notification-list-action">
				<div className="notification-list-action-link" onClick={() => this.handleClearAll()}>
					{I('Clear all')}
				</div>
			</div>;
		}
		let title ="Notifications"
		if (cflag.IsActive("2023-09-20.CEN-109.announcement")) {
			if ( tab == ANM_TAB_ANNOUNCEMENT ) {
				title = "Announcements"
			}
		}
		return (
			<PopupPage
				data-qa-id="notification-list"
				show={this.props.show}
				onClose={this.props.onClose}
				innerClass="notification-list">
				<div className="notification-list-sidebar">
					<div className="popup-title">
						<h2>{I(title)}</h2>
					</div>
				</div>
				<div className="notification-list-content">
					<div className="notification-list-mesages">
						{notificationAction}
						<ul>
							{notificationMessages}
						</ul>
					</div>
				</div>
			</PopupPage>
		)
	}
}
export default withCancellableOpenErrand(NotificationList);
