import React from 'react';
import classNames from 'classnames';
import update from 'immutability-helper';
import TagsInput from 'react-tagsinput';
import Autosuggest from 'react-autosuggest';
import {
	RC_EMAIL,
	RC_SMS,
	RC_VOICE
} from '../../common/v5/constants';
import {
	isValidEmail,
	isValidMultiEmails,
	isValidMultiPhones,
	isValidPhoneNo,
	isValidFixedLengthNumeric,
	isValidMultiFixedLengthNumeric,
	validEmailRegex,
	validMultiEmailsRegex,
	validMultiPhonesRegex,
	validPhoneRegex,
	validFixedLengthNumericRegex,
	validMultiFixedLengthNumericRegex
} from '../../common/v5/helpers';

const getSuggestions = (value, data) => {
	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;
	return inputLength < 2 ? [] : data.filter(info =>
		info.value.toLowerCase().indexOf(inputValue) > -1
	);
};

const getSuggestionValue = suggestion => suggestion.value;

function customRenderTag(props) {
	let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props
	return <span key={key} {...other}>
		<span className="tag-value">{getTagDisplayValue(tag)}</span>
		{!disabled &&
			<a className={classNameRemove} onClick={(e) => onRemove(key)} />
		}
	  </span>;
}

const InputComponent = ({id, ...inputProps}) => (
	<div>
		<input data-qa-id={"QA_tagpicker_"+id} {...inputProps} />
	</div>
);

function createArrayFromCommaSeperatorTags(multiTag) {
	const arrayTag = multiTag.split(",");
	let tags = [];
	$.each(arrayTag, (i, tag) => {
		tags.push(tag.trim());
	});
	return tags;
}

function checkAndConvertCommaSeperatorTags(tags) {
	let result = [];
	$.each(tags, (i, v) => {
		if (v.indexOf(",") === -1) {
			result.push(v);
		} else {
			result = result.concat(createArrayFromCommaSeperatorTags(v));
		}
	});
	return result;
}

export class ControlTagPicker extends React.PureComponent {
	constructor (props) {
		super(props);
		this.state = {
			suggestions: []
			, inputError: false
		}
		this.handleChange = this.handleChange.bind(this);
		this.renderLayout = this.renderLayout.bind(this);
		this.handleValidation = this.handleValidation.bind(this);
		this.handleValidationReject = this.handleValidationReject.bind(this);
	}
	accept() {
		if (!this.ref) {
			return false;
		}
		return this.ref.accept();
	}
	allowCommaSeperatorTags() {
		return this.props.allowSplitByComma && this.props.maxInput !== 1;
	}
	handleValidationReject(tags) {
		if (tags && tags.length && tags[0]) {
			this.setState({inputError: true});
			if (this.props.onFailValidation) {
				if (this.lastError !== tags[0]) {
					this.lastError = tags[0];
					this.props.onFailValidation(this.props.type, tags[0], tags);
				}
			}
		} else {
			this.setState({inputError: false});
		}
	}
	handleValidation (tag) { // For future tag-input as 3.19.0 still NOT support
		let validResult
			, emailValidation
			, phoneValidation
			;
		if (this.allowCommaSeperatorTags()) {
			emailValidation = isValidMultiEmails;
			phoneValidation = isValidMultiPhones;
			numericValidation = isValidMultiFixedLengthNumeric;
		} else {
			emailValidation = isValidEmail;
			phoneValidation = isValidPhoneNo;
			numericValidation = isValidFixedLengthNumeric;
		}
		if (this.props.type === RC_EMAIL) {
			validResult = emailValidation(tag);
		} else if (this.props.type === RC_SMS) {
			validResult = phoneValidation(tag);
		} else {
			validResult = true;
		}
		if (!validResult) {
			this.handleValidationReject([tag]);
		}
		return validResult;
	}
	handleChange (tags) {
		this.setState({inputError: false});
		if (this.props.onChange) {
			if (this.allowCommaSeperatorTags()) {
				tags = checkAndConvertCommaSeperatorTags(tags);
			}
			this.props.onChange(tags, this.props.name);
		}
	}
	getValidRegexp() {
		// TODO: use clone tool to clone the regexp instead of direct use
		// regexp object as certain regexp - global flag - the object will be
		// mutated by javascript and run several times may not give consistent
		// result.
		const { type } = this.props;
		let regexp;
		if (this.allowCommaSeperatorTags()) {
			if (type === RC_EMAIL) {
				regexp = validMultiEmailsRegex;
			} else if (type === RC_SMS || type === RC_VOICE) {
				regexp = validMultiPhonesRegex;
			}
			return regexp;
		}
		if (type === RC_EMAIL) {
			regexp = validEmailRegex;
		} else if (type === RC_SMS || type === RC_VOICE) {
			regexp = validPhoneRegex;
		}
		return regexp;
	}
	onSuggestionsFetchRequested = ({ value }) => {
		const regexp = this.getValidRegexp();
		let filters = [];
		$.each(this.props.options, (k, v) => {
			const id = v[this.props.fields.id];
			if (!regexp || regexp.test(id)) {
				filters = update(
					filters
					, {$push: [{id, value: v[this.props.fields.value]}]}
				);
			}
		});
		this.setState({suggestions: getSuggestions(value, filters)});
	}
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	}
	handleRenderSuggestion = (value) => {
		return value.trim().length > 0;
	}
	testValidRegexp(testString) {
		const re = this.getValidRegexp();
		if (!re) {
			return true;
		}
		return re.test(testString);
	}
	autocompleteRenderInput = ({addTag, placeholder, ...props}) => {
		const handleBlur = e => {
			if (this.state.inputError || (this.props.inputValue &&
				this.testValidRegexp(this.props.inputValue))) {
				// this.getValidRegexp() && !this.getValidRegexp().test(this.props.inputValue))) {
				if (this.ref) {
					this.ref.clearInput();
				}
			}
				props.onBlur(e);
			}
			, handleChange = (e, { newValue, method }) => {
				if (method === 'enter') {
					e.preventDefault()
				} else {
					props.onChange(e);
				}
			}
			, handleFocus = e => {
				if (this.state.inputError && !this.props.inputValue) {
					// empty string not consider at first gain focus
					this.setState({inputError: false});
				}
				props.onFocus(e);
			}
			, inputProps = {
				...props
				, id: this.props.id
				, disabled: this.props.disabled
				, placeholder: this.props.verticalView ? this.props.name.toUpperCase() : ""
				, onBlur: handleBlur
				, onChange: handleChange
				, onFocus: handleFocus
			}
			;
		return (
			<Autosuggest
				ref={props.ref}
				suggestions={this.state.suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				onSuggestionSelected={(e, {suggestion}) => addTag(suggestion.id)}
				renderSuggestion={(suggestion) => <span>{suggestion.value}</span>}
				inputProps={inputProps}
				shouldRenderSuggestions={this.handleRenderSuggestion}
				highlightFirstSuggestion={true}
				renderInputComponent={InputComponent}
				verticalView={this.props.verticalView}
			/>
		);
	}
	renderLayout(tagComponents, inputComponent) {
		return <span className="layout-container" hidden={this.props.hidden}>
				<span className="tag-input-container">
					{tagComponents}
					{inputComponent}
				</span>
				{(!this.props.disabled && !this.props.basic) &&
					<span data-qa-id={"contact-card-icon-"+this.props.name} className="address-book-add"
						onClick={this.props.onShowContactBook}>
						<i className="fas fa-plus" />
					</span>
				}
			</span>;
	}
	render () {
		return (
			<TagsInput
				ref={ref => this.ref = ref}
				focusedClassName={classNames(
					"react-tagsinput--focused"
					, {"input-error": this.state.inputError}
				)}
				id={this.props.id}
				addOnBlur={true}
				maxTags={this.props.maxInput}
				onlyUnique={true}
				renderTag={customRenderTag}
				renderInput={this.autocompleteRenderInput}
				renderLayout={this.renderLayout}
				disabled={this.props.disabled}
				value={this.props.selected}
				inputValue={this.props.inputValue}
				validationRegex={this.getValidRegexp()}
				onValidationReject={this.handleValidationReject}
				// validate={this.handleValidation} // future version
				onChange={this.handleChange}
				onChangeInput={this.props.onChangeInput}
				verticalView={this.props.verticalView}
			/>
		);
	}
}

const withInputValue = Component => class extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.state = {value: ""};
	}
	handleChange(value) {
		this.setState({value});
	}
	render() {
		return (
			<Component
				onChangeInput={this.handleChange}
				inputValue={this.state.value}
				{...this.props}
			/>
		);
	}
};

const TagPickerBase = withInputValue(ControlTagPicker);

const TagPicker = props => <TagPickerBase allowSplitByComma={true} {...props} />;

export default TagPicker;
