import React from "react";
import { Link } from 'react-router-dom';
import { readNotification } from "../../common/v5/socket";
import {
	ACCOUNTS_PATH_SLICE_V5,
	ACCOUNTS_PATH_SLICE
} from '../../common/v5/constants';
//blue bubble notification
export default class PopupNotification extends React.Component {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
		this.handleReadNotification = this.handleReadNotification.bind(this);
		this.handleShowAllNotification = this.handleShowAllNotification.bind(this);
	}
	handleClose() {
		this.props.onClose(this.props.id);
	}
	handleReadNotification() {
		const { id, text } = this.props.data;
		if (id && !id.read) {
			readNotification(id);
		}
		const msg = JSON.parse(text);
		const { page, linkTo } = msg;
		if (page === "/messages") {
			this.props.openMessage(linkTo);
		} else if (page === "/statistics") {
			this.props.openStatistics(linkTo);
		} else if (linkTo) {
			let featureOpenLastErrandInThread = features["external-experts.notification-link-to-last-errand"];
			if (featureOpenLastErrandInThread && msg.text == 'MsgCollaborationReply') {
				this.props.openLastErrandInThread(linkTo);
				this.props.openCollaReplyPanel(msg.linkTo, msg.eeThread, msg.eeQuery, true);
			} else if (msg.text == 'MsgAccountsErr') {
				let id = page ? page : "";
				let linkPage = process.env.PATH_PREFIX + ACCOUNTS_PATH_SLICE_V5[id] + "/" + msg.linkTo;
				this.props.openAccounts(id, linkPage);
			} else if (msg.text == 'MsgCollaborationRequest') {
				this.props.openCollaReplyPanel(msg.linkTo, msg.eeThread, msg.eeQuery);
			} else if(msg.text == 'MsgCollaborationReply') {
				this.props.openCollaReplyPanel(msg.linkTo, msg.eeThread, msg.eeQuery, true);
			} else {
				this.props.openErrand(linkTo);
			}
		} else if (cflag.IsActive ("2023-09-20.CEN-109.announcement") && msg.text == "MsgAnnouncement") {
			this.props.openAnnouncement(msg.id);
		}
	}
	handleShowAllNotification(e) {
		e.preventDefault();
		this.props.onShowAllNotification();
	}
	render() {
		const { data, sum } = this.props;
		let msg, handleOnClick;
		let linkPage = "";
		let pathName = process.env.PATH_PREFIX + "v5";
		if (data) {
			msg = JSON.parse(data.text);
			linkPage = msg.page ? msg.page : "";
			pathName = pathName + linkPage;
			if (msg.text == "MsgAccountsErr") {
				pathName = process.env.PATH_PREFIX + ACCOUNTS_PATH_SLICE_V5[linkPage] + "/" + msg.linkTo;
				linkPage = pathName
			}
		}
		if (sum) {
			handleOnClick = this.handleShowAllNotification;
		} else if (!data) {
			handleOnClick = (e => e.preventDefault());
		}
		else {
			handleOnClick = this.handleReadNotification;
		}
		let popupClass = "pop-up-notification";
		if (this.props.type === "error") {
			popupClass += " error";
		}
		let iconDOM = <i className={"icon-bell"}></i>;
		if (this.props.icon) {
			iconDOM = this.props.icon;
		}
		return (
			<div className={popupClass} hidden={!this.props.display}>
				<Link to={{ pathname: pathName }} onClick={handleOnClick}>
					<div className="notification-icon">
						{iconDOM}
					</div>
					<div className="notification-text">
						<p>{this.props.message}</p>
					</div>
				</Link>
				<div className="notification-action">
					<button className="close" onClick={this.handleClose}><i className="icon-times"></i></button>
				</div>
			</div>
		);
	}
}

